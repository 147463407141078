import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
//import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import {
  MatSort,
  MatSortable,
  Sort,
  SortDirection,
} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Trade } from '../_models/trade';
//import { Router } from '@angular/router';
import { Status } from '@app/_models/status';
import { Contractor } from '../_models/contractor';
import { AlertService } from '../_services';
import { FunctionsService } from '../_services/functions.service';
import { RestService } from '../_services/rest.service';
import { GlobalValues } from '@app/_services/global-values';

@Component({
  selector: 'app-contractor',
  templateUrl: './contractor.component.html',
  styleUrls: ['./contractor.component.scss'],
})
export class ContractorComponent implements OnInit {
  displayedColumns: string[] = [
    'contractorId',
    'tradingName',
    'state',
    'suburb',
    'postcode',
    'geoRegion',
    'status',
    'tncStatus',
    'compliantUntil',
    'isCompliant',
    'complianceStatus',
    'avgRating',
    'swmsProvidedDate',
    'dateLastUsed',
    'dateEvaluated',
    'mainContactName',
    'mainContactPhone',
    'mainTradeCategory',
  ];
  dataSource!: MatTableDataSource<Contractor>;
  isLoading = true;
  searchValue =
    localStorage.getItem('searchText') != null
      ? localStorage.getItem('searchText')
      : '';
  isSearch =
    localStorage.getItem('homeStyle') != null
      ? localStorage.getItem('homeStyle')?.toString() == 'true'
      : false;

  sortColumn =
    localStorage.getItem('sortColumn') != null
      ? localStorage.getItem('sortColumn')
      : 'contractorId';
  sortDirection =
    localStorage.getItem('sortDirection') != null
      ? localStorage.getItem('sortDirection')
      : 'asc';

  //isSearch = false;
  tradeServicesList:string[]=[];

  filterSelectObj: any = [];
  stateFilter = new FormControl(
    localStorage.getItem('stateFilter')
      ? localStorage.getItem('stateFilter')
      : ''
  );
  geoRegionsFilter = new FormControl(
    localStorage.getItem('geoRegionFilter')
      ? localStorage.getItem('geoRegionFilter')
      : ''
  );
  tradeFilter = new FormControl(
    localStorage.getItem('mainTradeCategoryFilter')
      ? localStorage.getItem('mainTradeCategoryFilter')
      : ''
  );
  tradeServicesFilter = new FormControl(
    localStorage.getItem('tradeServicesFilter')
      ? localStorage.getItem('tradeServicesFilter')?.split(',')
      : ['']);

  filterValues: any = {
    state: localStorage.getItem('stateFilter')
      ? localStorage.getItem('stateFilter')
      : '',
    geoRegion: localStorage.getItem('geoRegionFilter')
      ? localStorage.getItem('geoRegionFilter')
      : '',
    mainTradeCategory: localStorage.getItem('mainTradeCategoryFilter')
      ? localStorage.getItem('mainTradeCategoryFilter')
      : '',
    tradeServices: localStorage.getItem('tradeServicesFilter')
    ? localStorage.getItem('tradeServicesFilter')?.split(',')
    : [''],
  };

  @ViewChild(MatSort, { static: true })
  matSort: MatSort = new MatSort();
  paginator!: MatPaginator;
  @ViewChild(MatPaginator) set _paginator(paginator: MatPaginator) {
    this.paginator = paginator;
    if (this.dataSource) this.dataSource.paginator = this.paginator;
  }
  pageSizeControl = new FormControl(
    localStorage.getItem('pageSize')
      ? Number(localStorage.getItem('pageSize'))
      : 15
  );
  constructor(
    private globalValues:GlobalValues,
    public restService: RestService,
    private alertService: AlertService,
    private router: Router,
    public functionsService: FunctionsService
  ) {
    this.filterSelectObj = [
      {
        name: 'State',
        columnProp: 'state',
        options: [],
      },
      {
        name: 'Geo Region',
        columnProp: 'geoRegion',
        options: [],
      },
      {
        name: 'Main Trade',
        columnProp: 'mainTradeCategory',
        options: [],
      },
    ];
  }
  onPageSizeChange() {
    // Save the new page size to localStorage when the user selects a new page size
    localStorage.setItem('pageSize', this.paginator.pageSize.toString());
  }

  // for logging localStorage contents
  logLocalStorageContents() {
    console.log('Current localStorage contents:');
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key ?? '');
      console.log(`${key}: ${value}`);
    }
  }

  ngOnInit() {
    try {
      this.restService.getTrades().subscribe((res: Trade[]) => {
        this.tradeServicesList = res.map(x=>x.tradeName);
      });
      this.restService.getStatusTypes().subscribe((res: Status[]) => {
        this.restService.statusesArray = res;
      });
      this.getRemoteData();
      this.fieldListener();
      this.globalValues.globalVariableSubject.subscribe(()=>{
        this.setLevelZeroAssessor();
      })

      return;
    } catch (error: any) {
      console.log('Error: ' + error.message);
      this.alertService.error(error);
    }
  }

  /*private _sortingDataAccessor = (item: MyItem, property: string): any => {
    switch (property) {
        case 'someCustomProperty':
            return ...;
        default:
            return item[property]?.toLowerCase() || 'Ω';
    }
}*/
  sortData(sort: Sort) {
    //alert(sort.active);
    localStorage.setItem('sortColumn', sort.active);
    localStorage.setItem('sortDirection', sort.direction);
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction;
    return;
  }

  // Get Unique values from columns to build the filter
  getFilterObject(fullObj: any[], key: string | number) {
    const uniqChk: any[] = [];
    fullObj.filter((obj: { [x: string]: any }) => {
      if (!uniqChk.includes(obj[key])) {
        if (obj[key]) uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  radioChange(event: MatRadioChange) {
    this.isSearch = event.value;
    localStorage.setItem('homeStyle', this.isSearch.toString());
    this.getRemoteData();
  }

  //Fetch latest data set from the REST API
  getRemoteData() {
    try {
      this.isLoading = true;
      this.restService.getContractors().subscribe(
        (contractors) => {
          this.dataSource = new MatTableDataSource(contractors);
          this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
              return data[sortHeaderId].toLocaleLowerCase();
            }    
           return data[sortHeaderId];
          };

          if (
            this.pageSizeControl.value != null &&
            this.pageSizeControl.value > 100 &&
            this.dataSource.data.length !=
              Number(localStorage.getItem('pageSize'))
          ) {
            console.log(
              'pageSizeControl.value: ' + this.dataSource.data.length.toString()
            );
            localStorage.setItem(
              'pageSize',
              this.dataSource.data.length.toString()
            );
          }

          //this.matSort.sort(({ id: 'contractorId', start: 'asc' }) as MatSortable);
          //this.matSort.direction = 'asc';

          //let sortDir: SortDirection = JSON.parse(this.sortDirection != null ? this.sortDirection : 'asc');
          this.matSort.sort({
            id: this.sortColumn,
            start: this.sortDirection,
            disableClear: true,
            //caseInsensitive: true
          } as MatSortable);
          //this.matSort.direction = JSON.parse(this.sortDirection != null ? this.sortDirection : 'asc');

          this.dataSource.sort = this.matSort;
          this.dataSource.paginator = this.paginator;
          this.filterSelectObj.filter(
            (o: { options: any[]; columnProp: string | number }) => {
              o.options = this.getFilterObject(
                this.dataSource.data,
                o.columnProp
              ).sort();
            }
          );

          if (this.isSearch) {
            if (localStorage.getItem('searchState') == null) {
              this.dataSource.filter = '';
              this.searchValue = '';
            } else {
              this.dataSource.filter = JSON.parse(
                localStorage.getItem('searchState')!
              );
              this.searchValue = localStorage.getItem('searchText');
            }
          } else {
            this.dataSource.filterPredicate = this.createFilter();
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          return false;
        }
      );
      return true;
    } catch (error: any) {
      this.alertService.error(error);
      return false;
    }
  }

  setLevelZeroAssessor(){
    if(localStorage.getItem('levelZeroAssessor') == null)
    {
    this.restService.getListOfAllMembersInAssessorLevelZeroGroup(this.globalValues.accessToken).subscribe((members: any) => {
      let data = members.value;
      var jsonString =JSON.stringify(data);
      localStorage.setItem('levelZeroAssessor', jsonString);
    })
  }
}

  private fieldListener() {
    try {
      this.stateFilter.valueChanges.subscribe((state) => {
        if (state == null) state = '';
        this.filterValues.state = state;
        this.dataSource.filter = JSON.stringify(this.filterValues);
        localStorage.setItem('stateFilter', this.filterValues.state);
      });
      this.geoRegionsFilter.valueChanges.subscribe((geoRegion) => {
        if (geoRegion == null) geoRegion = '';
        this.filterValues.geoRegion = geoRegion;
        this.dataSource.filter = JSON.stringify(this.filterValues);
        localStorage.setItem('geoRegionFilter', this.filterValues.geoRegion);
      });
      this.tradeFilter.valueChanges.subscribe((mainTradeCategory) => {
        if (mainTradeCategory == null) mainTradeCategory = '';
        this.filterValues.mainTradeCategory = mainTradeCategory;
        this.dataSource.filter = JSON.stringify(this.filterValues);
        localStorage.setItem('mainTradeCategoryFilter',this.filterValues.mainTradeCategory
        );
      });

      this.tradeServicesFilter.valueChanges.subscribe((tradeServices) => {
        if (tradeServices == null || tradeServices.length == 1) tradeServices = [''];
        this.filterValues.tradeServices = tradeServices;//tradeServices?.map(x=>x);//
        this.dataSource.filter = JSON.stringify(this.filterValues);
        localStorage.setItem('tradeServicesFilter', this.filterValues.tradeServices.join(',')); 
      });
    } catch (error: any) {
      this.alertService.error(error);
    }
  }

  clearFilters() {
    try {
      this.stateFilter.setValue('');
      this.geoRegionsFilter.setValue('');
      this.tradeFilter.setValue('');
      this.tradeServicesFilter.setValue(['']);

      localStorage.setItem('stateFilter', '');
      localStorage.setItem('geoRegionFilter', '');
      localStorage.setItem('mainTradeCategoryFilter', '');
      localStorage.setItem('tradeServicesFilter', '');
    } catch (error: any) {
      this.alertService.error(error);
    }
  }

  private createFilter(): (data: Contractor, filter: string) => boolean {
    let filterFunction = function (
      this: any,
      data: {
        state: string | any[];
        geoRegion: string | any[];
        mainTradeCategory: string | any[];
        tradeServices: string | any[];
      },
      filter: string
    ): boolean {
      let searchTerms = JSON.parse(filter);
      return (
        data.state.indexOf(searchTerms.state) !== -1 &&
        data.geoRegion.indexOf(
          searchTerms.geoRegion == null ? '' : searchTerms.geoRegion
        ) !== -1 &&
        data.mainTradeCategory.indexOf(
          searchTerms.mainTradeCategory == null
            ? ''
            : searchTerms.mainTradeCategory
        ) !== -1 && 
        (searchTerms.tradeServices.length <= 1 || 
          (data.tradeServices && searchTerms.tradeServices.some((x: string) =>
            data.tradeServices.toString().split(',').map(y=>y.trim()).includes(x)))));
    };
    return filterFunction;
  }

  //Search by keyword
  onSearchKeyUp(search: { value: any }) {
    try {
      var currentFilter = search.value;
      this.dataSource.filter = currentFilter;

      localStorage.setItem(
        'searchState',
        JSON.stringify(this.dataSource.filter)
      );
      localStorage.setItem('searchText', search.value);

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    } catch {}
  }

  //Clear search by keyword
  onClearClicked(search: { value: string }) {
    this.dataSource.filter = '';
    search.value = '';
    this.searchValue = '';
    localStorage.setItem('searchState', '""');
    localStorage.setItem('searchText', '');

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Add New Contractor
  addNewContractor() {
    this.router.navigate(['../add-edit-contractor/']);
  }
}
