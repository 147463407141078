<div class="main-container">
    <h2 *ngIf="dataSource && (dataSource.data.length != 1)">{{dataSource.data.length}} Invoices Approved for Payment
    </h2>
    <h2 *ngIf="dataSource && (dataSource.data.length == 1)">{{dataSource.data.length}} Invoice Approved for Payment</h2>
    <div class="loading-container" *ngIf="isLoading">
        <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div style="text-align: right; padding: 5px;">
        <button
            [disabled]="!(this.appComponent.userGroupsArray.includes(groups.groupIT) || this.appComponent.userGroupsArray.includes(groups.groupFinance))"
            style="width: fit-content;" class="btn btn-sm btn-primary mat-raised-button"
            (click)="exporter.exportTable('xlsx',{fileName:'Contractor Invoices Approved ' + functionsService.getCurrentTimestampFormatted()})">Export
            to Excel (Finance)
        </button>
        <button
            [disabled]="!(this.appComponent.userGroupsArray.includes(groups.groupIT) || this.appComponent.userGroupsArray.includes(groups.groupFinance))"
            style="width: fit-content; margin-left: 3px;" class="btn btn-sm btn-danger mat-raised-button"
            (click)="hiddenfileinput.click()">
            <!-- <i class="material-icons left">cloud</i> -->
            Upload Payments (Finance)</button>
        <!-- class="custom-file-input" -->
        <input style="display: none" type="file" accept=".xlsx" id="customFile" name="myfile"
            (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
        <!-- <label for="actual-btn">Choose File</label> -->
        <!-- <span id="file-chosen">No file chosen</span> -->
        <span id="file-chosen"></span>
    </div>
    <div>
        <div class="main-table-container">
            <mat-table matTableExporter class="mat-elevation-z8 table-striped" [dataSource]="dataSource" matSort
                matSortStart="asc" #exporter="matTableExporter">
                <ng-container matColumnDef="id">
                    <th class="w-3" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                    <td mat-cell *matCellDef="let jobHistory"><a href="/job-details/{{jobHistory.jobId}}"
                            target="_blank">{{jobHistory.id}}</a></td>
                </ng-container>

                <ng-container matColumnDef="refNo">
                    <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Ref No</th>
                    <td mat-cell *matCellDef="let jobHistory"><a
                            href="https://portal.mk3apps.com.au/case-details/{{jobHistory.refNo}}"
                            target="_blank">{{jobHistory.refNo}}</a></td>
                </ng-container>

                <ng-container matColumnDef="statusId">
                    <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let jobHistory">{{getJobStatus(jobHistory.statusId)}}</td>
                </ng-container>

                <ng-container matColumnDef="invoiceNumber">
                    <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Invoice #</th>
                    <td mat-cell *matCellDef="let jobHistory">{{jobHistory.invoiceNumber}}</td>
                </ng-container>

                <ng-container matColumnDef="invoiceAmount">
                    <th class="w-5" style="text-align: right;" mat-header-cell *matHeaderCellDef mat-sort-header>Amount ($)</th>
                    <td mat-cell *matCellDef="let jobHistory" style="text-align: right;">
                        <div *ngIf="jobHistory.invoiceAmount">{{jobHistory.invoiceAmount | number : '1.2-2'}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="contractorId">
                    <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Contractor ID</th>
                    <td mat-cell *matCellDef="let jobHistory"><a href="/contractor-details/{{jobHistory.contractorId}}"
                            target="_blank">{{jobHistory.contractorId}}</a></td>
                </ng-container>

                <ng-container matColumnDef="tradingName">
                    <th class="w-15" mat-header-cell *matHeaderCellDef mat-sort-header>Contractor/ Internal Trade</th>
                    <!-- <td mat-cell *matCellDef="let jobHistory"><a href="/contractor-details/{{jobHistory.contractorId}}"
                            target="_blank">{{jobHistory.tradingName}}</a></td> -->

                    <td mat-cell *matCellDef="let jobHistory">
                        <div *ngIf="jobHistory.contractorId">
                            <a href="/contractor-details/{{jobHistory.contractorId}}"
                                target="_blank">{{jobHistory.tradingName}}</a>
                        </div>
                        <div *ngIf="jobHistory.internalTradeID"><a
                                href="/internal-trade-details/{{jobHistory.internalTradeID}}"
                                target="_blank">{{jobHistory.internalTradeName}}</a></div>
                    </td>

                </ng-container>


                <!-- <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
        <td mat-cell *matCellDef="let jobHistory" [innerHTML]="jobHistory.comments"></td>
      </ng-container> -->

                <ng-container matColumnDef="date">
                    <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                    <td mat-cell *matCellDef="let jobHistory">{{jobHistory.date | date: 'dd/MM/yyyy'}}</td>
                </ng-container>

                <ng-container matColumnDef="addedBy">
                    <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Approved By</th>
                    <td mat-cell *matCellDef="let jobHistory">{{jobHistory.addedBy}}</td>
                </ng-container>

                <ng-container matColumnDef="timeStamp">
                    <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Approved On</th>
                    <td mat-cell *matCellDef="let jobHistory">{{jobHistory.timeStamp | date: 'dd/MM/yyyy HH:mm'}}</td>
                </ng-container>

                <ng-container matColumnDef="details">
                    <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
                    <td mat-cell *matCellDef="let jobHistory">
                        <button *ngIf="jobHistory.jobId" mat-raised-button class="btn btn-sm btn-success"
                            [routerLink]="['/job-details/',jobHistory.jobId]">Details</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="viewDocument">
                    <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Invoice</th>
                    <td mat-cell *matCellDef="let jobHistory">
                        <button *ngIf="jobHistory.originalHistoryId" mat-raised-button class="btn btn-sm btn-primary"
                            (click)="openInvoicePDF(jobHistory.originalHistoryId)">PDF</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="reject">
                    <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Finance</th>
                    <td mat-cell *matCellDef="let jobHistory">
                        <button
                            [disabled]="!(this.appComponent.userGroupsArray.includes(groups.groupIT) || this.appComponent.userGroupsArray.includes(groups.groupFinance))"
                            *ngIf="jobHistory.originalHistoryId" mat-raised-button class="btn btn-sm btn-danger"
                            (click)="rejectInvoice(jobHistory.id)">Reject</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="instructionTypeId">
                    <th class="w-3" mat-header-cell *matHeaderCellDef mFat-sort-header>Company</th>
                    <td mat-cell *matCellDef="let jobHistory">
                        <div *ngIf="jobHistory.instructionTypeId == 1">NIB</div>
                        <div *ngIf="jobHistory.instructionTypeId == 3">RR</div>
                        <div *ngIf="jobHistory.instructionTypeId == 5">Mk3</div>
                        <!-- <div *ngIf="jobHistory.instructionTypeId == 1"><img height="30"
                                src="../assets/nib-favicon.png" matTooltip="NIB" matTooltipPosition="right"/></div>
                        <div *ngIf="jobHistory.instructionTypeId == 3"><img height="30"
                                src="../assets/rr-favicon.png" matTooltip="RR" matTooltipPosition="right"/></div>
                        <div *ngIf="jobHistory.instructionTypeId == 5">
                            <img height="30" src="../assets/mk3-favicon.png" matTooltip="Mk3" matTooltipPosition="right"/>
                        </div> -->
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9999">No Invoices Available Approved for Payment</td>
                </tr>
            </mat-table>
        </div>
        <div class="center-button-container" *ngIf="!isLoading">
        </div>
    </div>
</div>