<div class="main-container">

  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div style="vertical-align: top;">
    <div>
      <div>
        <h1 *ngIf="isAddMode">Add New Contractor</h1>
        <h1 *ngIf="!isAddMode">Edit Contractor</h1>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="contractor-details-container mat-elevation-z8 bg-light">
          <div class="form-group">
            <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="onSelectedIndexChange($event)"
              (selectedTabChange)="onTabClick($event)">
              <mat-tab label="Contractor Details">
                <h2>Contractor Details</h2>
                <mat-grid-list cols="4" class="mainGroupStyle">
                  <fieldset>
                    <mat-grid-tile>
                      <mat-list>
                        <mat-list-item class="list-key-column"> Trading Name </mat-list-item>
                        <mat-list-item class="list-key-column"> Entity Name </mat-list-item>
                        <mat-list-item class="list-key-column"> Status </mat-list-item>
                        <mat-list-item class="list-key-column"> Geo Region </mat-list-item>
                        <mat-list-item class="list-key-column"> Address </mat-list-item>
                        <mat-list-item class="list-key-column"> Suburb - Postcode - State </mat-list-item>
                        <mat-list-item class="list-key-column"> Main Contact Name - Phone </mat-list-item>
                        <mat-list-item class="list-key-column"> Main Contact Email </mat-list-item>
                        <mat-list-item class="list-key-column"> Account Contact Name - Phone </mat-list-item>
                        <mat-list-item class="list-key-column"> Account Contact Email </mat-list-item>
                      </mat-list>
                    </mat-grid-tile>
                    <mat-grid-tile>
                      <mat-list>
                        <div formGroupName="mainGroup">
                          <mat-list-item class="list-value-column">
                            <input type="text" formControlName="tradingName" maxlength="200" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && mainGroup.controls['tradingName'].errors }" />
                            <small class="form-text text-danger"
                              *ngIf="submitted && mainGroup.controls['tradingName'].errors?.required"
                              class="alert alert-danger">*Required</small>
                            <small class="form-text text-danger" class="alert alert-danger"
                              *ngIf="submitted && mainGroup.controls['tradingName'].hasError('duplicate')">*Duplicate</small>
                          </mat-list-item>
                        </div>
                        <div formGroupName="mainGroup">
                          <mat-list-item class="list-value-column">
                            <input type="text" formControlName="companyName" maxlength="200" class="form-control"/>
                            <!-- <input type="text" formControlName="companyName" maxlength="200" class="form-control" (paste)="functionsService.removeNewlines($event, $any(mainGroup.controls['companyName']))"/> -->
                          </mat-list-item>
                        </div>
                        <div formGroupName="mainGroup">
                          <mat-list-item class="list-value-column">
                            <select formControlName="statusId" class="form-control">
                              <option *ngFor="let st of statuses | async" [ngValue]="st.statusId">{{ st.status }} -
                                {{restService.getStatusDefinition(st.statusId)}}
                              </option>
                            </select>
                          </mat-list-item>
                        </div>
                        <div formGroupName="contactGroup">
                          <mat-list-item class="list-value-column">
                            <select formControlName="geoRegionId" class="form-control">
                              <option *ngFor="let gr of geoRegions | async" [ngValue]="gr.geoRegionId">
                                {{ gr.geoRegion }}
                              </option>
                            </select>
                          </mat-list-item>
                        </div>
                        <div formGroupName="contactGroup">
                          <mat-list-item class="list-value-column"><input type="text" formControlName="address"
                              maxlength="255" class="form-control" /></mat-list-item>
                          <mat-list-item class="list-value-column"><input type="text" formControlName="suburb"
                              maxlength="255" class="form-control" style="width:60%" /><input digitOnly
                              inputmode="numeric" placeholder="0000" type="text" maxlength="4" pattern="[0-9]*"
                              formControlName="postCode" class="form-control" style="width:20%" />
                            <small class="form-text text-danger" *ngIf="contactGroup.controls['postCode'].invalid && (contactGroup.controls['postCode'].dirty 
                          || contactGroup.controls['postCode'].touched)" class="alert alert-danger">Invalid</small>
                            <select formControlName="stateId" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && contactGroup.controls['stateId'].errors }"
                              style="width:30%">
                              <option *ngFor="let s of states | async" [ngValue]="s.stateId">
                                {{ s.state }}
                              </option>
                            </select>
                            <small class="form-text text-danger" *ngIf="!contactGroup.controls['stateId'].value"
                              class="alert alert-danger">*Required</small>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <input type="text" formControlName="mainContactName" maxlength="255" class="form-control" />
                            <input type="text" digitOnly maxlength="10" formControlName="mainContactPhone"
                              class="form-control" style="width:50%" />
                            <small class="form-text text-danger" *ngIf="contactGroup.controls['mainContactPhone'].invalid && (contactGroup.controls['mainContactPhone'].dirty 
                          || contactGroup.controls['mainContactPhone'].touched)"
                              class="alert alert-danger">Invalid</small>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <input type="email" formControlName="mainContactEmail" maxlength="255"
                              class="form-control" />
                            <small class="form-text text-danger" *ngIf="contactGroup.controls['mainContactEmail'].invalid && (contactGroup.controls['mainContactEmail'].dirty 
                            || contactGroup.controls['mainContactEmail'].touched)"
                              class="alert alert-danger">Invalid</small>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <input type="text" formControlName="accountContactName" maxlength="255"
                              class="form-control" />
                            <input type="text" digitOnly maxlength="10" formControlName="accountContactPhone"
                              class="form-control" style="width:50%" />
                            <small class="form-text text-danger" *ngIf="contactGroup.controls['accountContactPhone'].invalid && (contactGroup.controls['accountContactPhone'].dirty 
                        || contactGroup.controls['accountContactPhone'].touched)"
                              class="alert alert-danger">Invalid</small>
                          </mat-list-item>
                          <mat-list-item class="list-value-column"><input type="email"
                              formControlName="accountContactEmail" maxlength="255" class="form-control" />
                            <small class="form-text text-danger" *ngIf="contactGroup.controls['accountContactEmail'].invalid && (contactGroup.controls['accountContactEmail'].dirty 
                          || contactGroup.controls['accountContactEmail'].touched)"
                              class="alert alert-danger">Invalid</small>
                          </mat-list-item>
                        </div>
                      </mat-list>
                    </mat-grid-tile>
                  </fieldset>
                  <fieldset>
                    <mat-grid-tile>
                      <mat-list>
                        <mat-list-item class="list-key-column"> Main Trade Category </mat-list-item>
                        <mat-list-item class="list-key-column"> Last Used Date </mat-list-item>
                        <mat-list-item class="list-key-column"> Evaluated Date </mat-list-item>
                        <mat-list-item class="list-key-column"> Entity Type </mat-list-item>
                        <mat-list-item class="list-key-column" *ngIf="mainGroup.controls['abn'].value"><a
                            href="https://abr.business.gov.au/ABN/View?id={{mainGroup.controls['abn'].value}}"
                            target="_blank">
                            ABN </a>
                        </mat-list-item>
                        <mat-list-item class="list-key-column" *ngIf="!mainGroup.controls['abn'].value"><a
                            href="https://abr.business.gov.au/Search/ResultsActive?SearchText={{mainGroup.controls['tradingName'].value}}"
                            target="_blank"> ABN </a></mat-list-item>
                        <mat-list-item class="list-key-column" *ngIf="mainGroup.controls['acn'].value"><a
                            href="https://abr.business.gov.au/ABN/View?id={{mainGroup.controls['acn'].value}}"
                            target="_blank">
                            ACN </a>
                        </mat-list-item>
                        <mat-list-item class="list-key-column" *ngIf="!mainGroup.controls['acn'].value"><a
                            href="https://abr.business.gov.au/Search/ResultsActive?SearchText={{mainGroup.controls['tradingName'].value}}"
                            target="_blank"> ACN </a></mat-list-item>
                        <mat-list-item class="list-key-column"> Compliancy Notes </mat-list-item>
                        <mat-list-item class="list-key-column"> </mat-list-item>
                        <mat-list-item class="list-key-column"> </mat-list-item>
                        <mat-list-item class="list-key-column"> </mat-list-item>
                      </mat-list>
                    </mat-grid-tile>
                    <mat-grid-tile>
                      <mat-list style="align-items: top;">
                        <div formGroupName="mainGroup">
                          <mat-list-item class="list-value-column">
                            <select formControlName="mainTradeCategoryId" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.mainTradeCategoryId.errors }">
                              <option *ngFor="let tc of tradeClassifications | async" [ngValue]="tc.tradeId">
                                {{ tc.tradeName }}
                              </option>
                            </select>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <mat-form-field style="flex:0; padding: 2px;">
                              <!-- <mat-label>Choose a date</mat-label> -->
                              <input matInput [matDatepicker]="pickerLastUsed" formControlName="dateLastUsed">
                              <mat-datepicker-toggle matSuffix [for]="pickerLastUsed">
                              </mat-datepicker-toggle>
                              <mat-datepicker #pickerLastUsed></mat-datepicker>
                            </mat-form-field>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <mat-form-field style="flex:0;padding: 2px;">
                              <!-- <mat-label>Choose a date</mat-label> -->
                              <input matInput [matDatepicker]="pickerEvaluated" formControlName="dateEvaluated">
                              <mat-datepicker-toggle matSuffix [for]="pickerEvaluated">
                              </mat-datepicker-toggle>
                              <mat-datepicker #pickerEvaluated></mat-datepicker>
                            </mat-form-field>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <select formControlName="entityTypeId" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.entityTypeId.errors }">
                              <option *ngFor="let et of entityTypes | async" [ngValue]="et.entityTypeId">
                                {{ et.entityType }}
                              </option>
                            </select>
                          </mat-list-item>
                          <!-- <mat-list-item class="list-value-column">
                            <input type="text" formControlName="abn" appAbnMask maxlength="14" class="form-control" />
                              <mat-error *ngIf="mainGroup.get('abn')?.hasError('abnFormat') && mainGroup.get('abn')?.dirty"><div>ABN must be in the format XX XXX XXX XXX</div></mat-error>
                            </mat-list-item> -->

                          <!-- <mat-list-item class="list-value-column"><input type="text" formControlName="abn" appAbnMask maxlength="14" class="form-control" />
                            <small class="form-text text-danger" *ngIf="mainGroup.controls['abn']?.hasError('abnFormat') && ((mainGroup.controls['abn']?.dirty 
                          || mainGroup.controls['abn']?.touched))"
                              class="alert alert-danger">Invalid</small>
                          </mat-list-item> -->

                          <mat-list-item class="list-value-column"><input type="text"  placeholder="XX XXX XXX XXX" appAbnMask formControlName="abn"
                              maxlength="14" class="form-control" /><br />
                            <small class="form-text text-danger" *ngIf="mainGroup.controls['abn'].invalid && (mainGroup.controls['abn'].dirty 
                        || mainGroup.controls['abn'].touched)" class="alert alert-danger">Invalid ABN</small>
                          </mat-list-item>

                          <!-- <mat-list-item class="list-value-column"><input type="text" formControlName="acn"
                              maxlength="100" class="form-control" />
                          </mat-list-item> -->

                          <mat-list-item class="list-value-column"><input type="text"  placeholder="XXX XXX XXX" appAcnMask formControlName="acn"
                              maxlength="11" class="form-control" /><br />
                            <small class="form-text text-danger" *ngIf="mainGroup.controls['acn'].invalid && (mainGroup.controls['acn'].dirty 
                      || mainGroup.controls['acn'].touched)" class="alert alert-danger">Invalid ACN</small>
                          </mat-list-item>

                          <mat-list-item class="list-value-column">
                            <div class="text-area-wrapper">
                              <mat-form-field class="text-area-form-field" appearance="outline">
                                <textarea matInput formControlName="compliancyNotes" maxlength="400" cdkTextareaAutosize
                                  #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                  cdkAutosizeMaxRows="5"></textarea>
                              </mat-form-field>
                            </div>
                          </mat-list-item>
                          <mat-list-item class="list-value-column"></mat-list-item>
                          <mat-list-item class="list-value-column"></mat-list-item>
                          <mat-list-item class="list-value-column"></mat-list-item>
                        </div>
                      </mat-list>
                    </mat-grid-tile>
                  </fieldset>
                </mat-grid-list>
              </mat-tab>
              <mat-tab label="Insurance & Licence Details">
                <h2>Insurance & Licence Details</h2>
                <h3>{{mainGroup.controls['tradingName'].value}}</h3>
                <mat-grid-list cols="4">
                  <fieldset formGroupName="insuranceGroup">
                    <mat-grid-tile>
                      <mat-list>
                        <mat-list-item class="list-key-column">
                          <h3>Insurance Details</h3>
                        </mat-list-item>
                        <mat-list-item class="list-key-column"> W/C or Sick and Acc Insurer </mat-list-item>
                        <mat-list-item class="list-key-column"> W/C or Sick & Acc Policy Number </mat-list-item>
                        <mat-list-item class="list-key-column"> W/C or Sick & Acc Policy Expiry Date </mat-list-item>
                        <mat-list-item class="list-key-column"> W/C or Sick & Acc Sum Insured </mat-list-item>
                        <mat-list-item class="list-key-column"> P/L Insurer </mat-list-item>
                        <mat-list-item class="list-key-column"> P/L Policy Number </mat-list-item>
                        <mat-list-item class="list-key-column"> P/L Expiry Date </mat-list-item>
                        <mat-list-item class="list-key-column"> P/L Sum Insured </mat-list-item>
                        <mat-list-item class="list-key-column"> </mat-list-item>
                      </mat-list>
                    </mat-grid-tile>
                    <mat-grid-tile>
                      <mat-list>
                        <mat-list-item class="list-value-column"></mat-list-item>
                        <mat-list-item class="list-value-column">
                          <select formControlName="wcsainsurerId" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.wcsainsurerId.errors }">
                            <option *ngFor="let ins of insurers | async" [ngValue]="ins.id">
                              {{ ins.insurer }}
                            </option>
                          </select>
                        </mat-list-item>
                        <mat-list-item class="list-value-column"><input type="text" formControlName="policyNumber"
                            maxlength="100" class="form-control" /></mat-list-item>
                        <mat-list-item class="list-value-column">
                          <div>
                            <mat-form-field style="flex:0; padding: 0px;">
                              <!-- <mat-label>Choose a date</mat-label> -->
                              <input matInput [matDatepicker]="pickerPolicyExpiry" formControlName="policyExpiryDate">
                              <mat-datepicker-toggle matSuffix [for]="pickerPolicyExpiry" style='height: 5em'>
                              </mat-datepicker-toggle>
                              <mat-datepicker #pickerPolicyExpiry></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </mat-list-item>
                        <mat-list-item class="list-value-column">
                          <div class="currency-wrap">
                            <span class="currency-code">$</span><input type="number" formControlName="policySumInsured"
                              class="form-control text-currency">
                          </div>
                        </mat-list-item>
                        <mat-list-item class="list-value-column">

                          <select formControlName="plinsurerId" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.plinsurerId.errors }">
                            <option *ngFor="let ins of insurers | async" [ngValue]="ins.id">
                              {{ ins.insurer }}
                            </option>
                          </select>

                          <!-- <input type="text" placeholder="Pick one" aria-label="P/L Insurer" matInput formControlName="plinsurerId"
                            [matAutocomplete]="auto">

                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let ins of insurers | async" [value]="ins.id">
                              {{ins.insurer}}
                            </mat-option>
                          </mat-autocomplete> -->

                          <!-- <ng-container *ngIf="options$| async as options">
                            <mat-form-field *ngIf="filteredOptions$ | async as filteredOptions">
                              <input type="text" placeholder="Select a P/L Insurer" matInput [matAutocomplete]="auto"
                                formControlName="plinsurerId" class="form-control" #input
                                (keyup)="onAutocompleteKeyUp(input.value, options)" required />
                              <mat-error *ngIf="f.plinsurerId.errors?.matchRequired">
                                Please select a P/L Insurer from the list.
                              </mat-error>

                              <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                [displayWith]="getAutoCompleteDisplayValue">
                                <mat-option *ngFor="let option of filteredOptions" [value]="option">
                                  {{ option.insurer }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </ng-container> -->


                        </mat-list-item>
                        <mat-list-item class="list-value-column"><input type="text" formControlName="plpolicyNumber"
                            maxlength="100" class="form-control" /></mat-list-item>
                        <mat-list-item class="list-value-column">
                          <div>
                            <mat-form-field style="flex:0; padding: 0px;">
                              <!-- <mat-label>Choose a date</mat-label> -->
                              <input time="false" date="true" type="text" matInput [matDatepicker]="pickerPLExpiry"
                                formControlName="plexpiryDate">
                              <mat-datepicker-toggle matSuffix [for]="pickerPLExpiry" style='height: 5em'>
                              </mat-datepicker-toggle>
                              <mat-datepicker #pickerPLExpiry></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </mat-list-item>
                        <mat-list-item class="list-value-column">
                          <div class="currency-wrap">
                            <span class="currency-code">$</span><input type="number" formControlName="plsumInsured"
                              class="form-control text-currency">
                          </div>

                        </mat-list-item>
                        <mat-list-item class="list-value-column"></mat-list-item>
                      </mat-list>
                    </mat-grid-tile>
                  </fieldset>
                  <fieldset>
                    <mat-grid-tile>
                      <mat-list>
                        <mat-list-item class="list-key-column">
                          <h3>Licence Details</h3>
                        </mat-list-item>
                        <mat-list-item class="list-key-column">Licence Required</mat-list-item>
                        <mat-list-item class="list-key-column">SWMS Required</mat-list-item>
                        <mat-list-item class="list-key-column">Licence Name</mat-list-item>
                        <mat-list-item class="list-key-column">Licence Classification</mat-list-item>
                        <mat-list-item class="list-key-column">Licence Number</mat-list-item>
                        <mat-list-item class="list-key-column">Licence Expiry Date</mat-list-item>
                        <mat-list-item class="list-key-column">Construction Induction</mat-list-item>
                        <mat-list-item class="list-key-column">SWMS Documentation Provided Date</mat-list-item>
                        <mat-list-item class="list-key-column">Licence Conditions</mat-list-item>
                      </mat-list>
                    </mat-grid-tile>
                    <mat-grid-tile>
                      <mat-list>
                        <mat-list-item class="list-value-column" *ngIf="contactGroup.controls['stateId'].value == 1"><a
                            href="https://www.onegov.nsw.gov.au/publicregister/#/publicregister/categories"
                            target="_blank">NSW Public Register</a>
                        </mat-list-item>
                        <mat-list-item class="list-value-column" *ngIf="contactGroup.controls['stateId'].value == 3"><a
                            href="https://www.onlineservices.qbcc.qld.gov.au/OnlineLicenceSearch/VisualElements/SearchBSALicenseeContent.aspx"
                            target="_blank">QLD Public Register</a>&nbsp;|&nbsp;<a
                            href="https://fswqap.worksafe.qld.gov.au/elis/" target="_blank">QLD Electrical Licence</a>
                        </mat-list-item>
                        <mat-list-item class="list-value-column" *ngIf="contactGroup.controls['stateId'].value == 2"><a
                            href="https://consumer.etoolbox.pic.vic.gov.au/_layouts/cc/pic_validatepp.aspx"
                            target="_blank">Check
                            VIC Public Register</a>
                        </mat-list-item>
                        <mat-list-item class="list-value-column" *ngIf="contactGroup.controls['stateId'].value == 4"><a
                            href="https://www.commerce.wa.gov.au/consumer-protection/consumer-protection-licence-and-registration-search"
                            target="_blank">WA Public Register</a>
                        </mat-list-item>
                        <mat-list-item class="list-value-column" *ngIf="contactGroup.controls['stateId'].value == 6"><a
                            href="https://secure.cbs.sa.gov.au/OccLicPubReg/LicenceSearch.php" target="_blank">SA Public
                            Register</a>
                        </mat-list-item>
                        <mat-list-item class="list-value-column" *ngIf="contactGroup.controls['stateId'].value == 5"><a
                            href="https://www.accesscanberra.act.gov.au/s/public-registers" target="_blank">ACT Public
                            Register</a>
                        </mat-list-item>
                        <mat-list-item class="list-value-column" *ngIf="contactGroup.controls['stateId'].value == null">
                        </mat-list-item>
                        <div formGroupName="mainGroup">
                          <mat-list-item class="list-value-column">
                            <select formControlName="licenceRequired" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.licenceRequired.errors }">
                              <option *ngFor="let lr of licenceRequired" [ngValue]=lr.id>
                                {{ lr.text }}
                              </option>
                            </select>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <select formControlName="swmsRequired" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.swmsRequired.errors }">
                              <option *ngFor="let swm of swmsRequired" [ngValue]=swm.id>
                                {{ swm.text }}
                              </option>
                            </select>
                          </mat-list-item>
                        </div>
                        <div formGroupName="licenceGroup">
                          <mat-list-item class="list-value-column"><input type="text" formControlName="licenceName"
                              maxlength="100" class="form-control" /></mat-list-item>
                          <mat-list-item class="list-value-column">
                            <select formControlName="licenceClassificationId" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.licenceClassificationId.errors }">
                              <option *ngFor="let lc of licenceClassifications | async" [ngValue]="lc.id">
                                {{ lc.classification }}
                              </option>
                            </select>
                          </mat-list-item>
                          <mat-list-item class="list-value-column"><input type="text" formControlName="licenceNumber"
                              maxlength="50" class="form-control" /></mat-list-item>
                          <mat-list-item class="list-value-column">
                            <div>
                              <mat-form-field style="flex:0; padding: 0px;">
                                <!-- <mat-label>Choose a date</mat-label> -->
                                <input matInput [matDatepicker]="pickerLicenceExpiry"
                                  formControlName="licenceExpiryDate">
                                <mat-datepicker-toggle matSuffix [for]="pickerLicenceExpiry" style='height: 5em'>
                                </mat-datepicker-toggle>
                                <mat-datepicker #pickerLicenceExpiry></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <select formControlName="constructionInduction" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.constructionInduction.errors }">
                              <option [ngValue]="null"></option>
                              <option *ngFor="let ci of constructionInduction" [ngValue]="ci.id">
                                {{ ci.text }}
                              </option>
                            </select>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <div>
                              <mat-form-field style="flex:0; padding: 0px;">
                                <input matInput [matDatepicker]="pickerSWMSProvided" [max]="today"
                                  formControlName="swmsProvidedDate" id="swmsProvidedDate"
                                  (dateChange)="onSWMSProvidedDateChange()">
                                <mat-datepicker-toggle matSuffix [for]="pickerSWMSProvided" style='height: 5em'>
                                </mat-datepicker-toggle>
                                <mat-datepicker #pickerSWMSProvided></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </mat-list-item>
                          <mat-list-item class="list-value-column">
                            <div class="text-area-wrapper">
                              <mat-form-field class="text-area-form-field" appearance="outline">
                                <textarea matInput formControlName="licenceConditions" maxlength="400"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                  cdkAutosizeMaxRows="5"></textarea>
                              </mat-form-field>
                            </div>
                          </mat-list-item>
                        </div>
                      </mat-list>
                    </mat-grid-tile>
                  </fieldset>
                </mat-grid-list>
              </mat-tab>
              <mat-tab label="Trade Services" style="align-items: center;">
                <h2>Trade Services</h2>
                <h3>{{mainGroup.controls['tradingName'].value}}</h3>
                <div tabindex="1" id="tradeCheckboxes" autofocus>
                  <div *ngFor="let partition of this.functionsService.partitionV(tradeIdsArray, tradeIdsArray.length)"
                    style="display: flex; padding-left: 20px; overflow-x:hidden;">
                    <div *ngFor="let item of partition" style="display: flex; align-self: center;">
                      <div style="display: flex; align-content:center;">
                        <div class="form-check" style="align-self: center;">
                          <input type="checkbox" class="form-check-input" [value]="trades[item-1].tradeId"
                            [id]="'ck' + trades[item-1].tradeId" [appCheckboxlist]="tradesCheckedList"
                            (checkboxchange)="setCheckedTradesList($event)" />
                          <label [for]="'ck' + trades[item-1].tradeId" class="form-check-label"
                            style="width:220px;">{{trades[item-1].tradeName}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div class="footer-buttons">
            <button [disabled]="!form.valid || isLoading" class="btn btn-sm btn-danger">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Save
            </button>
            <a *ngIf="isAddMode" routerLink="/" class="btn btn-link">Cancel</a>
            <a *ngIf="!isAddMode" [routerLink]="'/contractor-details/'+ contractorId" class="btn btn-link">Cancel</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>