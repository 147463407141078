import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { AddEditContractorComponent } from './add-edit-contractor/add-edit-contractor.component';
import { ContractorDetailsComponent } from './contractor-details/contractor-details.component';
import { ContractorComponent } from './contractor/contractor.component';
import { HomeComponent } from './home';
import { groups } from './auth-config';
import { GroupGuardService } from './_services/group-guard.service';
import { OverageComponent } from './overage/overage.component';
import { NewNoteComponent } from './contractor-details/new-note/new-note.component';
import { RouterModule, Routes } from '@angular/router';
import { InternalTradesComponent } from './internal-trades/internal-trades.component';
import { InternalTradeDetailsComponent } from './internal-trade-details/internal-trade-details.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { InvoiceApprovalDialogComponent } from './job-details/invoice-approval-dialog/invoice-approval-dialog.component';
import { InvoiceUploadDialogComponent } from './job-details/invoice-upload-dialog/invoice-upload-dialog.component';
import { ApprovedInvoicesComponent } from './approved-invoices/approved-invoices.component';
import { InvoicesPaidComponent } from './invoices-paid/invoices-paid.component';
import { InvoicesAwaitingApprovalComponent } from './invoices-awaiting-approval/invoices-awaiting-approval.component';

const insurersModule = () =>
  import('./insurers/insurers.module').then((x) => x.InsurersModule);
const licencesModule = () =>
  import('./licences/licences.module').then((x) => x.LicencesModule);
const regionsModule = () =>
  import('./regions/regions.module').then((x) => x.RegionsModule);
const tradesModule = () =>
  import('./trades/trades.module').then((x) => x.TradesModule);

const routes: Routes = [
  {
    path: 'contractors',
    component: ContractorComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Contractors List',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'contractor-details/:contractorId',
    component: ContractorDetailsComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Contractor Details',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'contractor-details/:contractorId/:dateLastUsed',
    component: ContractorDetailsComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Contractor Details',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'internal-trades',
    component: InternalTradesComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Internal Trades',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'internal-trade-details/:internalTradeId',
    component: InternalTradeDetailsComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Internal Trade Details',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'contractor-details/new-note/:contractorId/:companyName',
    component: NewNoteComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Add New Note',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'insurers',
    loadChildren: insurersModule,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Insurers',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'licences',
    loadChildren: licencesModule,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Licence Classifications',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'regions',
    loadChildren: regionsModule,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Mk3 Geo Regions',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'trades',
    loadChildren: tradesModule,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Trade Classifications',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'add-edit-contractor',
    component: AddEditContractorComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Add New Contractor',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'add-edit-contractor/:contractorId',
    component: AddEditContractorComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Edit Contractor',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupFinance
      ],
    },
  },
  {
    path: 'job-details/:id',
    component: JobDetailsComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Job Details/ History',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'job-details/:id/:statusId',
    component: JobDetailsComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Job Details/ History',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'invoice-approval-dialog',
    component: InvoiceApprovalDialogComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Invoice Approval',
      expectedGroups: [
        groups.groupManagement,
        groups.groupAssessors,
        groups.groupTeamLeaders,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'invoice-upload-dialog',
    component: InvoiceUploadDialogComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Invoice Upload',
      expectedGroups: [
        groups.groupManagement,
        groups.groupAssessors,
        groups.groupTeamLeaders,
        groups.groupFinance,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'invoices-awaiting-approval',
    component: InvoicesAwaitingApprovalComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Invoices Awaiting Approval',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'approved-invoices',
    component: ApprovedInvoicesComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Approved Invoices for Payment',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'invoices-paid',
    component: InvoicesPaidComponent,
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      title: 'Invoices Paid by Finance',
      expectedGroups: [
        groups.groupIT,
        groups.groupManagement,
        groups.groupContractorRWFull,
        groups.groupContractorRWPartial,
        groups.groupContractorRO,
        groups.groupFinance,
        groups.groupAssessors,
        groups.groupCoordinators
      ],
    },
  },
  {
    path: 'overage',
    component: OverageComponent,
    canActivate: [MsalGuard],
  },
  {
    // Needed for hash routing
    path: 'error',
    component: HomeComponent,
  },
  {
    // Needed for hash routing
    path: 'state',
    component: HomeComponent,
  },
  {
    // Needed for hash routing
    path: 'code',
    component: HomeComponent,
  },
  { path: '', component: HomeComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
