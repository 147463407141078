import { Component, Input, OnInit } from '@angular/core';
import { Note } from '@app/_models/note';
import { RestService } from '@app/_services/rest.service';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { AlertService } from '@app/_services';
import { MsalService } from '@azure/msal-angular';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-new-note',
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.scss'],
})
export class NewNoteComponent implements OnInit {
  public form!: FormGroup;
  noteDescription!: string;
  contractorId!: number;
  companyName!: string;
  loading = false;
  submitted = false;
  newNote!: Note;
  result!: string;
  noteTypes!: Observable<any>;
  @Input() max: any;
  today = moment().toDate();

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private restService: RestService,
    private alertService: AlertService,
    private msalService: MsalService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.contractorId = params['contractorId'];
      this.companyName = params['companyName'];
    });
    this.form = new FormGroup({
      noteDescription: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      noteTypeId: new FormControl(1),
      refNo: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.noteTypes = this.restService.getNoteTypes();
  }

  public onCancel = () => {
    this.location.back();
  };

  public createNote() {
    if (this.form.valid) {
      if (this.form.controls.noteDescription.value.trim() != '') {
        Swal.fire({
          title: 'Are you sure you want to add the New Note?',
          icon: 'question',
          showDenyButton: true,
          confirmButtonText: `Yes`,
          denyButtonText: `No`,
          confirmButtonColor: '#007bff',
          denyButtonColor: '#dc3545',
          focusDeny: true,
          width: 450,
          padding: '1em',
          heightAuto: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitted = true;
            this.alertService.clear();

            // stop here if form is invalid
            if (this.form.invalid) {
              return;
            }

            const userFullName =
              this.msalService.instance.getActiveAccount()!.name!;
            this.newNote = {
              contractorId: this.contractorId,
              noteTypeId: this.form.controls.noteTypeId.value,
              refNo:
                this.form.controls.refNo.value == ''
                  ? null
                  : this.form.controls.refNo.value,
              addedBy: userFullName,
              comments: this.form.controls.noteDescription.value,
              timeStamp: null,
            };

            this.loading = true;
            this.restService
              .addNewNote(this.newNote)
              .pipe(first())
              .subscribe({
                next: () => {
                  Swal.fire(
                    'New note has been added successfully!',
                    '',
                    'success'
                  );
                  this.router
                    .navigate([
                      '../contractor-details/' + this.newNote.contractorId,
                    ])
                    .then(() => {});
                },
                error: (error: string) => {
                  this.loading = false;
                  this.alertService.error(error);
                },
              });
          } else if (result.isDenied) {
            this.loading = false;
          }
        });
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };
}
