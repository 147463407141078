import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AccountInfo } from '@azure/msal-common';
import { MsalService } from '@azure/msal-angular';
import { GraphService } from './graph.service';
import { AlertService } from './alert.service';

interface Account extends AccountInfo {
  idTokenClaims?: {
    preferred_username?: string,
    groups?: string[],
    _claim_names?: {
      groups: string | string[]
    },
    _claim_sources?: {
      src1: {
        endpoint: string | string[]
      }
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class GroupGuardService  {

  constructor(private authService: MsalService, private graphService: GraphService, private router: Router, private alertService: AlertService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const expectedGroups = route.data.expectedGroups;
    let account: Account = this.authService.instance.getAllAccounts()[0];
    this.graphService.user.displayName = account.idTokenClaims?.preferred_username!;

    if (account.idTokenClaims?.groups?.some(e => expectedGroups.includes(e)) || this.graphService.user.groupIDs.some(e => expectedGroups.includes(e))) {
      if (account.idTokenClaims?.groups) {
        this.graphService.user.groupIDs = account.idTokenClaims?.groups;//Jay to recheck
      }
      return true;
    }


    if (account.idTokenClaims?.groups) {
      this.graphService.user.groupIDs = account.idTokenClaims?.groups;
    } else {
      if (account.idTokenClaims?._claim_names) {
        this.alertService.error('Sorry, You have too many group memberships. The application will now query Microsoft Graph to get the full list of groups that you are a member of. Please contact MK3 IT-Helpdesk with the details if you keep seeing this message.');
        this.router.navigate(['/overage']);
        return false;
      }

      this.alertService.error('Sorry,You are not in a group having access to the page you are trying to open. Please contact MK3 IT-Helpdesk with the details if you think this is a mistake.');
      return false;
    }

    this.alertService.error('Sorry, You do not have access to the page you are trying to open. Please contact MK3 IT-Helpdesk with the details if you think this is a mistake.');
    this.router.navigate(['']);
    return false;
  }
}