import { Directive, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appDisableMultipleClicks]',
})
export class DisableMultipleClicksDirective {
  @Input() debounceTimeMs = 1000; // Default debounce time in milliseconds (500 ms)
  private clickSubject = new Subject<void>();

  constructor() {
    this.clickSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe(() => this.enableClick());
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    this.disableClick();
    this.clickSubject.next();
  }

  private disableClick(): void {
    const button = this.getButtonElement();
    if (button) {
      try {
        button.setAttribute('disabled', 'true');
      } catch (e) {
        console.log(e);
      }
    }
  }

  private enableClick(): void {
    const button = this.getButtonElement();
    if (button) {
      try {
        button.removeAttribute('disabled');
      } catch (e) {
        console.log(e);
      }
    }
  }

  private getButtonElement(): HTMLButtonElement | null {
    // Assuming that the directive is used on a <button> element.
    // You can modify this method to handle other element types if needed.
    return document.querySelector(
      '[appDisableMultipleClicks]'
    ) as HTMLButtonElement;
  }
}
