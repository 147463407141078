import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Case } from '@app/_models/case';
import { WorkItem } from '@app/_models/work-item';

@Component({
  selector: 'app-sow-selection-dialog',
  templateUrl: './sow-selection-dialog.component.html',
  styleUrls: ['./sow-selection-dialog.component.scss']
})
export class SowSelectionDialogComponent implements OnInit {
  isLoading = true;
  case!: Case;
  refNo!: number;
  displayedColumns: string[] = ['select', 'itemRef', 'scope', 'area', 'tradeName', 'itemCode', 'description', 'additionalInfo'];
  dataSourceWorkItems!: MatTableDataSource<WorkItem>;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  selection = new SelectionModel<WorkItem>(true, []);

  constructor(public sowSelectionDialogRef: MatDialogRef<SowSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: WorkItem[]) {
  }

  ngOnInit(): void {
    if (this.dialogData) {
      const workItems: WorkItem[] = this.dialogData;
      workItems.forEach(item => this.addItemRefColumn(item));
      this.dataSourceWorkItems = new MatTableDataSource(workItems);
      this.matSort.sort(({ disableClear: true}) as MatSortable);
      this.dataSourceWorkItems.sort = this.matSort;
      this.refNo = workItems[0].refNo;
    }
  }

  addItemRefColumn(obj: any) {
    obj.itemRef = `${obj.sowId} (${obj.scopeId}.${obj.areaId}.${obj.itemId})`;
  }

  onInsertToSoW() {
    let selected: string[] = [];
    this.selection.selected.forEach(item => {
      selected.push((item.itemRef + ' - ' + (item.area ? (item.area + '. ') : '') + (item.description ? ((item.description.charAt(item.description.length - 1) == '.' ? item.description + ' ' : item.description.trim() + '. ')) : ' ') + (item.descriptionMk3ForENData ? ((item.descriptionMk3ForENData.charAt(item.descriptionMk3ForENData.length - 1) == '.' ? item.descriptionMk3ForENData + ' ' : item.descriptionMk3ForENData.trim() + '. ')) : '') + (item.additionalInfo ? ((item.additionalInfo.charAt(item.additionalInfo.length - 1) == '.' ? item.additionalInfo : item.additionalInfo.trim() + '.')) : '')).trim());
    });
    this.sowSelectionDialogRef.close(selected);
  }

  public onCancel = () => {
  };

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceWorkItems.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceWorkItems.data.forEach(row => this.selection.select(row));
  }
}
