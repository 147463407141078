export class EmailRecord {
    id: number = 0;
    contractorId: number | null = null;
    internalTradeId: number | null = null;
    instructionTypeId: number | null = null;
    instructionItemId: number | null = null;
    refNo!: number;
    pdfData: any = null;
    attachmentName: string | null = null;
    fromEmail: string | null = null;
    toEmail: string | null = null;
    ccEmails: string | null = null;
    subject: string | null = null;
    body: string | null = null;
    sentBy: string | null = null;
    dateSent: Date | null = null;
    signedReturned: Date | null = null;
    jobCost: number | null = null;
    retracted: boolean | null = null;
    retractedBy: string | null = null;
    retractedOn: Date | null = null;
    startDate: Date | null = null;
    endDate: Date | null = null;
    statusId: number = 1;
    additionalInternalTrades: string | null = null;
    jtmRating: number | null = null;
    qoWRating: number | null = null;
    hseRating: number | null = null;
    qualityComments: string | null = null;
}