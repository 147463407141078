export class JobHistory {
    id: number = 0;
    jobId: number | null = null;
    statusId: number | null = null;   
    comments: string | null = null;
    date: Date | null = null;
    addedBy: string | null = null;
    timeStamp: Date | null = null;
    invoiceNumber: string | null = null;
    invoiceAmount: number | null = null;
    isFinalInvoice: boolean | null = null;
    originalHistoryId: number | null = null;
}