<div class="main-container">
    <h2 *ngIf="dataSource && (dataSource.data.length != 1)">{{dataSource.data.length}} Invoices Paid by Finance</h2>
    <h2 *ngIf="dataSource && (dataSource.data.length == 1)">{{dataSource.data.length}} Invoice Paid by Finance</h2>

    <div class="loading-container" *ngIf="isLoading">
        <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="flex-container">
        <div class="center-aligned">
            <mat-form-field class="search-box-form-field">
                <mat-label>Search by Keyword</mat-label>
                <input #search matInput type="text" onfocus="this.select()" (keyup)="onSearchKeyUp($event)"
                    [value]="searchValue" autoFocus>
                <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                    (click)="onClearClicked(search)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

        </div>
        <div class="right-aligned"><button style="width: fit-content;" class="btn btn-sm btn-primary mat-raised-button"
                (click)="exporter.exportTable('xlsx',{fileName:'Contractor Invoices Paid ' + functionsService.getCurrentTimestampFormatted()})">Export
                to Excel</button></div>
    </div>
    <div style="text-align: right; padding: 5px;">
        <div>
            <div class="main-table-container">
                <mat-table matTableExporter class="mat-elevation-z8 table-striped" [dataSource]="dataSource" matSort
                    matSortStart="asc" #exporter="matTableExporter">
                    <ng-container matColumnDef="id">
                        <th class="w-3" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>ID
                        </th>
                        <td mat-cell *matCellDef="let jobHistory"><a href="/job-details/{{jobHistory.jobId}}"
                                target="_blank">{{jobHistory.id}}</a></td>
                    </ng-container>

                    <ng-container matColumnDef="refNo">
                        <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Ref No</th>
                        <td mat-cell *matCellDef="let jobHistory"><a
                                href="https://portal.mk3apps.com.au/case-details/{{jobHistory.refNo}}"
                                target="_blank">{{jobHistory.refNo}}</a></td>
                    </ng-container>

                    <ng-container matColumnDef="invoiceNumber">
                        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Invoice #</th>
                        <td mat-cell *matCellDef="let jobHistory">{{jobHistory.invoiceNumber}}</td>
                    </ng-container>

                    <ng-container matColumnDef="invoiceAmount">
                        <th class="w-2" style="text-align: right;" mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                        <td mat-cell *matCellDef="let jobHistory" style="text-align: right;">
                            <div *ngIf="jobHistory.invoiceAmount">${{jobHistory.invoiceAmount | number : '1.2-2'}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tradingName">
                        <th class="w-15" mat-header-cell *matHeaderCellDef mat-sort-header>Contractor/ Internal Trade
                        </th>
                        <!-- <td mat-cell *matCellDef="let jobHistory"><a
                                href="/contractor-details/{{jobHistory.contractorId}}"
                                target="_blank">{{jobHistory.tradingName}}</a></td> -->

                        <td mat-cell *matCellDef="let jobHistory">
                            <div *ngIf="jobHistory.contractorId">
                                <a href="/contractor-details/{{jobHistory.contractorId}}"
                                    target="_blank">{{jobHistory.tradingName}}</a>
                            </div>
                            <div *ngIf="jobHistory.internalTradeID"><a
                                    href="/internal-trade-details/{{jobHistory.internalTradeID}}"
                                    target="_blank">{{jobHistory.internalTradeName}}</a></div>
                        </td>

                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                        <td mat-cell *matCellDef="let jobHistory">{{jobHistory.date | date: 'dd/MM/yyyy'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="addedBy">
                        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Paid By</th>
                        <td mat-cell *matCellDef="let jobHistory">{{jobHistory.addedBy}}</td>
                    </ng-container>

                    <ng-container matColumnDef="timeStamp">
                        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Paid On</th>
                        <td mat-cell *matCellDef="let jobHistory">{{jobHistory.timeStamp | date: 'dd/MM/yyyy HH:mm'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="instructionTypeId">
                        <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
                        <td mat-cell class="mat-cell-centered" *matCellDef="let jobHistory">
                            <!-- <div *ngIf="jobHistory.instructionTypeId == 1">NIB</div>
                            <div *ngIf="jobHistory.instructionTypeId == 3">RR</div>
                            <div *ngIf="jobHistory.instructionTypeId == 5">Mk3</div> -->
                            <div *ngIf="jobHistory.instructionTypeId == 1"><div style="display: none;">NIB</div><img height="30"
                                    src="../assets/nib-favicon.png" matTooltip="NIB" matTooltipPosition="right" /></div>
                            <div *ngIf="jobHistory.instructionTypeId == 3"><img height="30"
                                    src="../assets/rr-favicon.png" matTooltip="RR" matTooltipPosition="right" /></div>
                            <div *ngIf="jobHistory.instructionTypeId == 5">
                                <img height="30" src="../assets/mk3-favicon.png" matTooltip="Mk3"
                                    matTooltipPosition="right" />
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
                        <td mat-cell *matCellDef="let jobHistory">
                            <button *ngIf="jobHistory.originalHistoryId" mat-raised-button
                                class="btn btn-sm btn-success"
                                [routerLink]="['/job-details/',jobHistory.jobId]">Details</button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="viewDocument">
                        <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Invoice</th>
                        <td mat-cell *matCellDef="let jobHistory">
                            <button *ngIf="jobHistory.jobId" mat-raised-button class="btn btn-sm btn-primary"
                                (click)="openInvoicePDF(jobHistory.originalHistoryId)">PDF</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999">No Paid Invoices Available</td>
                    </tr>
                </mat-table>
                <mat-paginator *ngIf="dataSource" #paginator [pageSize]="dataSource.data.length" [pageSizeOptions]="[15, 50, 100, dataSource.data.length]"
                    showFirstLastButtons class="mat-paginator">
                </mat-paginator>
            </div>
            <div class="center-button-container" *ngIf="!isLoading">
            </div>
        </div>
    </div>