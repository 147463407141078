import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { JobFile } from '@app/_models/job-file';
import { JobHistoryView } from '@app/_models/job-history-view';
import { AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

type AoA = any[][];

@Component({
  selector: 'app-invoices-paid',
  templateUrl: './invoices-paid.component.html',
  styleUrls: ['./invoices-paid.component.scss'],
})
export class InvoicesPaidComponent implements OnInit {
  invoicesArray: AoA = [[], []];
  displayedColumns: string[] = [
    'id',
    'refNo',
    'invoiceNumber',
    'invoiceAmount',
    'tradingName',
    'instructionTypeId',
    'date',
    'addedBy',
    'timeStamp',
    'details',
    'viewDocument'
  ];

  dataSource!: MatTableDataSource<JobHistoryView>;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  
  isLoading = true;
  searchValue = localStorage.getItem('searchTextInvoicesPaid')
    ? localStorage.getItem('searchTextInvoicesPaid')
    : '';

  constructor(
    public restService: RestService,
    private alertService: AlertService,
    public functionsService: FunctionsService
  ) {
    this.getRemoteData();
  }

  ngOnInit(): void {}

  getRemoteData() {
    try {
      this.isLoading = true;
      this.getPaidInvoices();
      return true;
    } catch (error: any) {
      this.isLoading = false;
      this.alertService.error(error);
      return false;
    }
  }

  getPaidInvoices() {
    return this.restService.getPaidInvoices().subscribe(
      (jobHistory: JobHistoryView[]) => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(jobHistory);
        this.dataSource.sort = this.matSort;
        this.dataSource.paginator = this.paginator;
      },
      (error) => {
        this.isLoading = false;
        this.alertService.error(error);
      }
    );
  }

  openInvoicePDF(id: number) {
    this.restService.getJobHistoryView(id).subscribe((data) => {
      if (data.jobFileId) {
        this.restService.getInvoicePDF(data.jobFileId).subscribe(
          (data: JobFile) => {
            this.functionsService.openPdf(data.fileData);
          },
          (error) => {
            this.alertService.error(error);
          }
        );
      }
    });
  }

  //Search by keyword
  onSearchKeyUp(event: Event) {
    try {
      var currentFilter = (event.target as HTMLInputElement).value;
      this.dataSource.filter = currentFilter;
      localStorage.setItem('searchTextInvoicesPaid', currentFilter);
      
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    } catch (error) {
      Swal.fire('Error in Search by keyword : ' + error, '', 'error');
    }
  }

  //Clear search by keyword
  onClearClicked(search: { value: string }) {
    this.dataSource.filter = '';
    search.value = '';
    this.searchValue = '';
    localStorage.setItem('searchTextInvoicesPaid', '');

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
