export class DocumentDetail {
    documentId: number = 0;
    contractorId: number = 0;
    documentTypeId: number = 0;
    documentData: any = null;
    addedById:number|null=null;
    addedOn:any =null;
    comment: string | null=null;
    expireDate: any|null =null;
    fileType : string | null=null;
}