<div id="print" #print>
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  
  <section fxLayout="row wrap" fxLayoutAlign="center center" class="row-height bdrop">
  <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit(approvalOptionSelected)"
    fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="5px">
    <mat-dialog-content text-align="middle" style="overflow-y: hidden !important;">
      <mat-card fxFlex="650px" fxFlex.xs="100%">
        <mat-card-title>Invoice Approval</mat-card-title>
        <mat-card-content>
          <div>
            <span style="white-space: nowrap;">
              <div class="search-option-radio" style="display: flex;">
                <mat-radio-group aria-label="Select an option" formControlName="approvalOptions"
                  [value]="approvalOptionSelected">
                  <mat-radio-button (change)=radioChange($event) class="search-option-radio-button"
                    *ngFor="let radio of radioButtons" [value]="radio.id">
                    {{ radio.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </span>
            <mat-grid-list class="grid-tile-content" cols="12" rowHeight="190px">
              <mat-grid-tile class="grid-tile" colspan="5">
                <mat-list>
                  <mat-list-item class="list-key-column">Invoice Number:</mat-list-item>
                  <mat-list-item class="list-key-column">Invoice Amount ($):</mat-list-item>
                  <mat-list-item *ngIf="approvalOptionSelected == 1 && !(this.isLargeLossApprovalRequired && emailRecord.statusId != 6)" class="list-key-column">Amount Approved ($):</mat-list-item>
                  <!-- <mat-list-item *ngIf="!((this.isSeniorApprovalRequired || this.isLargeLossApprovalRequired) && emailRecord.statusId != 6) || currentUser=='john.kontalipos@mk3.com.au'" class="list-key-column">Is this the Final Invoice for this particular instruction?:</mat-list-item> -->
                  <mat-list-item *ngIf="!((this.isSeniorApprovalRequired || this.isLargeLossApprovalRequired) && emailRecord.statusId != 6) || currentUser=='paul.vaughn@mk3.com.au'" class="list-key-column">Is this the Final Invoice for this particular instruction?:</mat-list-item>
                </mat-list>
              </mat-grid-tile>
              <mat-grid-tile class="grid-tile" colspan="7">
                <mat-list>
                  <mat-list-item class="list-value-column"><input type="text" style="display: inline-block;"
                    formControlName="invoiceNo" class="form-control" />
                  <mat-error *ngIf="hasError('invoiceNo', 'pattern')">Invalid!</mat-error>
                </mat-list-item>
                <mat-list-item class="list-value-column">
                  <input type="text" readonly style="display: inline-block;" class="form-control" formControlName="originalInvoiceAmount"/>
              </mat-list-item>
              <mat-list-item *ngIf="approvalOptionSelected == 1 && !(this.isLargeLossApprovalRequired && emailRecord.statusId != 6)" class="list-value-column">
                <input type="text" style="display: inline-block; width: fit-content!important;"
                       inputmode="numeric" format="1.2-2" formControlName="invoiceAmount" class="form-control" maxlength="10"
                       placeholder="0000000.00" [ngClass]="{ 'is-invalid': form.controls.invoiceAmount.errors }" /><br/>
                <!-- <mat-error *ngIf="form.controls.invoiceAmount.hasError('max')" style="display: block;"> 
                  &nbsp;* Amount must be less than or equal the original invoice amount!
                </mat-error> -->
                <mat-error *ngIf="form.controls.invoiceAmount.hasError('pattern') || form.controls.invoiceAmount.hasError('max')">
                  &nbsp;* Invalid!
                </mat-error>
                <mat-error *ngIf="form.controls.invoiceAmount.hasError('required')">
                  &nbsp;* Required!
                </mat-error>
                </mat-list-item>
                  <mat-list-item *ngIf="!((this.isSeniorApprovalRequired || this.isLargeLossApprovalRequired) && emailRecord.statusId != 6) || currentUser=='paul.vaughn@mk3.com.au'" class="list-value-column">
                    <select formControlName="isFinalInvoice" class="form-control" style="display: inline-block; width: fit-content!important;" 
                    [ngClass]="{ 'is-invalid': form.controls.isFinalInvoice.errors }" (change)="onChangeIsFinalInvoice()">
                    <option [ngValue]="null">--- Select ---</option>
                    <option [ngValue]="false">No</option>
                    <option [ngValue]="true">Yes</option>
                  </select></mat-list-item>
                </mat-list>
              </mat-grid-tile>
            </mat-grid-list>
            <label *ngIf="approvalOptionSelected == 1" class="label-custom">Approve Comments:&nbsp;</label>
              <label *ngIf="approvalOptionSelected == 2" class="label-custom">Reject Comments:&nbsp;</label>
            <mat-form-field style="text-align: center;display: block; width: 100%;">
              <textarea matInput width="800px" cdkTextareaAutosize cdkAutosizeMinRows="1" style="cursor: text;"
                [ngClass]="{ 'is-invalid': form.controls.approveRejectComment.errors,'invoicecomment':true }"
                formControlName="approveRejectComment" id="approveRejectComment"></textarea>
              <mat-hint align="end">Characters {{form.controls.approveRejectComment.value.length || 0}}</mat-hint>
            </mat-form-field>

            <div *ngIf="approvalOptionSelected == 1 && form.controls.isFinalInvoice.value">
              <table>
                <tr colspan="2" class="green-text">Rate Contractor</tr>
                <tr>
                  <td class="w-50">
                    <label class="label-custom">Quality of Work Rating:&nbsp;</label>
                  </td>
                  <td class="w-50"><select formControlName="qowRating" class="form-control"
                      style="display: inline-block; width: 30%;" [value]='null' (change)="onChangeRating()">
                      <option [ngValue]="null" selected value="null">Select Rating</option>
                      <option *ngFor="let option of rateOptions" [ngValue]="option.id">{{option.name}}</option>
                    </select></td>
                  <br />
                </tr>
                <tr>
                  <td class="w-50">
                    <label class="label-custom">Job Time management Rating:&nbsp;</label>
                  </td>
                  <td class="w-50"><select formControlName="jobTimeRating" class="form-control"
                      style="display: inline-block; width: 30%;" [value]='null' (change)="onChangeRating()">
                      <option [ngValue]="null" selected value="null">Select Rating</option>
                      <option *ngFor="let option of rateOptions" [ngValue]="option.id">{{option.name}}</option>
                    </select></td>
                  <br />
                </tr>
                <tr>
                  <label class="label-custom">
                    Rating Comments:&nbsp;</label>
                  <mat-form-field>
                    <input matInput type="text" formControlName="ratingComments">
                  </mat-form-field>
                </tr>
              </table>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <span class="red-text" style="white-space: nowrap;" *ngIf="approvalOptionSelected == 1"><mat-checkbox [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}"><ul>
                <li>I have checked invoice details including the company it is invoiced to; <br/>Job address and details and  that the ABN is displayed.</li>                                    
                <li>I have checked that the cost is reasonable and as per the approved cost <br/>established and/or quoted.</li>
                <li>I have double checked that all works listed as complete in the invoice <br/>tallies with the invoice amount and with our costed scope.</li>
                <li>I confirm that the works have been adequately reviewed for quality assurance</li>
            </ul>
                </mat-checkbox>
          </span>
          <span style="white-space: nowrap;">
            <span style="white-space: nowrap;">
              <button type="button" *ngIf="jobHistoryView.id" mat-raised-button
              class="btn btn-sm btn-primary" (click)="openInvoicePDF(jobHistoryView.id)">View Invoice</button>
            </span>
            <span style="white-space: nowrap;" *ngIf="approvalOptionSelected == 1">
              &nbsp;<button mat-button type="button" (click)="onSubmit(approvalOptionSelected)" mat-raised-button
                class="btn btn-sm btn-success" [disabled]="!form.valid || isLoading || !isChecked">Approve</button></span>
            <span style="white-space: nowrap;" *ngIf="approvalOptionSelected == 2">
              &nbsp;<button mat-button type="button" (click)="onSubmit(approvalOptionSelected)" mat-raised-button
                class="btn btn-sm btn-danger" [disabled]="!form.valid || isLoading">Reject</button>
            </span>
            <span style="white-space: nowrap;">
              &nbsp;<button mat-raised-button mat-dialog-close type="button" class="btn mat-button-base" [disabled]="isLoading">Cancel</button>
            </span>
            <!-- <span style="white-space: nowrap;">
              &nbsp;<mat-checkbox [(ngModel)]="isCheckedInvoice" [ngModelOptions]="{standalone: true}">I have checked the invoice</mat-checkbox>
            </span> -->

          </span>
          <div style="margin: 2px;" class="red-text" *ngIf="stateManager && (stateManager.email != '')">* This invoice requires senior review by <u>{{stateManager.employeeName}}</u>, who will be notified after your approval</div>
          <div style="margin: 2px;" class="blue-text">Total Invoices Approved for the Job# {{emailRecord.refNo}} as of now is <b><u>${{invoicesApprovedTotal | number: '1.2-2'}}</u></b></div>
        </mat-card-actions>
      </mat-card>
    </mat-dialog-content>
  </form>
</section>