<div id="print" #print>
    <div class="loading-container" *ngIf="isLoading">
        <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <section fxLayout="row wrap" fxLayoutAlign="center center" class="row-height" class="bdrop" style="width:1000px">
        <div style="width:42%">
            <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()" fxLayout="column wrap"
                fxLayoutGap="5px">
                <mat-dialog-content text-align="left" style="overflow-y: hidden !important;">
                    <mat-card fxFlex.xs="100%">
                        <mat-card-title>Invoice Upload</mat-card-title>
                        <mat-card-content>
                            <div>
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">Invoice Number:&nbsp;&nbsp;&nbsp;</label>
                                    <input type="text" style="display: inline-block; width: fit-content!important;"
                                        formControlName="invoiceNo" class="form-control" />
                                    <mat-error *ngIf="hasError('invoiceNo', 'pattern')">Invalid!</mat-error>
                                </span>
                                <br />
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">Total Amount:&nbsp;&nbsp;&nbsp;&nbsp;$&nbsp;</label>
                                    <input type="text" style="display: inline-block; width: fit-content!important;"
                                        inputmode="numeric" format="1.2-2" formControlName="invoiceAmount"
                                        class="form-control" maxlength="10" placeholder="0000000.00" />
                                    <mat-error *ngIf="hasError('invoiceAmount', 'pattern')">Invalid!</mat-error>
                                    <br />
                                </span>
                                <br />
                                <span style="white-space: nowrap;">
                                    <input type="file" id="fileUpload" formControlName="invoiceFile"
                                        class="form-control"
                                        style="display: inline-block; width: fit-content!important;"
                                        accept="application/pdf" (change)="onFileSelected($event)" #fileUpload />
                                </span>
                                <br />
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">Comment:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <input type="textarea" id="invoiceComment" formControlName="invoiceComment"
                                        class="form-control"
                                        style="display: inline-block; width: fit-content!important; margin-left: 27px; margin-top: 22px; width: 224px !important;" />
                                </span>
                            </div>
                        </mat-card-content>
                        <mat-card-actions class="mat-card-actions">
                            <span style="white-space: nowrap;">
                                <span class="red-text" style="white-space: nowrap;">
                                    &nbsp;<br /><mat-checkbox [(ngModel)]="isChecked"
                                        [ngModelOptions]="{standalone: true}">
                                        <ul>
                                            <li>I have checked that I am uploading the <u>correct document.</u></li>
                                            <li>I confirm that the invoice is addressed to the<br /><u>same company</u>
                                                who issued the instructions.</li>
                                            <li>I have checked that the invoice has the <u>correct</u> job
                                                address,<br />Job reference number, and ABN is displayed.</li>
                                        </ul>
                                    </mat-checkbox>
                                </span><br />
                                <button appDisableMultipleClicks [debounceTimeMs]="10" mat-raised-button type="button"
                                    (click)="onSubmit()" mat-raised-button class="btn btn-sm btn-primary"
                                    [disabled]="!form.valid || isLoading || !isChecked">Submit</button>
                                <span style="white-space: nowrap;">
                                    <button mat-raised-button mat-dialog-close type="button" class="btn mat-button-base"
                                        [disabled]="isLoading">Cancel</button>
                                </span>
                            </span>
                        </mat-card-actions>
                    </mat-card>
                </mat-dialog-content>
            </form>
        </div>
        <div *ngIf="!pdfSrc" style="width: 58%;">
            <p style="text-align:center">No File uploaded</p>
        </div>
        <div *ngIf="pdfSrc" style="width: 58%;">
            <pdf-viewer [src]="pdfSrc" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="true"
                [zoom]=".95" [zoom-scale]="'page-width'" [stick-to-page]="true" [render-text]="true"
                [show-borders]="true" style="width: 100%; height:450px"></pdf-viewer>
        </div>
    </section>