<div id="print" #print>
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <section fxLayout="row wrap" fxLayoutAlign="center center" class="row-height" class="bdrop">
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()" fxLayout="column wrap"
      fxLayoutAlign="center center" fxLayoutGap="2px">
      <mat-dialog-content text-align="middle" style="overflow-y: hidden !important; margin-top:-25px;">
        <mat-card fxFlex="650px" fxFlex.xs="100%">
          <mat-card-title>{{title}}</mat-card-title>
          <mat-card-content>

            <div style="display: flex; align-items: center;">
              <div style="width: 100%">
                <span style="white-space: nowrap; display: flex; align-items: center;">
                  <mat-label class="label-custom">Select Instruction/ Quote Type:&nbsp;</mat-label>
                  <select placeholder="Select Instruction Type" formControlName="instructionTypeId"
                    style="display: inline-block; width: max-content;" class="form-control"
                    [ngClass]="{ 'is-invalid': form.controls.instructionTypeId.errors }"
                    (change)="onChangeInstructionType($event)">
                    <option value="0" disabled selected>--- Select ---</option>
                    <option *ngFor="let item of instructionTypes | async" [ngValue]="item.id">
                      {{ item.type }}
                    </option>
                  </select>
                </span>
                <label class="label-custom">Mk3 Ref:&nbsp;</label>
                <label style="color: var(--mk3red); background-color: yellow; font-weight: 500;">{{case.refNo}}
                  {{case.lastNameS}}</label><br />
                <label class="label-custom">Site Address:&nbsp;</label>
                <label style="color: var(--mk3red); font-weight: 500;"><a
                    href="https://www.google.com.au/maps/place/{{this.functionsService.blankIfNull(case.situationStreet.replace('/', '%2F'))}} {{this.functionsService.blankIfNull(case.situationSuburb)}} {{this.functionsService.blankIfNull(case.state)}} {{this.functionsService.blankIfNull(case.postCode)}}"
                    target="_blank">{{this.functionsService.blankIfNull(case.situationStreet)}}
                    {{this.functionsService.blankIfNull(case.situationSuburb)}}
                    {{this.functionsService.blankIfNull(case.state)}}
                    {{this.functionsService.blankIfNull(case.postCode)}}</a></label><br />
              </div>
              <div *ngIf="pdfDetails.instructionTypeId == 1 || pdfDetails.instructionTypeId == 2"
                style="margin-right: 100px;">
                <img height="100" src="../assets/nib-logo.png" matTooltip="NIB" matTooltipPosition="right" />
              </div>
              <div *ngIf="pdfDetails.instructionTypeId == 3 || pdfDetails.instructionTypeId == 4"
                style="margin-right: 100px;">
                <img height="100" src="../assets/rr-logo-square.png" matTooltip="RR" matTooltipPosition="right" />
              </div>
              <div *ngIf="pdfDetails.instructionTypeId == 5" style="margin-right: 100px">
                <img height="100" src="../assets/mk3-logo.png" matTooltip="Mk3" matTooltipPosition="right" />
              </div>
            </div>


            <span style="white-space: nowrap;">
              <label class="label-custom">Contact:&nbsp;</label>
              <select formControlName="customerContactType" class="form-control"
                style="display: inline-block; width: 90%;"
                [ngClass]="{ 'is-invalid': form.controls.customerContactType.errors }"
                (change)="onChangeCustomerContactType($event)">
                <option *ngFor="let item of customerContactsArray" [value]="item.id">
                  <ng-container *ngIf="item.name !== ''; else elseNoName">
                    {{ item.description }} - {{ item.number }} - {{ item.name }} - {{ item.claimContactRole }}
                  </ng-container>
                  <ng-template #elseNoName>
                    {{ item.description }} - {{ item.number }}
                  </ng-template>
                </option>
              </select>
              <br>
              <input type="text" style="display: inline-block; width: 90%;margin-top: 5px;margin-left: 72px;" placeholder="Add Contact details"
                  class="form-control" [(ngModel)]="otherContact" *ngIf="customerContactType==0" [ngModelOptions]="{standalone: true}" [ngClass]="{ 'is-invalid': otherContact=='' }"/>
            </span>

            <div
              *ngIf="pdfDetails.instructionTypeId == 1 || pdfDetails.instructionTypeId == 3 || pdfDetails.instructionTypeId == 5">
              <span style="white-space: nowrap;">
                <label class="label-custom">Attend Site: </label>
                <div class="search-option-radio" style="display: inline-block;">
                  <mat-radio-group aria-label="Select an option" formControlName="siteAttendOptions"
                    [value]="siteOptionSelected">
                    <mat-radio-button (change)=radioChange($event) class="search-option-radio-button"
                      *ngFor="let radio of radioButtons" [value]="radio.id">
                      {{ radio.name }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </span>
              <div *ngIf="siteOptionSelected == 1" class="search-box-container">
                <table>
                  <tr>
                    <td style="width: 10%">Date: </td>
                    <td style="width: 40%">
                      <mat-form-field style="width: fit-content">
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date"
                          formControlName="inspectionDate" id="inspectionDate" (click)="picker.open()" [min]="today">
                        <mat-datepicker-toggle matSuffix [for]="picker" style='height: 5em'></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td style="width: 10%">Time: </td>
                    <td style="width: 40%">
                      <input class="form-control" style="width: fit-content !important; height: 32px"
                        [ngxTimepicker]="insptimepicker" (ngModel)="inspectionTime"
                        [value]="functionsService.timeToTimeString(inspectionTime.toString())" readonly>
                      <ngx-material-timepicker [timepickerClass]="'timepicker-custom'"
                        (timeSet)="onChangeInspectionTime($event)" #insptimepicker [format]="12" [minutesGap]="15"
                        [defaultTime]="'00:00 AM'"></ngx-material-timepicker>
                    </td>
                  </tr>
                </table>
              </div>
              <div *ngIf="siteOptionSelected == 2">
                Organise directly with the contact and advise in response to this email.
              </div>
              <div *ngIf="siteOptionSelected == 3">
                <mat-form-field>
                  <input matInput type="text" formControlName="customSiteAttendInstructions">
                </mat-form-field>
              </div>

              <span style="white-space: nowrap; padding-bottom: 50px;">
                <label class="label-custom">Expected Invoice Amount (Inc. GST):&nbsp;$&nbsp;</label>
                <input type="text" style="display: inline-block; height:32px; width: fit-content!important;" inputmode="numeric"
                  format="1.2-2" formControlName="jobCost" class="form-control" maxlength="10"
                  placeholder="0000000.00" [ngClass]="{ 'is-invalid': form.controls.jobCost.errors }"/>
                <mat-error *ngIf="hasError('jobCost', 'pattern')">Invalid!</mat-error>
              </span>
              <br>
              <label class="label-custom" style="margin-top: 10px;">Scope of Works:&nbsp;</label>
              <button mat-button type="button" (click)="onSelectSoW()" mat-raised-button
                class="btn btn-sm btn-primary">Select Scope of Works</button>&nbsp;
              <button [hidden]="!this.isInternalInstructions" mat-button type="button" (click)="onAddAdditionalTrades()"
                mat-raised-button class="btn btn-sm btn-primary" style="height:32px;">Add Additional Trades</button>
            </div>

            <div *ngIf="pdfDetails.instructionTypeId == 2 || pdfDetails.instructionTypeId == 4" style="flex: auto;">
              <label class="label-custom" style="margin-top: 10px;"
                *ngIf="pdfDetails.instructionTypeId == 2 || pdfDetails.instructionTypeId == 4">Quote Details:&nbsp;</label>
              <button mat-button type="button" (click)="onSelectSoW()" mat-raised-button
                class="btn btn-sm btn-primary">Select Scope of Works</button>
            </div>

            <mat-form-field style="text-align: center;display: block;">
              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5"
                [ngClass]="{ 'is-invalid': form.controls.customerContactType.errors }" formControlName="description"
                id="description"
                style="text-align: left; display:block; float: left; width: 100%; max-height: 120px;resize: none;"></textarea>

                <mat-list style="width: 100%; float: right; font-size: small; vertical-align: top; background-color:lightyellow;" *ngIf="selectedFiles && selectedFiles!.length>0">
                  <ng-container *ngFor="let file of selectedFiles">
                    <mat-list-item style="height: 23px;">
                      <mat-icon style="margin-left: -15px;" matListItemIcon (click)="openDocument(file.name)">attach_file</mat-icon>
                      <div style="text-align: left; overflow: hidden; cursor: pointer;" matListItemLine >
                        <a href="javascript:void(0)" (click)="openDocument(file.name)" style="text-decoration: underline; cursor: pointer;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ file.name.length>80? (file.name.substring(0,77) +'...' + file.name.split('.').pop()) : file.name }}</a>
                      </div>
                      <mat-icon style="margin-right: -25px;" matListItemIcon (click)="removeAttachment(file.name)">delete</mat-icon>
                    </mat-list-item>
                  </ng-container>
                </mat-list>
      
              <mat-hint align="end">Characters {{form.controls.description.value.length || 0}}</mat-hint>
            </mat-form-field>

            <label class="label-custom">Email Body (Please edit below as required): </label>
            <mat-form-field style="text-align: center;display: block;">
              <textarea matInput width="800px" cdkTextareaAutosize cdkAutosizeMinRows="5"
                [innerHTML]="form.controls.emailBody" [ngClass]="{ 'is-invalid': form.controls.emailBody.errors }"
                formControlName="emailBody" id="emailBody"
                style="text-align: left;display: inline; min-width:500px; display:block; max-height: 120px;resize: none;"></textarea>
              <mat-hint
                [ngStyle]="(form.controls.emailBody.value.length > 2000 == true) ? {'color': 'red', 'font-weight': '500'} : null"
                align="end">Characters {{form.controls.emailBody.value.length || 0}}/2000</mat-hint>
            </mat-form-field>
          </mat-card-content>
          <mat-card-actions>
            <span class="red-text" style="white-space: nowrap;">
              &nbsp;<mat-checkbox [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}">I have checked that I am sending from the <u>correct company</u></mat-checkbox>
            </span><br/>
            <button mat-button type="button" (click)="onPreview(false)" mat-raised-button class="btn btn-sm btn-success"
              [disabled]="!form.valid || isLoading || (customerContactType == 0 && otherContact =='' )">Preview Document</button>

            <button mat-button type="button" mat-raised-button class="btn btn-sm btn-color-navi margin-left-10" [disabled]="!form.valid || isLoading || (customerContactType == 0 && otherContact =='' )" onclick="fileUpload.click()">Attach Files</button>
            <input type="file" id="fileUpload" formControlName="documentFile"
            class="form-control" [disabled]="!form.valid || isLoading || !isChecked"
            style="width:350px!important; margin: 5px;display: none;"
            accept="application/pdf,image/png,image/jpeg,image/gif,image/jpg"
            onclick="this.value = null" 
            (change)="onFileSelected($event)" #fileUpload />

            <ng-template #scopeWorks >
              <mat-dialog-content text-align="middle" style="overflow-y: hidden !important; width: 900px;">
                <mat-card-title>Scope of Works Editor</mat-card-title>
                <mat-card fxFlex.xs="100%">
                    <mat-card-content>
                        <div style="align-items: center;">
                          <textarea matInput cdkAutosizeMinRows="15" formControlName="scopeWorksContent"
                            id="scopeWorksContent" style="text-align: left; border: 1px; background-color:azure; display:block; float: left; overflow-y: scroll; resize: none; width: 100%; height:720px;">
                          </textarea>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="mat-card-actions" fxLayoutAlign="center center">
                      <span style="white-space: nowrap;">
                        <button appDisableMultipleClicks [debounceTimeMs]="10" mat-raised-button type="button"
                          (click)="onEditScopeWorks(true)" class="btn btn-sm btn-primary"
                          [disabled]="isLoading">Done</button>
                        <button mat-raised-button type="button"
                          style="margin-left:20px;margin-bottom: 10px;" class="btn btn-success"
                          (click)="onEditScopeWorks(false)" [disabled]="isLoading">Cancel</button>
                      </span>
                  </mat-card-actions>
                </mat-card>
              </mat-dialog-content>
            </ng-template>

            <ng-template #attachmentDialog >
              <mat-dialog-content text-align="middle" style="overflow-y: hidden !important; width: 900px;">
                <mat-card-title>Attachment File Upload</mat-card-title>
                <mat-card fxFlex.xs="100%" *ngIf="currentFile[0]">
                    <mat-card-content>
                        <div style="align-items: center;padding-left: 30px;">
                          <p style="overflow-wrap: break-word;line-height: 1.1;">
                            <label class="label-custom">Document Name: {{ currentFile[0].name }}</label><br />
                            <label class="label-custom">Document Size: {{ functionsService.formatBytes(currentFile[0].file.size) }}</label><br />
                            <label class="label-custom">Document Type: {{ currentFile[0].file.type }}</label><br />
                            <label class="label-custom">Document Last Modified Date: {{ currentFile[0].file.lastModifiedDate | date: 'dd/MMM/yyyy HH MM'}}</label>
                          </p>
                        </div>

                        <div *ngIf="pdfSrc && isPdf" style="display: flex; justify-content: center;">
                          <pdf-viewer [src]="this.currentFile[0].base64!" [rotation]="0" [original-size]="false" [show-all]="true"
                              [fit-to-page]="true" [zoom]=".97" [zoom-scale]="'page-width'" [stick-to-page]="true"
                              [render-text]="true" [show-borders]="true"
                              style="width: 100%; height:500px;"></pdf-viewer>
                        </div>
                        <div *ngIf="pdfSrc && !isPdf" style="display: flex; justify-content: center;">
                          <img [src]="this.currentFile[0].base64!" alt="Image" style="max-height:500px;max-width: 900px;">
                        </div>
                        <div style="background-color: red; color: white;">
                          <h4 *ngIf="attachmentExceed20MB!==''">{{ attachmentExceed20MB }}</h4>
                          <h4 *ngIf="attachSameFileName!==''">{{ attachSameFileName }}</h4>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="mat-card-actions" fxLayoutAlign="center center">
                      <span style="white-space: nowrap;">
                        <button appDisableMultipleClicks [debounceTimeMs]="10" mat-raised-button type="button"
                          (click)="onAddFile()" mat-raised-button class="btn btn-sm btn-primary"
                          [disabled]="isLoading">Add File</button>
                        <button mat-raised-button type="button" style="margin-left:20px;margin-bottom: 10px;" *ngIf="currentFile && currentFile.length>0 && !isNewAttachment"
                        (click)="removeAttachment(currentFile[0].name)" [disabled]="isLoading" class="btn btn-danger">Delete File</button>
                        <button mat-raised-button mat-dialog-close type="button"
                          style="margin-left:20px;margin-bottom: 10px;" class="btn btn-success"
                          (click)="clearValidationMsg()" [disabled]="isLoading">Cancel</button>
                      </span>
                  </mat-card-actions>
                </mat-card>
              </mat-dialog-content>
            </ng-template>
            <!-- <button mat-button type="button" (click)="uploadDocument()" mat-raised-button class="btn btn-sm btn-success"
            [disabled]="!form.valid || isLoading">Upload Document</button> -->
            <button mat-button type="button" (click)="onPreview(true)" mat-raised-button class="btn btn-sm btn-danger"
              [disabled]="!form.valid || isLoading || !isChecked">Send Email</button>
            <button mat-raised-button mat-dialog-close type="button" class="btn mat-button-base">Cancel</button>
          </mat-card-actions>
        </mat-card>
      </mat-dialog-content>
    </form>
  </section>
</div>