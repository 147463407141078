import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContractorComponent } from './contractor/contractor.component';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from './_components';
import { HomeComponent } from './home/home.component';
import { Mk3MaterialModule } from './material-module';
import { ContractorDetailsComponent } from './contractor-details/contractor-details.component';
import { AddEditContractorComponent } from './add-edit-contractor/add-edit-contractor.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CheckboxlistDirective } from './_directives/check-box-list.directive';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { OverageComponent } from './overage/overage.component';
import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { GraphService } from './_services/graph.service';
import { GroupGuardService } from './_services/group-guard.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NewNoteComponent } from './contractor-details/new-note/new-note.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CdkTableModule } from '@angular/cdk/table';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { InputRefNoDialog } from './home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { GenerateDocumentDialogComponent } from './generate-document-dialog/generate-document-dialog.component';
import { FormFocusDirective } from './_directives/form-focus.directive';
import { InternalTradesComponent } from './internal-trades/internal-trades.component';
import { InternalTradeDetailsComponent } from './internal-trade-details/internal-trade-details.component';
import { SowSelectionDialogComponent } from './sow-selection-dialog/sow-selection-dialog.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { SelectInternalTradesComponent } from './select-internal-trades/select-internal-trades.component';
import { InvoiceApprovalDialogComponent } from './job-details/invoice-approval-dialog/invoice-approval-dialog.component';
import { DisableMultipleClicksDirective } from './_directives/disable-multiple-clicks.directive';
import { InvoiceUploadDialogComponent } from './job-details/invoice-upload-dialog/invoice-upload-dialog.component';
import { ApprovedInvoicesComponent } from './approved-invoices/approved-invoices.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { InvoicesPaidComponent } from './invoices-paid/invoices-paid.component';
import { InvoicesAwaitingApprovalComponent } from './invoices-awaiting-approval/invoices-awaiting-approval.component';
import { AbnMaskDirective } from './_directives/abn-mask.directive';
import { AcnMaskDirective } from './_directives/acn-mask.directive';
import { DocumentDetailsComponent } from './contractor-details/document-details/document-details.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

//const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal
export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

//Here we pass the configuration parameters to create an MSAL instance.
//For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

//MSAL Angular will automatically retrieve tokens for resources 
//added to protectedResourceMap. For more info, visit: 
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(protectedResources.graphApiUser.endpoint, protectedResources.graphApiUser.scopes);
  protectedResourceMap.set(protectedResources.graphApiGroups.endpoint, protectedResources.graphApiGroups.scopes);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

//Set your default interaction type for MSALGuard here. If you have any
//additional scopes you want the user to consent upon login, add them here as well.
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: loginRequest,
    loginFailedRoute: '/login-failed'
  };
}
@NgModule({
  declarations: [
    AppComponent,
    ContractorComponent,
    AlertComponent,
    HomeComponent,
    ContractorDetailsComponent,
    AddEditContractorComponent,
    CheckboxlistDirective,
    OverageComponent,
    NewNoteComponent,
    InputRefNoDialog,
    GenerateDocumentDialogComponent,
    FormFocusDirective,
    InternalTradesComponent,
    InternalTradeDetailsComponent,
    SowSelectionDialogComponent,
    JobDetailsComponent,
    SelectInternalTradesComponent,
    InvoiceApprovalDialogComponent,
    DisableMultipleClicksDirective,
    InvoiceUploadDialogComponent,
    ApprovedInvoicesComponent,
    InvoicesPaidComponent,
    InvoicesAwaitingApprovalComponent,
    AbnMaskDirective,
    AcnMaskDirective,
    DocumentDetailsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    AppRoutingModule,
    Mk3MaterialModule,
    MatTableModule,
    FormsModule,
    CdkTableModule,
    CommonModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    HttpClientModule,
    DigitOnlyModule,
    NgbModule,
    FlexLayoutModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMomentDateModule,
    MatTableExporterModule,
    PdfViewerModule
  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    GraphService,
    GroupGuardService,
    DecimalPipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
