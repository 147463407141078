<div class="main-container">

  <!-- CSS loading animation container -->
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <h1>NIB/ Mk3 Contractors</h1>
  <div class="contractor-details-container mat-elevation-z8  bg-light" style="vertical-align: top;">
    <mat-tab-group #tabGroup (selectedTabChange)="onTabClick($event)" [selectedIndex]="selectedTabIndex">
      <mat-tab label="Contractor Details" id="0">
        <ng-template mat-tab-label>Contractor Details</ng-template>
        <ng-template matTabContent>
          <h2>Contractor Details</h2>
          <h3>{{contractor.tradingName}}</h3>
          <h3 *ngIf="contractor.statusId != 3 && contractor.statusId != 2 && contractor.statusId != 5 && !isCompliant" class="red-text-highlighted"
            style="background-color: yellow; font-weight: 500;"><a class="router-link-active red-text-highlighted"
              [routerLink]="" (click)="changeTab(1)">Non-Compliant with Mk3/ NIB. Please check the Licence/ Insurance
              Details</a></h3>
              <h3 *ngIf="contractor.statusId == 2 && !isCompliant"
              style="color: yellow; background-color: var(--mk3red); font-weight: 500;">Standby Contractor</h3>
          <h3 *ngIf="contractor.statusId == 3 && !isCompliant"
            style="color: yellow; background-color: var(--mk3red); font-weight: 500;">Blacklisted with Mk3/ NIB</h3>
            <h3 *ngIf="contractor.statusId == 5 && !isCompliant"
            style="color: yellow; background-color: var(--mk3red); font-weight: 500;">Contractor Not Operating</h3>

          <mat-grid-list [cols]="breakpoint">
            <mat-grid-tile>
              <mat-list>
                <mat-list-item class="list-key-column"> Contractor ID </mat-list-item>
                <mat-list-item class="list-key-column"> Trading Name </mat-list-item>
                <mat-list-item class="list-key-column"> Entity Name </mat-list-item>
                <mat-list-item class="list-key-column"> Status </mat-list-item>
                <mat-list-item class="list-key-column"> Geo Region </mat-list-item>
                <mat-list-item class="list-key-column"> Address </mat-list-item>
                <mat-list-item class="list-key-column"> Main Contact </mat-list-item>
                <mat-list-item class="list-key-column"> Main Contact Email </mat-list-item>
                <mat-list-item class="list-key-column"> Account Contact </mat-list-item>
                <mat-list-item class="list-key-column"> Account Contact Email </mat-list-item>
                <mat-list-item class="list-key-column"> Main Trade Category </mat-list-item>
                <mat-list-item class="list-key-column"> Trade Services </mat-list-item>
              </mat-list>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-list>
                <mat-list-item class="list-value-column"> {{contractor.contractorId}} </mat-list-item>
                <mat-list-item class="list-value-column"> {{contractor.tradingName}} </mat-list-item>
                <mat-list-item class="list-value-column"> {{contractor.companyName}} </mat-list-item>
                <mat-list-item class="list-value-column">
                  <ng-container *ngIf="(statuses | async) as statuses; else elseBlank">
                    <div [ngStyle]="{'color': getStateColor(statuses.status), 'font-weight' : '500'}"
                      matTooltip="{{this.restService.getStatusDefinition(statuses.statusId)}}"
                      matTooltipPosition="right">
                      {{statuses.status}}</div>
                  </ng-container>
                  <ng-template #elseBlank></ng-template>
                </mat-list-item>
                <mat-list-item class="list-value-column">
                  <ng-container *ngIf="(geoRegion | async) as geoRegion; else elseBlank">{{geoRegion.geoRegion}}
                  </ng-container>
                  <ng-template #elseBlank> </ng-template>
                </mat-list-item>
                <mat-list-item class="list-value-column"><a *ngIf="contact.address"
                    href="https://www.google.com.au/maps/place/{{contact.address.replace('/', '%2F')}} {{contact.suburb}} {{contact.postcode}}"
                    target="_blank">{{contact.address}} {{contact.suburb}} {{contact.postcode}}
                    <ng-container *ngIf="(state | async) as state; else elseBlank"> {{state.state}}</ng-container>
                    <ng-template #elseBlank> </ng-template>
                  </a></mat-list-item>
                <mat-list-item class="list-value-column">{{contact.mainContactName}} (<a
                    href="tel:{{contact.mainContactPhone}}">{{contact.mainContactPhone}}</a>)</mat-list-item>
                <mat-list-item class="list-value-column"><a
                    href="mailto:{{contact.mainContactEmail}}">{{contact.mainContactEmail}}</a></mat-list-item>
                <mat-list-item class="list-value-column">{{contact.accountContactName}} (<a
                    href="tel:{{contact.accountContactPhone}}">{{contact.accountContactPhone}}</a>)</mat-list-item>
                <mat-list-item class="list-value-column"><a
                    href="mailto:{{contact.accountContactEmail}}">{{contact.accountContactEmail}}</a></mat-list-item>
                <mat-list-item class="list-value-column" style="color:blue; font-weight: 500;">
                  {{contractor.mainTradeCategory }}</mat-list-item>
                <mat-list-item class="list-value-column" style="color:green; font-weight: 500;">
                  {{contractor.tradeServices }}</mat-list-item>
              </mat-list>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-list>
                <mat-list-item class="list-key-column"> T & C Agreement </mat-list-item>
                <mat-list-item class="list-key-column"> Compliant Until </mat-list-item>
                <mat-list-item class="list-key-column"> Last Used Date </mat-list-item>
                <mat-list-item class="list-key-column"> Evaluated Date </mat-list-item>
                <mat-list-item class="list-key-column"> Entity Type </mat-list-item>
                <mat-list-item class="list-key-column" *ngIf="contractor.abn?.length"><a
                    href="https://abr.business.gov.au/ABN/View?id={{contractor.abn.trim()}}" target="_blank"> ABN </a>
                </mat-list-item>
                <mat-list-item class="list-key-column" *ngIf="!contractor.abn?.length"><a
                    href="https://abr.business.gov.au/Search/ResultsActive?SearchText={{contractor.tradingName}}"
                    target="_blank"> ABN </a></mat-list-item>
                <mat-list-item class="list-key-column" *ngIf="contractor.acn?.length"><a
                    href="https://abr.business.gov.au/ABN/View?id={{contractor.acn.trim()}}" target="_blank"> ACN </a>
                </mat-list-item>
                <mat-list-item class="list-key-column" *ngIf="!contractor.acn?.length"><a
                    href="https://abr.business.gov.au/Search/ResultsActive?SearchText={{contractor.tradingName}}"
                    target="_blank"> ACN </a></mat-list-item>
                <mat-list-item class="list-key-column"> Job Time Quality Rating </mat-list-item>
                <mat-list-item class="list-key-column"> Quality of Work Rating </mat-list-item>
                <mat-list-item class="list-key-column"> HSE and Admin Quality Rating </mat-list-item>
                <mat-list-item class="list-key-column"> Total Quality Rating </mat-list-item>
                <mat-list-item class="list-key-column"> Evaluation Notes </mat-list-item>
              </mat-list>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-list>
                <mat-list-item class="list-value-column">
                  <!-- <a [href]="contractor.tncURL" target="_blank">{{contractor.tncURL}}</a> -->
                  <ng-container *ngIf="contractor.tncURL != 'Not Sent' else elseNotSent">
                  <ng-container *ngIf="contractor.tncStatus == 'Sent'; else elseSigned">
                    <a [href]="contractor.tncURL" target="_blank"><a
                      href="{{contractor.tncURL}}" target="_blank">Sent - Not Signed</a></a>
                    </ng-container>
                  </ng-container>
                  <ng-template #elseNotSent>Not Sent</ng-template>
                  <ng-template #elseSigned><a
                    href="{{contractor.tncURL}}" target="_blank">{{contractor.tncStatus}}</a></ng-template>
                </mat-list-item>
                <mat-list-item class="list-value-column">{{contractor.compliantUntil | date: 'dd/MM/yyyy'}}
                </mat-list-item>
                <mat-list-item class="list-value-column"> {{contractor.dateLastUsed | date: 'dd/MM/yyyy'}}
                </mat-list-item>
                <mat-list-item class="list-value-column"> {{contractor.dateEvaluated | date: 'dd/MM/yyyy'}}
                </mat-list-item>
                <mat-list-item class="list-value-column">
                  <ng-container *ngIf="entityType | async; let entityType; else elseBlank">{{entityType.entityType}}
                  </ng-container>
                  <ng-template #elseBlank> </ng-template>
                </mat-list-item>
                <mat-list-item class="list-value-column" *ngIf="contractor.abn?.length"><a
                    href="https://abr.business.gov.au/ABN/View?id={{contractor.abn.trim()}}" target="_blank">
                    {{contractor.abn}} </a>
                </mat-list-item>
                <mat-list-item class="list-value-column" *ngIf="!contractor.abn?.length">
                </mat-list-item>
                <mat-list-item class="list-value-column" *ngIf="contractor.acn?.length"><a
                    href="https://abr.business.gov.au/ABN/View?id={{contractor.acn.trim()}}" target="_blank">
                    {{contractor.acn}} </a>
                </mat-list-item>
                <mat-list-item class="list-value-column" *ngIf="!contractor.acn?.length">
                </mat-list-item>
                <mat-list-item class="list-value-column"><ng-container
                    *ngIf="contractor.avgJTMRating">{{contractor.avgJTMRating | number : '1.1-1'}} (out of
                    5)</ng-container></mat-list-item>
                <mat-list-item class="list-value-column"><ng-container
                    *ngIf="contractor.avgQoWRating">{{contractor.avgQoWRating | number : '1.1-1'}} (out of
                    5)</ng-container></mat-list-item>
                <mat-list-item class="list-value-column"><ng-container
                    *ngIf="contractor.avgHSERating">{{contractor.avgHSERating | number : '1.1-1'}} (out of
                    5)</ng-container></mat-list-item>
                <mat-list-item class="list-value-column" style="font-weight: 500;"><ng-container
                    *ngIf="contractor.avgRating">{{contractor.avgRating | number : '1.1-1'}} (out of
                    5)</ng-container></mat-list-item>
                <mat-list-item class="list-value-column" [innerHTML]="contractor.compliancyNotes"></mat-list-item>
              </mat-list>
            </mat-grid-tile>

          </mat-grid-list>
        </ng-template>
      </mat-tab>
      <mat-tab label="Insurance & Licence Details" id="1">
        <h2>Insurance & Licence Details</h2>
        <h3>{{contractor.tradingName}}</h3>
        <mat-grid-list cols="4">
          <mat-grid-tile>
            <mat-list>
              <h3 style="text-align:left; padding-left: 45px;">Insurance Details</h3>
              <mat-list-item class="list-key-column"> W/C or Sick & Acc Insurer </mat-list-item>
              <mat-list-item class="list-key-column"> W/C or Sick & Acc Policy Number </mat-list-item>
              <mat-list-item class="list-key-column"> W/C or Sick & Acc Policy Expiry Date </mat-list-item>
              <mat-list-item class="list-key-column"> W/C or Sick & Acc Sum Insured </mat-list-item>
              <mat-list-item class="list-key-column"> P/L Insurer </mat-list-item>
              <mat-list-item class="list-key-column"> P/L Policy Number </mat-list-item>
              <mat-list-item class="list-key-column"> P/L Expiry Date </mat-list-item>
              <mat-list-item class="list-key-column"> P/L Sum Insured </mat-list-item>
              <mat-list-item class="list-key-column"></mat-list-item>
            </mat-list>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-list>
              <h3 style="visibility: hidden;">I</h3>
              <mat-list-item class="list-value-column">
                <ng-container *ngIf="wcaInsurer | async; let wcaInsurer; else elseBlank">{{wcaInsurer.insurer}}
                </ng-container>
                <ng-template #elseBlank> </ng-template>
              </mat-list-item>
              <mat-list-item class="list-value-column">{{insurance.policyNumber}}</mat-list-item>
              <mat-list-item class="list-value-column red-text-highlighted"
                *ngIf="functionsService.pastDate(insurance.policyExpiryDate)">
                {{insurance.policyExpiryDate | date: 'dd/MM/yyyy' }}</mat-list-item>
              <mat-list-item class="list-value-column green-text"
                *ngIf="!(functionsService.pastDate(insurance.policyExpiryDate)) && !(functionsService.expireInMonthDate(insurance.policyExpiryDate))">
                {{insurance.policyExpiryDate | date: 'dd/MM/yyyy' }}</mat-list-item>
              <mat-list-item class="list-value-column orange-text"
                *ngIf="!(functionsService.pastDate(insurance.policyExpiryDate)) && (functionsService.expireInMonthDate(insurance.policyExpiryDate))">
                {{insurance.policyExpiryDate | date: 'dd/MM/yyyy' }}</mat-list-item>
              <mat-list-item class="list-value-column">
                <div *ngIf="insurance.policySumInsured != null" class="dollar"></div>{{insurance.policySumInsured}}
              </mat-list-item>
              <mat-list-item class="list-value-column">
                <ng-container *ngIf="plInsurer | async; let plInsurer; else elseBlank">{{plInsurer.insurer}}
                </ng-container>
                <ng-template #elseBlank> </ng-template>
              </mat-list-item>
              <mat-list-item class="list-value-column">{{insurance.plpolicyNumber}}</mat-list-item>
              <mat-list-item class="list-value-column red-text-highlighted"
                *ngIf="functionsService.pastDate(insurance.plexpiryDate)">
                {{insurance.plexpiryDate | date: 'dd/MM/yyyy' }}</mat-list-item>
              <mat-list-item class="list-value-column green-text"
                *ngIf="!(functionsService.pastDate(insurance.plexpiryDate)) && !(functionsService.expireInMonthDate(insurance.plexpiryDate))">
                {{insurance.plexpiryDate | date: 'dd/MM/yyyy' }}</mat-list-item>
              <mat-list-item class="list-value-column orange-text"
                *ngIf="!(functionsService.pastDate(insurance.plexpiryDate)) && (functionsService.expireInMonthDate(insurance.plexpiryDate))">
                {{insurance.plexpiryDate | date: 'dd/MM/yyyy' }}</mat-list-item>
              <mat-list-item class="list-value-column">
                <div *ngIf="insurance.plsumInsured != null" class="dollar"></div>{{insurance.plsumInsured}}
              </mat-list-item>
              <mat-list-item class="list-value-column">
              </mat-list-item>
            </mat-list>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-list>
              <h3 style="text-align:left; padding-left: 45px;">Licence Details</h3>
              <mat-list-item class="list-key-column">Licence Required</mat-list-item>
              <mat-list-item class="list-key-column">Licence Name</mat-list-item>
              <mat-list-item class="list-key-column">Licence Classification</mat-list-item>
              <mat-list-item class="list-key-column">Licence Number</mat-list-item>
              <mat-list-item class="list-key-column">Licence Expiry Date</mat-list-item>
              <mat-list-item class="list-key-column">Construction Induction</mat-list-item>
              <mat-list-item class="list-key-column">SWMS Required</mat-list-item>
              <mat-list-item class="list-key-column">SWMS Documentation Provided Date</mat-list-item>
              <mat-list-item class="list-key-column">Licence Conditions</mat-list-item>
            </mat-list>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-list>
              <h3 style="text-align:left;; padding-left: 15px;">
                <div *ngIf="(state | async)?.state == 'NSW' && contractor.licenceRequired==true"><a
                  href="https://www.onegov.nsw.gov.au/publicregister/#/publicregister/categories" target="_blank">NSW
                  Public Register</a>
              </div>
              <div *ngIf="(state | async)?.state == 'QLD' && contractor.licenceRequired==true"><a
                  href="https://www.onlineservices.qbcc.qld.gov.au/OnlineLicenceSearch/VisualElements/SearchBSALicenseeContent.aspx"
                  target="_blank">QLD Public Register</a>&nbsp;|&nbsp;<a href="https://fswqap.worksafe.qld.gov.au/elis/"
                  target="_blank">QLD Electrical Licence</a>
              </div>
              <div *ngIf="(state | async)?.state == 'VIC' && contractor.licenceRequired==true"><a
                  href="https://consumer.etoolbox.pic.vic.gov.au/_layouts/cc/pic_validatepp.aspx" target="_blank">VIC
                  Public Register</a>
              </div>
              <div *ngIf="(state | async)?.state == 'WA' && contractor.licenceRequired==true"><a
                  href="https://www.commerce.wa.gov.au/consumer-protection/consumer-protection-licence-and-registration-search"
                  target="_blank">WA Public Register</a>
              </div>
              <div *ngIf="(state | async)?.state == 'SA' && contractor.licenceRequired==true"><a
                  href="https://secure.cbs.sa.gov.au/OccLicPubReg/LicenceSearch.php" target="_blank">SA Public
                  Register</a>
              </div>
              <div *ngIf="(state | async)?.state == 'ACT' && contractor.licenceRequired==true"><a
                  href="https://www.accesscanberra.act.gov.au/s/public-registers" target="_blank">ACT Public
                  Register</a>
              </div>
              <div *ngIf="(state | async)?.state == null || contractor.licenceRequired==false">&nbsp;
              </div>
            </h3>              
              <mat-list-item class="list-value-column">
                <td>
                  <ng-container *ngIf="contractor.licenceRequired==true">Yes</ng-container>
                  <ng-container *ngIf="contractor.licenceRequired==false">No</ng-container>
                  <ng-container *ngIf="contractor.licenceRequired==null">
                    <div class="red-text-highlighted">Not Selected</div>
                  </ng-container>
                </td>
              </mat-list-item>
              <mat-list-item class="list-value-column">{{licence.licenceName}}</mat-list-item>
              <mat-list-item class="list-value-column">
                <ng-container *ngIf="licenceClassification | async; let licenceClassification; else elseBlank">
                  {{licenceClassification.classification}}
                </ng-container>
                <ng-template #elseBlank> </ng-template>
              </mat-list-item>
              <mat-list-item class="list-value-column">{{licence.licenceNumber}}</mat-list-item>
              <mat-list-item class="list-value-column red-text-highlighted"
                *ngIf="functionsService.pastDate(licence.licenceExpiryDate) && contractor.licenceRequired==true">
                {{licence.licenceExpiryDate | date: 'dd/MM/yyyy' }}
              </mat-list-item>
              <mat-list-item class="list-value-column green-text"
                *ngIf="!(functionsService.pastDate(licence.licenceExpiryDate)) && !(functionsService.expireInMonthDate(licence.licenceExpiryDate)) && contractor.licenceRequired==true">
                {{licence.licenceExpiryDate | date: 'dd/MM/yyyy' }}
              </mat-list-item>
              <mat-list-item class="list-value-column orange-text"
                *ngIf="!(functionsService.pastDate(licence.licenceExpiryDate)) && (functionsService.expireInMonthDate(licence.licenceExpiryDate)) && contractor.licenceRequired==true">
                {{licence.licenceExpiryDate | date: 'dd/MM/yyyy' }}
              </mat-list-item>
              <mat-list-item class="list-value-column" *ngIf="!contractor.licenceRequired">
              </mat-list-item>
              <mat-list-item class="list-value-column">
                <div [ngStyle]="{'color': 'green', 'font-weight' : '500'}"
                  *ngIf="licence.constructionInduction==0; else elseBlank">
                  Not required</div>
                <div [ngStyle]="{'color': 'green', 'font-weight' : '500'}"
                  *ngIf="licence.constructionInduction==1; else elseBlank">
                  Yes - Provided</div>
                <div [ngStyle]="{'color': 'var(--mk3red)', 'font-weight' : '500'}"
                  *ngIf="licence.constructionInduction==2; else elseBlank">
                  No - Not provided</div>
                <div [ngStyle]="{'color': 'var(--mk3red)', 'font-weight' : '500'}"
                  *ngIf="licence.constructionInduction==null; else elseBlank">
                  Not Selected</div>
              </mat-list-item>
              <mat-list-item class="list-value-column">
                <td>
                  <ng-container *ngIf="contractor.swmsRequired==true">Yes</ng-container>
                  <ng-container *ngIf="contractor.swmsRequired==false">No</ng-container>
                  <ng-container *ngIf="contractor.swmsRequired==null">
                    <div class="red-text-highlighted">Not Selected</div>
                  </ng-container>
                </td>
              </mat-list-item>
              <mat-list-item class="list-value-column">
                {{licence.swmsProvidedDate | date: 'dd/MM/yyyy' }}
              </mat-list-item>
              <mat-list-item class="list-value-column">{{licence.licenceConditions}}</mat-list-item>
            </mat-list>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
      <mat-tab label="Notes" class="notes-tab" id="2">
        <h2>Notes</h2>
        <h3>{{contractor.tradingName}}</h3>
        <div class="notes-table" *ngIf="selectedTabIndex==2">
          <mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSourceNotes" matSort matSortStart="asc">
            <ng-container matColumnDef="noteTypeId">
              <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Note Type</th>
              <td mat-cell *matCellDef="let notes">
                {{getNoteType(notes.noteTypeId)}}
              </td>
            </ng-container>

            <ng-container matColumnDef="refNo">
              <th class="w-2" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Ref No</th>
              <td mat-cell *matCellDef="let notes">{{notes.refNo}}</td>
            </ng-container>

            <ng-container matColumnDef="comments">
              <th style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
              <td mat-cell *matCellDef="let notes">{{notes.comments}}</td>
            </ng-container>

            <ng-container matColumnDef="addedBy">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Added By</th>
              <td mat-cell *matCellDef="let notes">{{notes.addedBy}}</td>
            </ng-container>

            <ng-container matColumnDef="timeStamp">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Date/ Time</th>
              <td mat-cell *matCellDef="let notes">{{notes.timeStamp | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedNotesColumns"></tr>
            <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedNotesColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No notes available for this Contractor
              </td>
            </tr>
          </mat-table>
        </div>
      </mat-tab>
      <mat-tab label="Quote Requests" class="notes-tab" id="3">
        <h2>Quote Requests</h2>
        <h3>{{contractor.tradingName}}</h3>
        <div *ngIf="selectedTabIndex==3">

          <!-- <div class="search-box-container">
            <mat-form-field class="search-box-form-field">
              <mat-label>Search by Keyword</mat-label>
              <input #search matInput type="text" onfocus="this.select()" (keyup)="onSearchKeyUp($event)"
                [value]="searchValue" autoFocus>
              <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                (click)="onClearClicked(search)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div> -->

          <table mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSourceEmailRecords" matSort
            matSortStart="asc">
            <ng-container matColumnDef="id">
              <th class="w-1" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.id}}</td>
            </ng-container>
            <ng-container matColumnDef="refNo">
              <th class="w-1" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Ref No</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.refNo}}</td>
            </ng-container>
            <ng-container matColumnDef="instructionTypeId">
              <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Quote Type</th>
              <td mat-cell *matCellDef="let instructionTypes">{{instructionTypes.instructionType}}
                <!-- <div *ngIf="instructionTypes.instructionTypeId == 1">NIB Instructions</div> -->
                <div *ngIf="instructionTypes.instructionTypeId == 2">NIB Quote Request</div>
                <!-- <div *ngIf="instructionTypes.instructionTypeId == 3">RR Instructions</div> -->
                <div *ngIf="instructionTypes.instructionTypeId == 4">RR Quote Request</div>
                <!-- <div *ngIf="instructionTypes.instructionTypeId == 5">Mk3 Instructions</div> -->
              </td>
            </ng-container>
            <ng-container matColumnDef="attachmentName">
              <th class="w-10" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Attachment
                Name</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.attachmentName}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="startDate">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.startDate | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.endDate | date: 'dd/MM/yyyy'}}</td>
            </ng-container> -->

            <ng-container matColumnDef="sentBy">
              <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Sent By</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.sentBy}}</td>
            </ng-container>

            <ng-container matColumnDef="dateSent">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Sent On</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.dateSent | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="jobCost">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Job Cost</th>
              <td mat-cell style="text-align: right;" *matCellDef="let emailRecords">
                <div *ngIf="emailRecords.jobCost">
                  $
                  {{emailRecords.jobCost | number : '1.2-2'}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="jtmRating">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>JTM</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.jtmRating | number : '1.1-1'}}</td>
            </ng-container>

            <ng-container matColumnDef="qoWRating">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>QoW</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.qoWRating | number : '1.1-1'}}</td>
            </ng-container> -->

            <ng-container matColumnDef="statusId">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Job Status</th>
              <td mat-cell *matCellDef="let emailRecords">{{getJobStatus(emailRecords.statusId)}}</td>
            </ng-container>

            <ng-container matColumnDef="details">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
                <button mat-raised-button class="btn btn-sm btn-success"
                  [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                  (click)="jobDetails(emailRecords.id)">Details</button>&nbsp;&nbsp;
              </td>
            </ng-container>

            <ng-container matColumnDef="viewDocument">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let emailRecords">
                <button style="width: fit-content;" mat-raised-button class="btn btn-sm btn-primary"
                  (click)="openInstructionPDF(emailRecords.id)">PDF</button>
              </td>
            </ng-container>

            <ng-container matColumnDef="retract">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
                <!-- <button mat-raised-button class="btn btn-sm btn-danger"
                  [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                  [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId >= 5))"
                  (click)="retract(emailRecords.id)">
                  <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
                  <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId < 5)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
                  </div>
                  <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId >= 5)" class="btn-disabled">
                    &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
                </button> -->

                <button mat-raised-button class="btn btn-sm btn-danger"
                  [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                  [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10))"
                  (click)="retract(emailRecords.id)">
                  <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
                  <div *ngIf="(!emailRecords.retracted) && !(emailRecords.statusId == 9 || emailRecords.statusId == 10)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
                  </div>
                  <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10)" class="btn-disabled">
                    &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
                </button>

                &nbsp;&nbsp;
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedEmailRecordsColumns"></tr>
            <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedEmailRecordsColumns;"
              [ngClass]="{'highlight-table-row': row.retracted == '1'}"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No quote requests available for this Contractor
              </td>
            </tr>
          </table>
        </div>
      </mat-tab>

      <mat-tab label="Instructions (Open)" class="notes-tab" id="4">
        <h2>Instructions (Open)</h2>
        <h3>{{contractor.tradingName}}</h3>
        <div *ngIf="selectedTabIndex==4">

          <div class="search-box-container">
            <mat-form-field class="search-box-form-field">
              <mat-label>Search by Keyword</mat-label>
              <input #searchOpen matInput type="text" (keyup)="onSearchKeyUp(searchOpen,'open')"
              [value]="searchValueInstructionOpen" autoFocus>
            <button mat-button matSuffix mat-icon-button aria-label="Clear"
              (click)="onClearClicked(searchOpen,'open')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <table mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSourceEmailRecords" matSort
            matSortStart="asc">
            <ng-container matColumnDef="id">
              <th class="w-1" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.id}}</td>
            </ng-container>
            <ng-container matColumnDef="refNo">
              <th class="w-1" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Ref No</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.refNo}}</td>
            </ng-container>
            <ng-container matColumnDef="instructionTypeId">
              <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Instruction Type</th>
              <td mat-cell *matCellDef="let instructionTypes">{{instructionTypes.instructionType}}
                <!-- <div *ngIf="instructionTypes.instructionTypeId == 1">NIB Instructions</div>
                <div *ngIf="instructionTypes.instructionTypeId == 2">NIB Quote Request</div>
                <div *ngIf="instructionTypes.instructionTypeId == 3">RR Instructions</div>
                <div *ngIf="instructionTypes.instructionTypeId == 4">RR Quote Request</div>
                <div *ngIf="instructionTypes.instructionTypeId == 5">Mk3 Instructions</div> -->
              </td>
            </ng-container>
            <ng-container matColumnDef="attachmentName">
              <th class="w-10" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Attachment
                Name</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.attachmentName}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="startDate">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.startDate | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.endDate | date: 'dd/MM/yyyy'}}</td>
            </ng-container> -->

            <ng-container matColumnDef="sentBy">
              <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Sent By</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.sentBy}}</td>
            </ng-container>

            <ng-container matColumnDef="dateSent">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Sent On</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.dateSent | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="jobCost">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Job Cost</th>
              <td mat-cell style="text-align: right;" *matCellDef="let emailRecords">
                <div *ngIf="emailRecords.jobCost">
                  $
                  {{emailRecords.jobCost | number : '1.2-2'}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="jtmRating">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>JTM</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.jtmRating | number : '1.1-1'}}</td>
            </ng-container>

            <ng-container matColumnDef="qoWRating">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>QoW</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.qoWRating | number : '1.1-1'}}</td>
            </ng-container> -->

            <ng-container matColumnDef="statusId">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Job Status</th>
              <td mat-cell *matCellDef="let emailRecords">{{getJobStatus(emailRecords.statusId)}}</td>
            </ng-container>

            <ng-container matColumnDef="details">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
                <button mat-raised-button class="btn btn-sm btn-success"
                  [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                  (click)="jobDetails(emailRecords.id)">Details</button>&nbsp;&nbsp;
              </td>
            </ng-container>

            <ng-container matColumnDef="viewDocument">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let emailRecords">
                <button style="width: fit-content;" mat-raised-button class="btn btn-sm btn-primary"
                  (click)="openInstructionPDF(emailRecords.id)">PDF</button>
              </td>
            </ng-container>

            <ng-container matColumnDef="retract">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
                <!-- <button mat-raised-button class="btn btn-sm btn-danger"
                  [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                  [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId >= 5))"
                  (click)="retract(emailRecords.id)">
                  <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
                  <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId < 5)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
                  </div>
                  <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId >= 5)" class="btn-disabled">
                    &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
                </button> -->
                <button mat-raised-button class="btn btn-sm btn-danger"
                [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10))"
                (click)="retract(emailRecords.id)">
                <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
                <div *ngIf="(!emailRecords.retracted) && !(emailRecords.statusId == 9 || emailRecords.statusId == 10)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
                </div>
                <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10)" class="btn-disabled">
                  &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
              </button>
                &nbsp;&nbsp;
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedEmailRecordsColumns"></tr>
            <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedEmailRecordsColumns;"
              [ngClass]="{'highlight-table-row': row.retracted == '1'}"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No open Instructions available for this Contractor
              </td>
            </tr>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="Instructions (Closed)" class="notes-tab" id="5">
        <h2>Instructions (Closed)</h2>
        <h3>{{contractor.tradingName}}</h3>
        <div *ngIf="selectedTabIndex==5">

          <div class="search-box-container">
            <mat-form-field class="search-box-form-field">
              <mat-label>Search by Keyword</mat-label>
              <input #searchclose matInput type="text"autofocus (keyup)="onSearchKeyUp(searchclose,'close')"
                [value]="searchValueInstructionClose" autoFocus>
              <button mat-button  matSuffix mat-icon-button aria-label="Clear"
                (click)="onClearClicked(searchclose,'close')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <table mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSourceEmailRecords" matSort
            matSortStart="asc">
            <ng-container matColumnDef="id">
              <th class="w-1" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.id}}</td>
            </ng-container>
            <ng-container matColumnDef="refNo">
              <th class="w-1" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Ref No</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.refNo}}</td>
            </ng-container>
            <ng-container matColumnDef="instructionTypeId">
              <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Instruction Type</th>
              <td mat-cell *matCellDef="let instructionTypes">{{instructionTypes.instructionType}}
                <!-- <div *ngIf="instructionTypes.instructionTypeId == 1">NIB Instructions</div>
                <div *ngIf="instructionTypes.instructionTypeId == 2">NIB Quote Request</div>
                <div *ngIf="instructionTypes.instructionTypeId == 3">RR Instructions</div>
                <div *ngIf="instructionTypes.instructionTypeId == 4">RR Quote Request</div>
                <div *ngIf="instructionTypes.instructionTypeId == 5">Mk3 Instructions</div> -->
              </td>
            </ng-container>
            <ng-container matColumnDef="attachmentName">
              <th class="w-10" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Attachment
                Name</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.attachmentName}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="startDate">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.startDate | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.endDate | date: 'dd/MM/yyyy'}}</td>
            </ng-container> -->

            <ng-container matColumnDef="sentBy">
              <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Sent By</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.sentBy}}</td>
            </ng-container>

            <ng-container matColumnDef="dateSent">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Sent On</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.dateSent | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="jobCost">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Job Cost</th>
              <td mat-cell style="text-align: right;" *matCellDef="let emailRecords">
                <div *ngIf="emailRecords.jobCost">
                  $
                  {{emailRecords.jobCost | number : '1.2-2'}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="jtmRating">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>JTM</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.jtmRating | number : '1.1-1'}}</td>
            </ng-container>

            <ng-container matColumnDef="qoWRating">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>QoW</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.qoWRating | number : '1.1-1'}}</td>
            </ng-container> -->

            <ng-container matColumnDef="statusId">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Job Status</th>
              <!-- <td mat-cell *matCellDef="let emailRecords">{{getJobStatus(emailRecords.statusId)}}</td> -->
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.jobStatus}}</td>
            </ng-container>

            <ng-container matColumnDef="details">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
                <button mat-raised-button class="btn btn-sm btn-success"
                  [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                  (click)="jobDetails(emailRecords.id)">Details</button>&nbsp;&nbsp;
              </td>
            </ng-container>

            <ng-container matColumnDef="viewDocument">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let emailRecords">
                <button style="width: fit-content;" mat-raised-button class="btn btn-sm btn-primary"
                  (click)="openInstructionPDF(emailRecords.id)">PDF</button>
              </td>
            </ng-container>

            <ng-container matColumnDef="retract">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
                <!-- <button mat-raised-button class="btn btn-sm btn-danger"
                  [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                  [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId >= 5))"
                  (click)="retract(emailRecords.id)">
                  <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
                  <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId < 5)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
                  </div>
                  <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId >= 5)" class="btn-disabled">
                    &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
                </button> -->
                <button mat-raised-button class="btn btn-sm btn-danger"
                [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10))"
                (click)="retract(emailRecords.id)">
                <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
                <div *ngIf="(!emailRecords.retracted) && !(emailRecords.statusId == 9 || emailRecords.statusId == 10)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
                </div>
                <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10)" class="btn-disabled">
                  &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
              </button>
                &nbsp;&nbsp;
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedEmailRecordsColumns"></tr>
            <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedEmailRecordsColumns;"
              [ngClass]="{'highlight-table-row': row.retracted == '1'}"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No closed instructions available for this Contractor
              </td>
            </tr>
          </table>
        </div>
      </mat-tab>

      <mat-tab label="Instructions (All)" class="notes-tab" id="6">
        <h2>Instructions (All)</h2>
        <h3>{{contractor.tradingName}}</h3>
        <div *ngIf="selectedTabIndex==6">

          <div class="search-box-container">
            <mat-form-field class="search-box-form-field">
              <mat-label>Search by Keyword</mat-label>
              <input #searchAll matInput type="text" (keyup)="onSearchKeyUp(searchAll,'all')"
              [value]="searchValueInstructionAll" autoFocus>
            <button mat-button matSuffix mat-icon-button aria-label="Clear"
              (click)="onClearClicked(searchAll,'All')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <table mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSourceEmailRecords" matSort
            matSortStart="asc">
            <ng-container matColumnDef="id">
              <th class="w-1" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.id}}</td>
            </ng-container>
            <ng-container matColumnDef="refNo">
              <th class="w-1" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Ref No</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.refNo}}</td>
            </ng-container>
            <ng-container matColumnDef="instructionTypeId">
              <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Instruction Type</th>
              <td mat-cell *matCellDef="let instructionTypes">{{instructionTypes.instructionType}}
              </td>
            </ng-container>
            <ng-container matColumnDef="attachmentName">
              <th class="w-10" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Attachment
                Name</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.attachmentName}}</td>
            </ng-container>

            <ng-container matColumnDef="sentBy">
              <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Sent By</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.sentBy}}</td>
            </ng-container>

            <ng-container matColumnDef="dateSent">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Sent On</th>
              <td mat-cell *matCellDef="let emailRecords">{{emailRecords.dateSent | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>

            <ng-container matColumnDef="statusId">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Job Status</th>
              <td mat-cell *matCellDef="let emailRecords">{{getJobStatus(emailRecords.statusId)}}</td>
            </ng-container>

            <ng-container matColumnDef="details">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
                <button mat-raised-button class="btn btn-sm btn-success"
                  [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                  (click)="jobDetails(emailRecords.id)">Details</button>&nbsp;&nbsp;
              </td>
            </ng-container>

            <ng-container matColumnDef="viewDocument">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let emailRecords">
                <button style="width: fit-content;" mat-raised-button class="btn btn-sm btn-primary"
                  (click)="openInstructionPDF(emailRecords.id)">PDF</button>
              </td>
            </ng-container>

            <ng-container matColumnDef="retract">
              <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
                <button mat-raised-button class="btn btn-sm btn-danger"
                [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
                [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10))"
                (click)="retract(emailRecords.id)">
                <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
                <div *ngIf="(!emailRecords.retracted) && !(emailRecords.statusId == 9 || emailRecords.statusId == 10)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
                </div>
                <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10)" class="btn-disabled">
                  &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
              </button>
                &nbsp;&nbsp;
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedEmailRecordsColumns"></tr>
            <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedEmailRecordsColumns;"
            [ngClass]="{'highlight-table-row': row.retracted == '1'}"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No Instructions available for this Contractor
              </td>
            </tr>
          </table>
        </div>
      </mat-tab>

      <mat-tab label="Quality Reviews" class="notes-tab" id="7">
        <h2>Quality Reviews</h2>
        <h3>{{contractor.tradingName}}</h3>
        <div *ngIf="selectedTabIndex==7">
          <table mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSourceQualityReviews" matSort
            matSortStart="asc">

            <ng-container matColumnDef="jobId">Q
              <th class="w-5" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Job ID</th>
              <td mat-cell *matCellDef="let qualityReviews">
                <a href="/job-details/{{qualityReviews.jobId}}"
                      target="_blank">{{qualityReviews.jobId}}</a></td>Q
            </ng-container>

            <ng-container matColumnDef="comments">
              <th style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
              <td mat-cell *matCellDef="let qualityReviews">{{qualityReviews.comments}}</td>
            </ng-container>

            <ng-container matColumnDef="qoWscore">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>QoW Rating</th>
              <td mat-cell *matCellDef="let qualityReviews"><div *ngIf="qualityReviews.qoWscore != 0">{{qualityReviews.qoWscore}}</div></td>
            </ng-container>

            <ng-container matColumnDef="jobTimeScore">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Job Time Rating</th>
              <td mat-cell *matCellDef="let qualityReviews"><div *ngIf="qualityReviews.jobTimeScore != 0">{{qualityReviews.jobTimeScore}}</div></td>
            </ng-container>

            <ng-container matColumnDef="addedBy">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Added By</th>
              <td mat-cell *matCellDef="let qualityReviews">{{qualityReviews.addedBy}}</td>
            </ng-container>

            <ng-container matColumnDef="addedOn">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Added On</th>
              <td mat-cell *matCellDef="let qualityReviews">{{qualityReviews.addedOn | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedQualityReviewsColumns"></tr>
            <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedQualityReviewsColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No quality reviews available for this Contractor
              </td>
            </tr>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="Documents" id="8">
        <h2>Documents</h2>
        <h3>{{contractor.tradingName}}</h3>
        <div *ngIf="selectedTabIndex==8">
          <mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSourceDocumentDetail" matSort matSortStart="desc">
            <ng-container matColumnDef="documentId">
              <th class="w-5" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Document ID</th>
              <td mat-cell *matCellDef="let document">{{document.documentId}}
            </ng-container>
            <ng-container matColumnDef="contractorId">
              <th class="w-5" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Contractor ID</th>
              <td mat-cell *matCellDef="let document">{{document.contractorId}}
            </ng-container>

            <ng-container matColumnDef="documentType">
              <th class="w-8" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Document Type</th>
              <td mat-cell *matCellDef="let document">{{document.documentType}}</td>
            </ng-container>
            <ng-container matColumnDef="expireDate">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Expire Date</th>
              <td mat-cell *matCellDef="let document"><div *ngIf="document.expireDate != 0">{{document.expireDate | date: 'dd/MM/yyyy'}}</div></td>
            </ng-container>

            <ng-container matColumnDef="addedBy">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Added By</th>
              <td mat-cell *matCellDef="let document"><div *ngIf="document.qoWscore != 0">{{document.addedBy}}</div></td>
            </ng-container>

            <ng-container matColumnDef="addedOn">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>AddedOn</th>
              <td mat-cell *matCellDef="let document"><div >{{document.addedOn | date: 'dd/MM/yyyy HH:mm'}}</div></td>
            </ng-container>
            <ng-container matColumnDef="comment">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
              <td mat-cell *matCellDef="let document"><div >{{document.comment}}</div></td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
              <td mat-cell *matCellDef="let document">
                <button mat-raised-button class="btn btn-sm btn-primary mr-1" style="width:72px" *ngIf="document.fileType == pdfType"
            (click)="openDocument(document.documentId)" >PDF</button>
            <button mat-raised-button class="btn btn-sm btn-primary mr-1" style="width:72px" *ngIf="document.fileType != pdfType"
            (click)="openDocument(document.documentId)" >Image</button>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="dataSourceDocumentDetailColumn"></tr>
            <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: dataSourceDocumentDetailColumn;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No document available for this Contractor
              </td>
            </tr>
          </mat-table>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="center-button-container" *ngIf="!isLoading">

      <button mat-raised-button class="btn btn-sm btn-success margin-left-10" (click)=newWorkInstructions()
        [hidden]="contractor.statusId == 3 || contractor.statusId == 2 || contractor.statusId == 5">Work Instructions/ Quote Request</button>
      <button style="width: fit-content;" mat-raised-button class="btn btn-sm btn-primary margin-left-10"
        (click)='openNoteDialog()'>Add
        Note</button>
      <button mat-raised-button class="btn btn-sm btn-danger margin-left-10"  (click)=editContractor()>Edit Contractor</button>
      <button mat-raised-button class="btn btn-sm btn-color-navi margin-left-10" [disabled]="!hasPermissionToUploadDocument" (click)=uploadDocument()>Upload Document</button>
      <button mat-raised-button class="btn btn-sm btn-danger margin-left-10" *ngIf="contractor.tncStatus==='Not Sent' && contractor.mainContactEmail !==null && contractor.mainContactEmail !=='' && (contractor.statusId===1 || contractor.statusId===6)" (click)=sendTnC()>Send T&C</button>
      <a class="btn btn-link" routerLink="/">Back</a>
    </div>
  </div>
</div>