import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { Contractor } from '@app/_models/contractor';
import { EmailRecord } from '@app/_models/email-record';
import { InternalTrade } from '@app/_models/internal-trade';
import { JobFile } from '@app/_models/job-file';
import { JobHistory } from '@app/_models/job-history';
import { JobStatus } from '@app/_models/job-status';
import { AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import * as Pikaday from 'pikaday';
import { first, of } from 'rxjs';
import Swal from 'sweetalert2';
import { InvoiceApprovalDialogComponent } from './invoice-approval-dialog/invoice-approval-dialog.component';
import { InvoiceUploadDialogComponent } from './invoice-upload-dialog/invoice-upload-dialog.component';
import { groups } from '@app/auth-config';
import { Email } from '@app/_models/email';
import { JobHistoryView } from '@app/_models/job-history-view';
import { ADUserManager } from '@app/_models/user-manager-details-ad';
import { AppId, LislEmail, Success } from '@app/_helpers/constants';
import { Case } from '@app/_models/case';
import { DatumNote } from '@app/_models/datumNote';
import { CommentOnly } from '@app/_models/comment-only';
import { GlobalValues } from '@app/_services/global-values';
import { VClaimsAllNew } from '@app/_models/vClaimsAllNew';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'statusId',
    'invoiceNumber',
    'invoiceAmount',
    'comments',
    'date',
    'addedBy',
    'timeStamp',
    'approveInvoice',
    'viewDocument'
  ];

  dataSource!: MatTableDataSource<JobHistory>;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  isLoading = true;
  inputDialogRef: any;
  emailRecord!: EmailRecord;
  id!: number;
  jobStatusesArray: Array<JobStatus> = [];
  contractor!: Contractor;
  internalTrade!: InternalTrade;
  isInternalInstructions: boolean = false;
  assessorEmail: string = '';
  assessorDetails!: ADUserManager;
  userEmail: string = '';
  assessorName: string = '';
  coordinatorName: string = '';
  invoicesApprovedTotal: number = 0;
  groups = groups;
  claimAll!: VClaimsAllNew;
  hasPermissionForReopenInstruction = false;

  constructor(
    public globalValues: GlobalValues,
    public restService: RestService,
    private alertService: AlertService,
    public appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private msalService: MsalService,
    private datePipe: DatePipe,
    public functionsService: FunctionsService,
    public dialog: MatDialog,
    public decimalPipe: DecimalPipe,
    public invoiceApprovalDialogRef: MatDialogRef<InvoiceApprovalDialogComponent>,
    public invoiceUploadDialogRef: MatDialogRef<InvoiceUploadDialogComponent>
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.internalTrade) {
        this.isInternalInstructions = Boolean(params.internalTrade);
        this.route.queryParams.forEach((key) => {
          key.params = null;
        });
      }
      this.id = this.route.snapshot.params['id'];
      this.getRemoteData();
    });

  }

  ngOnInit(): void {
    this.userEmail = this.appComponent.getUserEmail();
    this.restService.getJobStatuses().subscribe((jobStatuses: JobStatus[]) => {
      this.jobStatusesArray = jobStatuses;
    });
    this.hasPermissionForReopenInstruction = this.appComponent.userGroupsArray.includes(groups.groupIT) || (this.globalValues.userEmailId.toLowerCase() == LislEmail);
  }

  getRemoteData() {
    try {
      this.isLoading = true;
      this.restService
        .getEmailRecord(this.id)
        .subscribe((data: EmailRecord) => {
          this.emailRecord = data;

          if (this.emailRecord.contractorId)
            this.isInternalInstructions = false;
          else this.isInternalInstructions = true;

          if (!this.isInternalInstructions) {
            this.restService
              .getContractorDetails(this.emailRecord.contractorId)
              .subscribe((data: Contractor) => {
                this.contractor = { ...data };
              });
          } else {
            this.restService
              .getInternalTrade(this.emailRecord.internalTradeId)
              .subscribe((data) => {
                //if(data.length > 0)
                this.internalTrade = { ...data[0] };
                /*else
                {
                  //this.internalTrade = new InternalTrade();
                  //this.internalTrade.employeeId = this.emailRecord.internalTradeId ? this.emailRecord.internalTradeId: 0;
                  //this.internalTrade.displayName = this.functionsService.getInternalTradeName(this.emailRecord.internalTradeId);
                }*/
              });
          }

          this.getJobHistory();

          this.restService
            .getClaimOwnerDetails(this.emailRecord.refNo)
            .subscribe((result): any => {
              this.restService
                .getInvoicesApprovedTotal(this.emailRecord.refNo)
                .subscribe((result) => {
                  this.invoicesApprovedTotal = result;
                });

              this.assessorEmail = result.consultantEmail
                ? result.consultantEmail
                : '';
              this.assessorName = result.consultant ? result.consultant : '';
              this.coordinatorName = result.coordinator
                ? result.coordinator
                : '';
              if (this.assessorEmail) {
                this.restService.getADUserManagerDetails(result.consultantID).subscribe(data => {
                  this.assessorDetails = data[0];
                })

              }
            });
            this.restService.getClaimAmountDetailsForRefNo(this.emailRecord.refNo).then((data: any) => {
              this.claimAll =  { ...data.records[0] };
            });

          this.isLoading = false;
        });
      return true;
    } catch (error: any) {
      this.alertService.error(error);
      return false;
    }
  }

  getJobHistory() {
    return this.restService
      .getJobHistory(this.route.snapshot.params.id)
      .subscribe(
        (jobHistory: JobHistory[]) => {
          this.dataSource = new MatTableDataSource(jobHistory);
          this.dataSource.sort = this.matSort;
        },
        (error) => {
          this.isLoading = false;
          this.alertService.error(error);
        }
      );
  }

  getJobStatus(statusId: number) {
    return this.jobStatusesArray.find((item) => item.id == statusId)
      ?.description;
  }

  openDateChangePopup(emailRecordId: number, isStart: boolean) {
    let datepicker: Pikaday;
    let defaultDate: string = '';

    if (isStart) {
      defaultDate = this.emailRecord.startDate
        ? moment(this.emailRecord.startDate).format('DD/MM/YYYY')
        : '';
    } else {
      defaultDate = this.emailRecord.endDate
        ? moment(this.emailRecord.endDate).format('DD/MM/YYYY')
        : '';
    }

    Swal.fire({
      title: 'Change start date',
      position: 'center',
      input: 'text',
      inputPlaceholder: 'Date format : dd/MM/yyyy',
      text: 'Please enter start date',
      inputValue: defaultDate,
      inputAttributes: {},
      stopKeydownPropagation: false,
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: '#007bff',
      denyButtonColor: '#dc3545',
      focusDeny: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      inputValidator: (value) => {
        let date = value.toString();
        if (!date) {
          return 'Date cannot be blank!';
        } else if (!this.functionsService.isValidDate(date)) {
          return 'You need to select a valid date!';
        } else {
          return '';
        }
      },
      preConfirm: () => {
        let retVal: string = '';
        retVal = moment(datepicker.getDate()).format('YYYY-MM-DD').toString();

        return retVal;
      },
      didOpen: () => {
        let field = Swal.getInput();
        datepicker = new Pikaday({
          position: 'top left',
          field: field,
          format: 'DD/MM/YYYY',
        });

        (datepicker.el.style.top = '200'),
          setTimeout(() => {
            datepicker.show();
          }, 400); // show calendar after showing animation
      },
    }).then((result) => {
      let date: string | null = result.value?.toString()
        ? result.value?.toString()
        : null;
      if (result.isConfirmed) {
        let emailRecord = new EmailRecord();
        emailRecord.id = emailRecordId;
        let jobHistory = new JobHistory();
        jobHistory.jobId = emailRecordId;

        jobHistory.date = null;//new Date();
        jobHistory.addedBy =
          this.msalService.instance.getActiveAccount()!.name!;
        jobHistory.timeStamp = null;

        if (isStart) {
          jobHistory.statusId = 3;

          if (date) emailRecord.startDate = new Date(date);
          date = this.datePipe.transform(emailRecord.startDate, 'dd/MM/yyyy');
          jobHistory.comments = 'Start Date changed to ' + date;

          this.restService
            .updateEmailRecord(emailRecordId, 3, emailRecord)
            .pipe(first())
            .subscribe({
              next: () => {
                this.restService
                  .addNewJobHistoryRecord(jobHistory)
                  .subscribe((x) => {
                    Swal.fire(
                      'Start Date changed to ' + date,
                      '',
                      'success'
                    ).then(() => {
                      if (emailRecordId) {
                        this.router
                          .navigate(['../job-details/' + emailRecordId])
                          .then(() => {
                            window.location.reload();
                          });
                      }
                    });
                  });
              },
            });
        } else {
          jobHistory.statusId = 4;

          if (date) emailRecord.endDate = new Date(date);
          date = this.datePipe.transform(emailRecord.endDate, 'dd/MM/yyyy');
          jobHistory.comments = 'End Date changed to ' + date;

          this.restService
            .updateEmailRecord(emailRecordId, 4, emailRecord)
            .pipe(first())
            .subscribe({
              next: () => {
                this.restService
                  .addNewJobHistoryRecord(jobHistory)
                  .subscribe((x) => {
                    Swal.fire(
                      'End Date changed to ' + date,
                      '',
                      'success'
                    ).then(() => {
                      if (emailRecordId) {
                        this.router
                          .navigate(['../job-details/' + emailRecordId])
                          .then(() => {
                            window.location.reload();
                          });
                      }
                    });
                  });
              },
            });
        }
      }
    });
  }

  getInstructionsInvoiceReceivedState() {
    let instReceived = false;
    let invReceived = false;

    this.dataSource.data.forEach((element) => {
      if (element.statusId == 5) {
        instReceived = true;
      } else if (element.statusId == 6) {
        invReceived = true;
      }
    });

    if (!instReceived && !invReceived) return 0;
    else if (instReceived && !invReceived) return 1;
    else if (!instReceived && invReceived) return 2;
    else instReceived && invReceived;
    return 3;
  }

  async onUploadInvoice() {
    //console.log(JSON.stringify(this.groups1));
    if (
      //groups.groupManagement.includes(this.appComponent.getUserType()) || //User with Invoice Approval Permissions - groupManagement, groupAssessors, groupTeamLeaders
      this.appComponent.userGroupsArray.includes(groups.groupManagement) ||
      this.appComponent.userGroupsArray.includes(groups.groupAssessors) ||
      this.appComponent.userGroupsArray.includes(groups.groupTeamLeaders) ||
      this.appComponent.userGroupsArray.includes(groups.groupFinance) ||
      this.appComponent.userGroupsArray.includes(groups.groupCoordinators) ||
      this.appComponent.userGroupsArray.includes(groups.groupIT)

      //this.appComponent.getUserType() == 6 || //User with Invoice Approval Permissions - groupManagement, groupAssessors, groupTeamLeaders
      //this.appComponent.getUserType() == 9 || //Finance
      //this.appComponent.getUserType() == 10 || //Claim Coordinators
      //this.appComponent.getUserType() == 11 || //Assessors
      //this.appComponent.getUserType() == 1 //IT
    ) {
      this.invoiceUploadDialogRef = this.dialog.open(
        InvoiceUploadDialogComponent,
        {
          disableClose: true,
          closeOnNavigation: false,
          data: {
            contractor: this.contractor,
            internalTrade: this.internalTrade,
            emailRecord: this.emailRecord,
            userName: this.appComponent.getUserName(),
            userEmail: this.appComponent.getUserEmail()
            //invoicesApprovedTotal: this.invoicesApprovedTotal,
          },
        }
      );
    } else {
      Swal.fire(
        'Sorry, you do not have permissions to perform this action!',
        '',
        'error'
      );
    }
  }

  isAuthorisedToApproveInvoice(): boolean {
    let retVal: boolean = false;
    let isRetract: boolean = false;
    const retract = this.dataSource.data.some(x=>x.statusId===2);
    const reopen = this.dataSource.data.some(y=>y.statusId===11);
    // this.dataSource.data.forEach((element) => {
    //   if (element.statusId == 2) {
    //     isRetract = true;
    //   }
    // });
    if ((!retract || (retract && reopen)) && (
      this.appComponent.userGroupsArray.includes(groups.groupManagement) ||
      this.appComponent.userGroupsArray.includes(groups.groupFinance) ||
      this.appComponent.userGroupsArray.includes(groups.groupIT) ||
      (this.appComponent.userGroupsArray.includes(groups.groupAssessorLevel1) && this.userEmail.toLowerCase() == this.assessorEmail.toLowerCase()) ||
      (this.appComponent.userGroupsArray.includes(groups.groupAssessorLevel2) && this.userEmail.toLowerCase() == this.assessorEmail.toLowerCase()) ||
      this.appComponent.userGroupsArray.includes(groups.groupAssessorLevel3) ||
      this.appComponent.userGroupsArray.includes(groups.groupAssessorLevel4) ||
      ((this.assessorDetails.reviewerEmail != null && this.userEmail.toLowerCase() == this.assessorDetails.reviewerEmail?.toLowerCase()) && !this.appComponent.userGroupsArray.includes(groups.groupAssessorLevel1) && this.assessorDetails.appId == AppId)
    )) {
      retVal = true;
    }
    return retVal;
  }

  onApproveRejectClicked(jobHistoryId: number) {
    this.restService
      .getJobHistoryView(jobHistoryId)
      .subscribe(async (jobHistoryView) => {
        let isAwaitingSeniorApproval: boolean = false;
        this.dataSource.data.forEach((element: JobHistory) => {
          if (
            element.originalHistoryId == jobHistoryId &&
            (element.statusId == 19 || element.statusId == 20)
          ) {
            jobHistoryView.originalHistoryId = element.originalHistoryId;
            jobHistoryView.isFinalInvoice = element.isFinalInvoice;
            jobHistoryView.invoiceAmount = element.invoiceAmount;
            isAwaitingSeniorApproval = true;
          }
        });

        if (isAwaitingSeniorApproval) {
          if (
            this.appComponent.userGroupsArray.includes(groups.groupManagement) ||
            this.appComponent.userGroupsArray.includes(groups.groupFinance) ||
            this.appComponent.userGroupsArray.includes(groups.groupIT) ||
            this.appComponent.userGroupsArray.includes(groups.groupAssessorLevel3) ||
            this.appComponent.userGroupsArray.includes(groups.groupAssessorLevel4) ||
            ((this.assessorDetails.reviewerEmail != null && this.userEmail.toLowerCase() == this.assessorDetails.reviewerEmail?.toLowerCase()) && !this.appComponent.userGroupsArray.includes(groups.groupAssessorLevel1) && this.assessorDetails.appId == AppId)

          ) {
            await Swal.fire({
              title:
                'This invoice was primarily approved by the Assessor and it is awaiting for senior approval.<br/><br/>Do you want to Approve it?',
              //text: 'Please provide a reason',
              html: `<span class="red-text" style="white-space: nowrap; text-align: left;"><ul>
              <li>I have checked invoice details including the company it is invoiced to; <br/>Job address and details and  that the ABN is displayed.</li>                                    
              <li>I have checked that the cost is reasonable and as per the approved cost <br/>established and/or quoted.</li>
              <li>I have double checked that all works listed as complete in the invoice <br/>tallies with the invoice amount and with our costed scope.</li>
              <li>I confirm that the works have been adequately reviewed for quality assurance</li><br/>
              <input type="checkbox" id="checkAgreed" name="checkAgreed" style="transform : scale(2)">
              <label for="checkAgreed">&nbsp;I confirm the above</label><br>
          </ul>
        </span>`,
              input: 'text',
              inputPlaceholder: 'Please provide the reason here',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              icon: 'question',
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: `Approve`,
              denyButtonText: `Reject`,
              returnInputValueOnDeny: true,
              confirmButtonColor: '#157347',
              denyButtonColor: '#BB2D3B',
              focusDeny: true,
              width: 750,
              padding: '1em',
              heightAuto: false,
              inputValidator: (value) => {
                let isAgreed: boolean = (document.getElementById('checkAgreed') as HTMLInputElement).checked;
                if (!isAgreed) {
                  return 'Please confirm that you have checked the above by selecting the check box!';
                }

                if (!value) {
                  return 'You need to write something!';
                } else if (value.length > 250) {
                  return 'Reason too Long!. Should be less than 250 characters';
                } else {
                  return '';
                }
              },
            }).then(async (result) => {
              let approveRejectComment = '';
              let jobStatusId = 0;
              let emailSubject = '';
              let isApproved = false;
              if (result.isConfirmed) {
                isApproved = true;
                //Invoice Approved by Senior
                if (jobHistoryView.isFinalInvoice) {
                  jobStatusId = 18; //Final Invoice Approved
                  emailSubject = 'Final Invoice Approved by Senior';
                }
                else {
                  jobStatusId = 14; //Invoice Approved
                  emailSubject = 'Invoice Approved by Senior';
                }

                approveRejectComment = '<b>Senior Approved ' + (jobHistoryView.isFinalInvoice ? 'Final ' : '') + 'Invoice #: </b>' +
                  jobHistoryView.invoiceNumber +
                  '<br/><b>Amount Approved:</b> $' +
                  jobHistoryView.invoiceAmount +
                  '<br/><b>Approved By:</b> ' +
                  this.msalService.instance.getActiveAccount()!.name +
                  '<br/><b>Comments:</b> ' +
                  result.value;

              } else if (result.isDenied) {
                //Invoice Rejected by a Senior
                jobStatusId = 15; //Invoice Rejected
                emailSubject = 'Invoice Rejected by Senior';

                approveRejectComment = '<b>Senior Rejected Invoice #: </b>' +
                  jobHistoryView.invoiceNumber +
                  '<br/><b>Amount Approved:</b> $' +
                  jobHistoryView.invoiceAmount +
                  '<br/><b>Approved By:</b> ' +
                  this.msalService.instance.getActiveAccount()!.name +
                  '<br/><b>Comments:</b> ' +
                  result.value;
              } else {
                //Cancelled
                return;
              }
              if (result.isConfirmed || result.isDenied) {
                this.restService.getEmailRecordStatusForJobId(this.emailRecord.id).subscribe(x => {
                  let resultMessage = x.message;
                  let currentStatus = x.response;

                  if (resultMessage == Success && this.emailRecord.statusId == currentStatus) {
                    this.isLoading = true;
                    this.managerApproveRejectInvoice(
                      jobHistoryView,
                      isApproved,
                      approveRejectComment,
                      jobStatusId,
                      emailSubject
                    );
                  }
                  else {
                    Swal.fire(
                      'Cannot Approve/Reject now. The job status got changed. Try again after refresh.',
                      '',
                      'warning'
                    ).then(() => {
                      window.location.reload();
                    });
                  }
                });
              }
            });
          } else {
            Swal.fire(
              'Sorry, you do not have permissions to perform this action!',
              '',
              'error'
            );
          }
        } else {
          this.invoiceApprovalDialogRef = this.dialog.open(
            InvoiceApprovalDialogComponent,
            {
              maxWidth: '2000px',
              maxHeight: '680px',
              disableClose: true,
              closeOnNavigation: false,
              data: {
                contractor: this.contractor,
                internalTrade: this.internalTrade,
                emailRecord: this.emailRecord,
                userName: this.appComponent.getUserName(),
                userEmail: this.appComponent.getUserEmail(),
                jobHistoryView: jobHistoryView,
                userGroupsArray: this.appComponent.userGroupsArray,
                invoicesApprovedTotal: this.invoicesApprovedTotal,
              },
            }
          );
        }
      });
  }

  managerApproveRejectInvoice(
    jobHistoryView: JobHistoryView,
    isApproved: boolean,
    approveRejectComment: string,
    jobStatusId: number,
    emailSubject: string,
    //isSeniorApprovalRequired: boolean
  ) {
    let emailRecord = new EmailRecord();
    emailRecord.id = this.emailRecord.id;
    emailRecord.statusId = jobStatusId;

    let jobHistoryNewRecord = new JobHistory();
    jobHistoryNewRecord.jobId = this.emailRecord.id;
    jobHistoryNewRecord.date = null;//new Date();
    jobHistoryNewRecord.addedBy =
      this.msalService.instance.getActiveAccount()!.name!;
    jobHistoryNewRecord.timeStamp = null;
    jobHistoryNewRecord.statusId = jobStatusId; //check
    jobHistoryNewRecord.invoiceNumber = jobHistoryView.invoiceNumber;
    jobHistoryNewRecord.invoiceAmount = jobHistoryView.invoiceAmount;
    jobHistoryNewRecord.originalHistoryId = jobHistoryView.originalHistoryId;
    jobHistoryNewRecord.isFinalInvoice = jobHistoryView.isFinalInvoice;
    jobHistoryNewRecord.comments = approveRejectComment;

    if (jobHistoryView.jobFileId) {
      this.restService.getInvoicePDF(jobHistoryView.jobFileId).subscribe(
        (data: JobFile) => {
          this.restService
            .updateEmailRecord(this.emailRecord.id, jobStatusId, emailRecord)
            .pipe(first())
            .subscribe({
              next: () => {
                this.restService
                  .addNewJobHistoryRecord(jobHistoryNewRecord)
                  .subscribe((x) => {
                    this.restService
                      .getClaimOwnerDetails(this.emailRecord.refNo)
                      .subscribe((result): any => {
                        let claimOwnerEmails =
                          (result.consultantEmail
                            ? result.consultantEmail + ';'
                            : '') +
                          (result.coordinatorEmail && !isApproved
                            ? result.coordinatorEmail
                            : '');

                        var email: Email;
                        email = {
                          from: 'trades@mk3.com.au',
                          to: 'accounts@mk3.com.au',
                          cc: /*this.msalService.instance.getActiveAccount()!.username + ';' + */claimOwnerEmails,
                          bcc: 'test@mk3.com.au',
                          subject: this.emailRecord.refNo + ' - ' + emailSubject + '. Invoice #: ' + jobHistoryView.invoiceNumber + ', Amount: ' + jobHistoryView.invoiceAmount,
                          body:
                            approveRejectComment +
                            '<br/><b>RefNo:</b> <a href=https://portal.mk3apps.com.au/case-details/' +
                            this.emailRecord.refNo +
                            '>' +
                            this.emailRecord.refNo +
                            '</a>' +
                            (this.contractor ? ('<br/><b>Contractor:</b> <a href=https://contractors.mk3apps.com.au/contractor-details/' + this.contractor.contractorId)
                              : ('<br/><b>Internal Trade:</b> <a href=https://contractors.mk3apps.com.au/internal-trade-details/' + this.internalTrade.employeeId)) +
                            '>' +
                            (this.contractor ? this.contractor.tradingName : this.internalTrade.displayName) +
                            '</a>' +
                            '<br/><b>Company:</b> ' +
                            this.getCompany(jobHistoryView.instructionTypeId) +
                            '<br/><br/>Please see the <a href=https://contractors.mk3apps.com.au/job-details/' +
                            this.emailRecord.id +
                            ' target="_blank">details here.</a>',
                          attachmentName: !data.fileName ? null : data.fileName,
                          attachmentContent: !data.fileData
                            ? null
                            : data.fileData
                              ?.toString()
                              .replace('data:', '')
                              .replace(/^.+,/, ''),
                          extraAttachmentName: null,
                          extraAttachmentContent:null,
                        };

                        email.subject = emailSubject;
                        this.restService.sendEmail(email, 'Email Type:Manager Approve or Reject Invoice'+ ', User Name:' + this.msalService.instance.getActiveAccount()!.name! 
                                        + 'Invoice No: ' + jobHistoryView.invoiceNumber+', RefNo:'+this.emailRecord.refNo?.toString()
                                        +', Contractor:'+this.contractor.tradingName+', ContractorId:'+this.contractor.contractorId.toString()
                                        +', OriginalHistoryId:'+jobHistoryView.originalHistoryId?.toString()+', EmailRecordId:'+emailRecord.id.toString()).subscribe({
                          next: () => {
                            if (jobStatusId == 14 || jobStatusId == 18) {
                              Swal.fire('Invoice approved!', '', 'success').then(
                                () => {
                                  if (this.emailRecord.id) {
                                    this.router
                                      .navigate([
                                        '../job-details/' + this.emailRecord.id,
                                      ])
                                      .then(() => {
                                        window.location.reload();
                                        this.isLoading = false;
                                      });
                                  }
                                }
                              );
                            } else if (jobStatusId == 15) {
                              //Invoice Rejected
                              Swal.fire('Invoice Rejected!', '', 'success').then(
                                () => {
                                  if (this.emailRecord.id) {
                                    this.router
                                      .navigate([
                                        '../job-details/' + this.emailRecord.id,
                                      ])
                                      .then(() => {
                                        window.location.reload();
                                        this.isLoading = false;
                                      });
                                  }
                                }
                              );
                            }
                          },
                          error: (err: any) => {
                            Swal.fire(
                              'Error (Manager Approve/ Reject): restService.sendEmail: ' + err,
                              '',
                              'error'
                            );
                            console.log(
                              'Error (Manager Approve/ Reject): restService.sendEmail: ' + err
                            );
                            this.isLoading = false;
                          },
                        });
                      });
                  });
              },
              error: (err: Error) => {
                Swal.fire(
                  'Error: restService.getEmployeeDetailsByEmail<br/>' +
                  err,
                  '',
                  'error'
                );
                this.isLoading = false;
              },
            });
        },
        (error) => {
          Swal.fire(
            'Error: restService.getInvoicePDF<br/>' +
            error,
            '',
            'error'
          );
          this.isLoading = false;
        }
      );
    } else {
      Swal.fire(
        'Invoice Not Found!',
        'Please contact the Mk3 Accounts Department or the IT Helpdesk',
        'error'
      ).then(() => {
        if (this.emailRecord.id) {
          this.router
            .navigate(['../job-details/' + this.emailRecord.id])
            .then(() => {
              window.location.reload();
            });
        }
      });
    }
  }

  getCompany(instructionTypeId: number | null): string {
    switch (instructionTypeId) {
      case 1:
        return 'NIB';
      case 3:
        return 'RR';
      case 5:
        return 'Mk3';
    }
    return '';
  }

  isInvoiceApproved(jobHistoryId: number) {
    let retVal: boolean = false;
    this.dataSource.data.forEach((element) => {
      if (
        element.originalHistoryId == jobHistoryId &&
        (element.statusId == 8 ||
          element.statusId == 14 ||
          element.statusId == 18)
      ) {
        retVal = true;
      }
    });
    return retVal;
  }

  isFinalInvoiceApproved() {
    //let retVal: boolean = false;
    //let isFinalInvoiceApproved: boolean = false;//Approved Final Invoice and pending payment by Finance
    //let isFinalInvoiceRejected: boolean = false;//Rejected by Finance (StatusID = 17 and IsFinalInvoice = true)

    if (this.dataSource && this.dataSource.data) {
      for (const element of this.dataSource.data) {
        if (element.isFinalInvoice && element.statusId == 17) {
          //retVal = false;
          return false;
        }
        if (element.isFinalInvoice && element.statusId == 18) {
          //retVal = true;
          return true;
        }
      }
    }

    return false;

    /*   this.dataSource.data.forEach((element) => {
      if (element.isFinalInvoice && element.statusId == 17) {
        retVal = false;
        return;
      }
      if (element.isFinalInvoice && element.statusId == 18) {
        retVal = true;
        return;
      }
    });
    return retVal;*/
  }

  isInvoiceRejected(jobHistoryId: number) {
    let retVal: boolean = false;
    this.dataSource.data.forEach((element) => {
      if (
        element.originalHistoryId == jobHistoryId &&
        (element.statusId == 15 || element.statusId == 17)
      ) {
        retVal = true;
      }
    });
    return retVal;
  }

  isFinalInvoiceRejected() {
    let retVal: boolean = false;
    this.dataSource.data.forEach((element) => {
      if (element.isFinalInvoice && element.statusId == 17) {
        retVal = true;
      }
    });
    return retVal;
  }

  openInvoicePDF(jobFileId: number) {
    this.restService.getInvoicePDF(jobFileId).subscribe(
      (data: JobFile) => {
        this.functionsService.openPdf(data.fileData);
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  openInstructionPDF(emailRecordId: number) {
    this.restService.getEmailRecord(emailRecordId).subscribe(
      (data: EmailRecord) => {
        this.functionsService.openPdf(data.pdfData);
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  downloadPdf(base64String: any, fileName: string) {
    const source = base64String;
    const link = document.createElement('a');
    link.href = source;
    link.setAttribute('target', '_blank');
    link.download = fileName;
    link.click();
  }

  getInvoicesApprovedTotal(refNo: number) {
    this.restService.getInvoicesApprovedTotal(refNo).subscribe((result) => {
      this.invoicesApprovedTotal = result;
      return this.invoicesApprovedTotal;
    });
  }
  addComment() {
    let commentOnly = new CommentOnly();
    let loginUserEmail = this.globalValues.userEmailId;
    this.restService
      .getClaimOwnerDetails(this.emailRecord.refNo).subscribe((result): any => {
        commentOnly.insured = result.lastName;
        commentOnly.claimOwnerEmails =
          ((result.consultantEmail?.toLowerCase() != loginUserEmail.toLowerCase()) ? result.consultantEmail : '') + (((result.consultantEmail?.toLowerCase() != loginUserEmail.toLowerCase()) && (result.coordinatorEmail?.toLowerCase() != loginUserEmail.toLowerCase())) ? ',' : '') +
          ((result.coordinatorEmail?.toLowerCase() != loginUserEmail.toLowerCase()) ? result.coordinatorEmail : '');
        Swal.fire({
          title: "Add comment",
          text: 'Please provide the comment',
          html: ` <textarea type="text" class="form-control" id="comment" style="display: flex;" [value]="" placeholder="Please provide the comment"></textarea>
                        <div style="float:left;font-weight: bold;margin-left:5px;margin-top:5px">
                          <input type="checkbox" id="financeCheckbox">
                            Notify Finance department
                        </div>
                        <div style="float:left;font-weight: bold;margin-left:5px;margin-top:5px">
                          <input type="checkbox"  id="ownerCheckbox">
                            Notify Assessor/Coordinator
                        </div>`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          icon: 'info',
          showDenyButton: true,
          confirmButtonText: `Yes`,
          denyButtonText: `No`,
          confirmButtonColor: '#007bff',
          denyButtonColor: '#dc3545',
          focusDeny: true,
          width: 450,
          padding: '1em',
          heightAuto: false,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!';
            } else if (value.length > 250) {
              return 'comment too Long!. Should be less than 250 characters';
            } else {
              return '';
            }
          },
          preConfirm: () => {
            const comment = document.getElementById('comment') as HTMLInputElement;
            const financeCheckbox = document.getElementById('financeCheckbox') as HTMLInputElement;
            const ownerCheckbox = document.getElementById('ownerCheckbox') as HTMLInputElement;
            return [
              comment.value,
              financeCheckbox.checked,
              ownerCheckbox.checked
            ];
          }
        }).then(async (result) => {
          if (result.value) {
            this.isLoading = true;
            commentOnly.addedBy = this.globalValues.userName;
            commentOnly.jobId = this.emailRecord.id;
            commentOnly.refNo = this.emailRecord.refNo;
            commentOnly.tradeName = this.contractor ? this.contractor.tradingName : this.internalTrade.displayName;
            commentOnly.comment = result.value[0];
            commentOnly.notifyFinance = result.value[1];
            commentOnly.NotifyOwner = result.value[2];
            this.restService.addComment(commentOnly).subscribe(x => {
              Swal.fire("Comment added successfully!", "success").then(x => {
                this.isLoading = false;
                window.location.reload();
              });
            });
          }
        });
      })
  }
  async retract(emailRecordId: number) {
    this.alertService.clear();
    let tradeName = (this.contractor && this.contractor.tradingName) ? this.contractor.tradingName : this.internalTrade.displayName;
    let tradeId = (this.contractor && this.contractor.contractorId) ? this.contractor.contractorId : this.internalTrade.employeeId;
    let contractorOrInternalTrade = (this.contractor && this.contractor.contractorId) ? 'Contractor' : 'Internal Trade';
    let title =
      'An email will be sent to <b><u>' +
      tradeName +
      '</b></u> to disregard this instruction.\r\n<b><u><p style="color:red">This cannot be reversed if you proceed!!!</p></b></u>Are you sure you want to Retract this?';
    await Swal.fire({
      title: title,
      text: 'Please provide the reason for cancellation',
      input: 'text',
      inputPlaceholder: 'Please enter the reason here',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: '#007bff',
      denyButtonColor: '#dc3545',
      focusDeny: true,
      width: 450,
      padding: '1em',
      heightAuto: false,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        } else if (value.length > 250) {
          return 'Reason too Long!. Should be less than 250 characters';
        } else {
          return '';
        }
      },
    }).then(async (result) => {
      let retractReason = result.value;
      if (result.isConfirmed) {
        let emailRecord = new EmailRecord();
        emailRecord.id = emailRecordId;
        emailRecord.retracted = true;
        emailRecord.retractedBy =
          this.msalService.instance.getActiveAccount()!.name!;
        emailRecord.retractedOn = new Date();
        emailRecord.statusId = 2;

        this.restService
          .updateEmailRecord(emailRecordId, 2, emailRecord)
          .pipe(first())
          .subscribe({
            next: () => {
              try {
                this.restService
                  .getEmailRecord(emailRecordId)
                  .subscribe((emailRecord: EmailRecord) => {
                    var email: Email;
                    let riskAddress = '';
                    this.restService
                      .getCase(emailRecord.refNo)
                      .subscribe((caseDetails: Case) => {
                        riskAddress = (
                          this.functionsService.blankIfNull(
                            caseDetails.situationStreet
                          ) +
                          ' ' +
                          this.functionsService.blankIfNull(
                            caseDetails.situationSuburb
                          ) +
                          ' ' +
                          this.functionsService.blankIfNull(caseDetails.state) +
                          ' ' +
                          this.functionsService.blankIfNull(
                            caseDetails.postCode
                          )
                        )?.trim();
                        let tradeNamesGreeting = '';

                        if (this.internalTrade && this.internalTrade.employeeId) {
                          let additionalInternalTradeIDs: number[];
                          if (emailRecord.additionalInternalTrades != null) {
                            additionalInternalTradeIDs =
                              emailRecord.additionalInternalTrades
                                .split(',')
                                .map(Number);

                            this.restService
                              .getInternalTrades()
                              .subscribe(
                                (internalTradesArray: Array<InternalTrade>) => {
                                  if (internalTradesArray.length != 0) {
                                    additionalInternalTradeIDs.forEach(
                                      (item: number) => {
                                        tradeNamesGreeting =
                                          tradeNamesGreeting +
                                          ', ' +
                                          internalTradesArray.find(
                                            (trade) => item == trade.employeeId
                                          )?.displayName;
                                      }
                                    );
                                  }
                                }
                              );
                          }
                          tradeNamesGreeting =
                            'Hello ' +
                            tradeName +
                            tradeNamesGreeting;
                        }
                        let body =
                          tradeNamesGreeting +
                          ',<br/>Please cancel the previous email instructions sent on ' +
                          this.datePipe.transform(
                            emailRecord.dateSent,
                            'dd/MM/yyyy'
                          ) +
                          ' with the subject <u>' +
                          emailRecord.subject +
                          '</u>, for the address ' +
                          riskAddress;
                        email = {
                          from: emailRecord.fromEmail,
                          to: emailRecord.toEmail,
                          cc: emailRecord.ccEmails,
                          bcc: null,
                          subject: 'Please Cancel - ' + emailRecord.subject,
                          body: body,
                          attachmentName: null,
                          attachmentContent: null,
                          extraAttachmentName: null,
                          extraAttachmentContent:null,
                        };
                        this.restService.sendEmail(email, 'Email Type:Retract Instruction'+ ', User Name:' + this.msalService.instance.getActiveAccount()!.name! 
                                          +', RefNo:'+ emailRecord.refNo.toString() +', Contractor:'+tradeName+', ContractorId:'+tradeId.toString()
                                          +', EmailRecordId:'+ emailRecordId.toString()).subscribe(
                          (response: any) => {
                            if (response.status == 200) {
                              let newNote: DatumNote = {
                                refNo: emailRecord.refNo,
                                date: this.datePipe
                                  .transform(new Date(), 'yyyy-MM-dd')
                                  ?.toString()!,
                                addedBy:
                                  this.msalService.instance.getActiveAccount()!
                                    .name!,
                                initiatedBy: 'Mk3 Staff',
                                noteTypeId: 4,
                                subTypeId: 2,
                                reasonId: 14,
                                notes:
                                  'Retracted Instructions to ' + contractorOrInternalTrade + ' ' +
                                  tradeName +
                                  ' (ID ' +
                                  tradeId +
                                  '). Reason: ' +
                                  retractReason,
                                private: -1,
                                timeStamp: null,
                                followUpDate: null,
                                followUpUser: null,
                                followUpComplete: null,
                                clientId: 1,
                              };
                              this.restService
                                .addNewNoteToDatum(newNote)
                                .pipe(first())
                                .subscribe({
                                  next: () => {
                                    let jobHistory = new JobHistory();
                                    jobHistory.jobId = emailRecordId;
                                    jobHistory.statusId = 2;
                                    jobHistory.comments = retractReason;
                                    jobHistory.date = null;//new Date();
                                    jobHistory.addedBy =
                                      this.msalService.instance.getActiveAccount()!.name!;
                                    jobHistory.timeStamp = null;
                                    this.restService
                                      .addNewJobHistoryRecord(jobHistory)
                                      .subscribe((x) => {
                                        console.log(x);
                                        Swal.fire(
                                          'Instructions Retracted successfully!\r\nEmail has been sent to the ' + contractorOrInternalTrade,
                                          '',
                                          'success'
                                        ).then(() => {
                                          if (emailRecordId) {
                                            this.router
                                              .navigate([
                                                '../job-details/' +
                                                emailRecordId,
                                              ])
                                              .then(() => {
                                                window.location.reload();
                                              });
                                          }
                                        });
                                      });
                                  },
                                });
                            } else {
                              Swal.fire(
                                'Error (' + contractorOrInternalTrade + '  Retract): restService.sendEmail: Status code = ' + response.status,
                                '',
                                'error'
                              );
                              this.isLoading = false;
                            }
                          },
                          (err: any) => {
                            Swal.fire(
                              'Error (' + contractorOrInternalTrade + '  Retract): restService.sendEmail: ' + err,
                              '',
                              'error'
                            );
                            console.log(
                              'Error (' + contractorOrInternalTrade + '  Retract): restService.sendEmail: ' + err
                            );
                          },
                        );
                      });
                  });
              } catch {
                Swal.fire(
                  'Error (' + contractorOrInternalTrade + '  Retract): restService.sendEmail. Please contact the IT Department with details',
                  '',
                  'error'
                );
                this.isLoading = false;
              }
            },
          });
      }
    });
  }
  reopenInstruction(jobId: number) {
    Swal.fire({
      title: "Are you sure, you want to re-open this closed or retracted Instruction?",
      text: 'Please provide the reason for reopen',
      input: 'text',
      inputPlaceholder: 'Please enter the reason here',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: '#007bff',
      denyButtonColor: '#dc3545',
      focusDeny: true,
      width: 450,
      padding: '1em',
      heightAuto: false,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        } else if (value.length > 250) {
          return 'Reason too Long!. Should be less than 250 characters';
        } else {
          return '';
        }
      },
    }).then(async (result) => {
      this.isLoading = true;
      let reopenInstruction = new CommentOnly();
      if (result.isConfirmed) {
        reopenInstruction.jobId = jobId;
        reopenInstruction.refNo = this.emailRecord.refNo;
        reopenInstruction.addedBy = this.globalValues.userName;
        reopenInstruction.comment = result.value;
        this.restService.reopenInstruction(reopenInstruction).subscribe((response) => {
          let result = response.message;
          if (result == Success) {
            Swal.fire("Reopened successfully!", "success").then(x => {
              this.isLoading = false;
              window.location.reload();
            });
          }
          else {
            Swal.fire("Something went wrong!", "error").then(x => {
              this.isLoading = false;
            });
          }
        });
      }
      else {
        this.isLoading = false;
      }
    });
  }
}
