import {
    Directive,
    Input,
    ElementRef,
    OnInit,
    OnChanges,
    HostListener,
    Output,
    EventEmitter,
    SimpleChanges,
  } from '@angular/core';
  
  @Directive({
    selector: '[appCheckboxlist]',
  })
  export class CheckboxlistDirective implements OnInit, OnChanges {
    @Input()
      appCheckboxlist!: number[];//attribute command that can receive an integer array via @Input (), which is incorporated by the parent component
      //Angular will pass the Checkbox in the instruction to the constructor in the form of the inject, and assign the value to oCheckbox
    @Output() checkboxchange = new EventEmitter();
    oCheckbox: HTMLInputElement;//Because we want to use Checkbox's Checked and Value properties, its type is set to HTMLInputElement, not HTMLElement
    constructor(public el: ElementRef) {
      this.oCheckbox = el.nativeElement;
    }
    currentValue!: number;
    ngOnInit() {
      this.currentValue = Number(this.oCheckbox.value);
   //In the initial state ngOnInit hook function, the 'toggle' function sets the status of the Checkbox according to the user's incoming array and the current Checkbox Value
      this.toggle(this.currentValue);
    }
    private toggle = (currentValue: number ) => {
      let checked = this.appCheckboxlist.includes(currentValue);
      this.oCheckbox.checked = checked;
    };
  
    //The binding from the UI to the data is implemented with Checkbox's Click event
    //1. When the Click event occurs, if it is selected, you will receive the current Checkbox's value to add the current Checkbox VALUE and remove the duplicate item.
    //2. When the Click event occurs, if the status is not selected, the VALUE removed in the AppCheckboxList array will receive the current Checkbox.
    //3. Notify the parent component to modify the incoming array appCheckBoxList
    @HostListener('click') onClick() {
      if (this.oCheckbox.checked) {
        this.appCheckboxlist.push(this.currentValue);
        this.appCheckboxlist = Array.from(new Set(this.appCheckboxlist));
      } else {
        this.appCheckboxlist = this.appCheckboxlist.filter(
          (i) => i != this.currentValue
        );
      }
      this.checkboxchange.emit(this.appCheckboxlist);
    }
  
    //The binding from data to the UI is mainly implemented by the ngOnChanges hook function. 
    //If the parent component is incorporated by the AppCheckboxList array changes, call the Toggle function to reset the current Checkbox status
    ngOnChanges(changes: SimpleChanges) {
      for (let propName in changes) {
        if (propName === 'appCheckboxlist') {
          this.toggle(this.currentValue);
        }
      }
    }
  }