import { DocumentDetail } from "./document-detail";

export class DocumentTypeDetails {
documentDetail : DocumentDetail| null = null;
wcsaInsurerId : number|null= null;
policyNumber : string| null= null;
policyExpiryDate : string| null= null;
policySumInsured : number|null= null;
plInsurerId : number|null= null;
plPolicyNumber: string| null= null;
plExpiryDate  : string| null= null;
plSumInsured : number|null= null;
licenceName: string| null= null;
licenceClassificationId : number| null= null;
licenceNumber: string| null= null;
licenceExpiryDate : string| null= null;
licenceConditions: string| null= null;
constructionInduction : number|null= null;
swmsProvidedDate : string| null= null;
licenceRequired : boolean|null = null;
swmsRequired : boolean|null= null;
address: string | null = null;
suburb : string | null = null;
stateId: number | null = null;
postcode: string | null = null;
accountContactName: string | null = null;
accountContactPhone: string | null = null;
accountContactEmail: string | null = null;
mainContactName: string | null = null;
mainContactPhone: string | null = null;
mainContactEmail: string | null = null;
tradeName: string='';
entryName:string | null = null;
geoRegionId: number | null = null;
mainTradeCategoryId: number | null = null;
entityTypeId : number | null = null;
abn:string | null = null;
acn:string | null = null;
}