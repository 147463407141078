/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
  // MSAL Configuration
  auth: {
    clientId: '3fff24ee-ce49-4a8c-875a-703499a13add', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/f90bd66c-40de-4bf9-9e0a-56de7ca5211e', // Tenant
    redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "SessionStorage" is more secure, but "LocalStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },

  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      //logLevel: LogLevel.Verbose,
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    },
  }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  graphApiUser: {
    endpoint: "https://graph.microsoft-ppe.com/v1.0/me",// PPE testing environment
    scopes: ["User.Read.All"]
  },
  // graphApiGroups: {
  //   endpoint: "https://graph.microsoft.com/v1.0/me/checkMemberGroups",
  //   scopes: ["Group.Read.All", "GroupMember.Read.All"]
  // }
  graphApiGroups: {
    //endpoint: "https://graph.microsoft.com/v1.0/me/checkMemberGroups",
    //endpoint: "https://graph.microsoft.com/v1.0/groups/me",// Prod environment. Uncomment to use.
    //endpoint: "https://graph.microsoft-ppe.com/v1.0/me",// PPE testing environment
    endpoint: "https://graph.microsoft.com/v1.0/me/memberOf",
    //endpoint: "https://graph.microsoft.com/v1.0/groups",
    //scopes: ["User.Read", 'directory.read.all', "Group.Read.All", "GroupMember.Read.All"]
    //scopes: ["Group.Read.All", "GroupMember.Read.All"]
    //scopes: ["Directory.Read.All", "GroupMember.Read.All", "Group.Read.All"]
    scopes: ["Directory.Read.All","Group.Read.All","GroupMember.Read.All"]
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['user.read', 'directory.read.all', 'Group.Read.All']
  //scopes: ["Directory.Read.All", "GroupMember.Read.All", "Group.Read.All"]
};

export const groups = {
  //Common Groups Across all Apps
  groupIT: "e6729c8a-65e3-4a5c-a86b-9f61b9faf7c0",
  groupAssessors: "f9c0bf88-ab91-472f-9deb-01aa232d7b16",//All Assessors
  groupCoordinators: "7e7559b0-27b9-4d1c-9779-4a4db40091f7",//Claims - All Coordinators (Not only NSW)
  groupManagement: "7f3a98d6-2313-4687-ab42-59546c24342a",
  groupStateAdminManagers:"5d4bb495-a5b5-4f20-92af-d2adaee694e4",//Kelly, Kim (Not State Managers)
  groupTeamLeaders: "61e9c148-7492-41f4-9fe1-04685d1b4f30",
  groupFinance: "98a5a1fc-be63-46a6-bd50-befaf2ce3d2b",//Windows Server AD //"bf560263-edea-462a-9a6f-a985d49286da",//Cloud
  groupQA:"503a71fb-dd63-4641-ac18-5bf58afe9b41",
  groupMk3Staff: "6f104fe7-7f41-4b9f-b689-c3057c8ec1ab",//Everyone in the Mk3 Group Staff
  groupHMA: "8a027ff8-7d2c-4a79-a40d-b297d00a2fe8",//HMA Everyone (Not only the HMA Assessors)
  groupSA: "e7772892-6581-454f-8e92-3e86dc085ac2",//SA Team, not HMA and not AssessorsHMA

  //Only for Contractors App
  //groupAssessorsExternal: "58519928-07e8-4dd5-b94a-99a3a5d5fa78",//Andres, Daniel, DominicT, Isaac, MichaelN
  
  groupContractorRWFull: "db7f5c43-c17a-4000-bddf-c83a4e37bb61",//Contractor_RW-Full group (Only for the Contractors App - Full Access)
  groupContractorRWPartial: "eba9f663-db9f-4416-a841-b3b4b333c8ed",//Contractor_RW-Partial group (Only for the Contractors App - Add Only no Edits)
  groupContractorRO: "b83e32c1-226f-49c2-9959-ed7135893e5b",//Contractor_RO group (Only for the Contractors App - Read Only)

  groupAssessorLevel0: "38a5d199-ed68-44cc-b2e9-b9df8af89549",//Assessor Level 0 // Can't Approve any (6 Members) // AZ,CH,DM,IH,NB,TC
  groupAssessorLevel1: "b11bd165-3ddf-4128-84ad-2c6f267c820f",//Assessor Level 1 // Secondary Approval required (14 Members)
  groupAssessorLevel2: "52a789a7-4e8c-48ce-92ae-c10dc8294c6a",//Assessor Level 2 // Can Approve Invoices for their claims (16 Members)
  groupAssessorLevel3: "34090cc0-737b-4d68-a2d6-c99e2e28dd2a",//Assessor Level 3 // State Manager or Higher (10)//AW,DW,GS,JK,JS,MG,MS,PV,SP,YB
  groupAssessorLevel4: "77f3f4e9-1ec5-4ff1-8b6c-a61c5f47fa55",//Assessor Level 4 // Jonathan (1 Member)


  //Only for Scope of Works App
  groupMk3PrivilegedContractors: "ef4bdd8f-063b-4953-971e-844380d554b0",//NOT the Contractor_RW-Partial (Members: Dan Marino, Dom Towner, Michael Nethery)
  groupMk3Contractors: "9c9d08d1-00f9-4ed3-8cd8-3bf268bbba0f",//Andres, Isaac, Test
  groupEstimators:"c0cd7d4a-cdb3-409a-89f5-c01074cae877",//For Scope of Works ENData API
  groupUAT: "89772dee-ebd0-4b4d-ad7a-a60b9f517384"//For Scope of Works ENData API (Use)
}