import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Email } from '@app/_models/email';
import { EmailRecord } from '@app/_models/email-record';
import { JobFile } from '@app/_models/job-file';
import { JobHistory } from '@app/_models/job-history';
import { JobHistoryView } from '@app/_models/job-history-view';
import { JobStatus } from '@app/_models/job-status';
import { AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import { AppComponent } from '@app/app.component';
import { MsalService } from '@azure/msal-angular';
import { Observable, concatMap, first, interval, take } from 'rxjs';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { groups } from '@app/auth-config';
import { HttpResponse } from '@angular/common/http';

type AoA = any[][];

@Component({
  selector: 'app-approved-invoives',
  templateUrl: './approved-invoices.component.html',
  styleUrls: ['./approved-invoices.component.scss'],
})
export class ApprovedInvoicesComponent implements OnInit {
  invoicesArray: AoA = [[], []];
  displayedColumns: string[] = [
    'id',
    'refNo',
    'invoiceNumber',
    'invoiceAmount',
    'contractorId',
    'tradingName',
    'instructionTypeId',
    'statusId',
    'date',
    'addedBy',
    'timeStamp',
    'details',
    'viewDocument',
    'reject',
  ];

  dataSource!: MatTableDataSource<JobHistoryView>;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  isLoading = true;
  jobStatusesArray: Array<JobStatus> = [];
  groups = groups;

  constructor(
    private router: Router,
    public restService: RestService,
    private alertService: AlertService,
    public functionsService: FunctionsService,
    private msalService: MsalService,
    public appComponent: AppComponent
  ) {
    this.getRemoteData();
  }

  ngOnInit(): void {
    this.restService.getJobStatuses().subscribe((jobStatuses: JobStatus[]) => {
      this.jobStatusesArray = jobStatuses;
    });
  }

  isInvoiceApproved(jobHistoryId: number) {
    let retVal: boolean = false;
    this.dataSource.data.forEach((element) => {
      if (
        element.originalHistoryId == jobHistoryId &&
        (element.statusId == 8 ||
          element.statusId == 14 ||
          element.statusId == 18)
      ) {
        retVal = true;
      }
    });
    return retVal;
  }

  isInvoiceRejected(jobHistoryId: number) {
    let retVal: boolean = false;
    this.dataSource.data.forEach((element) => {
      if (
        element.originalHistoryId == jobHistoryId &&
        (element.statusId == 15 || element.statusId == 17)
      ) {
        retVal = true;
      }
    });
    return retVal;
  }

  // isFinalInvoiceApproved() {
  //   let retVal: boolean = true;
  //   this.dataSource.data.forEach((element) => {
  //     if (element.isFinalInvoice && (element.statusId == 15 || element.statusId == 17)) {
  //       retVal = false;
  //     }
  //   });
  //   return retVal;
  // }

  getJobStatus(statusId: number) {
    return this.jobStatusesArray.find((item) => item.id == statusId)
      ?.description;
  }

  openInvoicePDF(jobHistoryId: number) {
    this.restService.getJobHistoryView(jobHistoryId).subscribe((data) => {
      if (data.jobFileId) {
        this.restService.getInvoicePDF(data.jobFileId).subscribe(
          (data: JobFile) => {
            this.functionsService.openPdf(data.fileData);
          },
          (error) => {
            this.alertService.error(error);
          }
        );
      }
    });
  }

  getRemoteData() {
    try {
      this.isLoading = true;
      this.getApprovedInvoices();
      return true;
    } catch (error: any) {
      this.isLoading = false;
      this.alertService.error(error);
      return false;
    }
  }

  getApprovedInvoices() {
    return this.restService.getApprovedInvoices().subscribe(
      (jobHistory: JobHistoryView[]) => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(jobHistory);
        this.dataSource.sort = this.matSort;
      },
      (error) => {
        this.isLoading = false;
        this.alertService.error(error);
      }
    );
  }

  /*openPdf(blobString: string) {
    var blob = this.functionsService.b64toBlob(blobString);
    var blobURL = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = blobURL;
    link.target = '_blank';
    link.click();
  }*/

  downloadPdf(base64String: any, fileName: string) {
    const source = base64String;
    const link = document.createElement('a');
    link.href = source;
    link.setAttribute('target', '_blank');
    link.download = fileName;
    link.click();
  }

  onFileChange(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const workBook: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsName: string = workBook.SheetNames[0];
      const workSheet: XLSX.WorkSheet = workBook.Sheets[wsName];

      /* save data */
      this.invoicesArray = <AoA>XLSX.utils.sheet_to_json(workSheet, {
        header: 1,
        raw: true,
        //raw: false,
        //dateNF: 'dd/MM/yyyy',
        //rawNumbers: false
      });

      if (this.invoicesArray[0][0] != 'ID') {
        Swal.fire('Failed!', 'Payments failed. Invalid payment file!', 'error');
        return;
      }
      let html = XLSX.utils.sheet_to_html(workSheet, { header: '<b>' + target.files[0].name + '</b>' });
      html = html.replace('<table>', '<table class="table table-bordered" style="border-collapse: collapse; width:100%; border: 1px solid;border-color: #96D4D4;"> ');
      //let invoiceCount = this.invoicesArray.length - 1;
      //let isSwalResultConfirmed = false;
      let errorCount = 0;

      Swal.fire({
        title:
          'Please confirm whether you want to update the invoices listed below as paid.<br/><b><u>This cannot be reversed once confirmed!</u></b>',
        html: html,
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: `Confirm`,
        denyButtonText: `Cancel`,
        confirmButtonColor: '#007bff',
        denyButtonColor: '#dc3545',
        focusDeny: true,
        width: 1800,
        padding: '1em',
        heightAuto: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const parsedArray = this.invoicesArray.slice(1).map(r => r[0]);
            const hasNonNumber = parsedArray.some(element => isNaN(Number(element)));
            if (hasNonNumber) {
              Swal.fire('Failed!', 'Payments failed. ID column contains no numberic value, please fix it and try again!', 'error');
              return;
            }
            else {
              this.restService.uploadPayments(this.invoicesArray.slice(1).map(r=>parseInt(r[0])), 
              this.msalService.instance.getActiveAccount()!.name!,
              this.msalService.instance.getActiveAccount()!.username!)
                .pipe(first()).subscribe({
                  next: (response: HttpResponse<any>) => {
                    if (response.status == 200) {
                      {
                        this.getRemoteData();
                        Swal.fire(
                          this.invoicesArray.slice(1).length.toString() +
                            ' Invoice(s) Processing! notification email will be sent once processing complete.',
                          '',
                          'success'
                        );
                      }
                    } else if (response.status == 400) {
                      this.getRemoteData();
                        Swal.fire(
                            'Bad Request! Please check the Excel file, or logout and login to try again.',
                          '',
                          'error'
                        );
                    } else {
                      this.getRemoteData();
                        Swal.fire(
                            'Error or Exception occur when uploading payments! Please contact the Mk3 Accounts Department or the IT Helpdesk.',
                          '',
                          'error'
                        );
                    }
                  },
                  error: (err) => {
                    this.getRemoteData();
                      Swal.fire(
                          'Error or Exception occur when uploading payments! Please contact the Mk3 Accounts Department or the IT Helpdesk.'+ err,
                        '',
                        'error'
                      );
                  }
              });
            }
          }
        })
     };
     reader.readAsBinaryString(target.files[0]);
  }

  rejectInvoice(jobHistoryId: number) {
    this.restService
      .getJobHistoryView(jobHistoryId)
      .subscribe(async (jobHistoryRecord) => {
        if (
          jobHistoryRecord && //this.appComponent.getUserType() == 1 || this.appComponent.getUserType() == 9
          (this.appComponent.userGroupsArray.includes(groups.groupIT) ||
            this.appComponent.userGroupsArray.includes(groups.groupFinance))
        ) {
          let title = 'Are you sure you want to Reject this?';
          await Swal.fire({
            title: title,
            text: 'Please provide the reason for rejection',
            input: 'text',
            inputPlaceholder: 'Please enter the reason here',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: `Yes`,
            denyButtonText: `No`,
            confirmButtonColor: '#007bff',
            denyButtonColor: '#dc3545',
            focusDeny: true,
            width: 450,
            padding: '1em',
            heightAuto: false,
            inputValidator: (value) => {
              if (!value) {
                return 'You need to write something!';
              } else if (value.length > 250) {
                return 'Reason too Long!. Should be less than 250 characters';
              } else {
                return '';
              }
            },
          }).then(
            async (result) => {
              let rejectComment = result.value;
              if (result.isConfirmed) {
                let emailRecord = new EmailRecord();
                emailRecord.id = jobHistoryRecord.jobId!;
                emailRecord.statusId = 17;

                this.restService
                  .updateEmailRecord(
                    emailRecord.id,
                    emailRecord.statusId,
                    emailRecord
                  )
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      let jobHistory = new JobHistory();
                      jobHistory.jobId = jobHistoryRecord.jobId;
                      jobHistory.statusId = emailRecord.statusId;
                      jobHistory.comments =
                        '<b>Invoice Rejected : </b>' + rejectComment;
                      jobHistory.date = null;//new Date();
                      jobHistory.addedBy =
                        this.msalService.instance.getActiveAccount()!.name!;
                      jobHistory.timeStamp = null;
                      jobHistory.invoiceNumber = jobHistoryRecord.invoiceNumber;
                      jobHistory.invoiceAmount = jobHistoryRecord.invoiceAmount;
                      jobHistory.isFinalInvoice =
                        jobHistoryRecord.isFinalInvoice;
                      jobHistory.originalHistoryId =
                        jobHistoryRecord.originalHistoryId;

                      this.restService
                        .addNewJobHistoryRecord(jobHistory)
                        .subscribe((x) => {
                          this.restService
                            .getClaimOwnerDetails(jobHistoryRecord.refNo!)
                            .subscribe((result): any => {
                              let claimOwnerEmails =
                                (result.consultantEmail
                                  ? result.consultantEmail + ';'
                                  : '') +
                                (result.coordinatorEmail
                                  ? result.coordinatorEmail
                                  : '');
                              var email: Email;
                              email = {
                                from: 'trades@mk3.com.au',
                                to: 'accounts@mk3.com.au',
                                cc: claimOwnerEmails,
                                bcc: 'test@mk3.com.au',
                                subject: '',
                                body:
                                  '<b>Reject Reason: </b>' +
                                  rejectComment +
                                  '<br/><b>RefNo:</b> <a href=https://portal.mk3apps.com.au/case-details/' +
                                  jobHistoryRecord.refNo +
                                  '>' +
                                  jobHistoryRecord.refNo +
                                  '</a>' +
                                  '<br/><br/>Please see the <a href=https://contractors.mk3apps.com.au/job-details/' +
                                  jobHistoryRecord.jobId +
                                  ' target="_blank">details here.</a>',
                                attachmentName: null,
                                attachmentContent: null,
                                extraAttachmentName: null,
                                extraAttachmentContent: null,
                              };

                              email.subject =
                                jobHistoryRecord.refNo +
                                ' - ' +
                                'Rejected Invoice #: ' +
                                jobHistoryRecord.invoiceNumber;
                              Swal.fire(
                                'Invoice Rejected!',
                                '',
                                'success'
                              ).then(() => {
                                if (emailRecord.id) {
                                  this.router
                                    .navigate([
                                      '../job-details/' + emailRecord.id,
                                    ])
                                    .then(() => {
                                      window.location.reload();
                                    });
                                }
                              });
                              //this.restService.sendEmail(email).subscribe();
                              this.restService.sendEmail(email).subscribe({
                                next: () => {},
                                error: (err: any) => {
                                  Swal.fire(
                                    'Error (Reject Approved Invoice): restService.sendEmail: ' +
                                      err,
                                    '',
                                    'error'
                                  );
                                  console.log(
                                    'Error (Reject Approved Invoice): restService.sendEmail: ' +
                                      err
                                  );
                                },
                              });
                            });
                        });
                    },
                  });
              }
            },
            (error) => {
              this.alertService.error(error);
            }
          );
        } else {
          // Swal.fire(
          //   'Invoice Not Found!',
          //   'Please contact the Mk3 Accounts Department or the IT Helpdesk',
          //   'error'
          // )
          Swal.fire(
            'Sorry, you do not have permissions to perform this action!',
            '',
            'error'
          ).then(() => {
            if (jobHistoryRecord.jobId) {
              this.router
                .navigate(['../job-details/' + jobHistoryRecord.jobId])
                .then(() => {
                  window.location.reload();
                });
            }
          });
        }
      });
  }
}
