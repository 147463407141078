<div class="centered-div" *ngIf="!isLoggedIn()">
  <h2 style="color: var(--mk3red);">NIB/ Mk3 Contractors</h2>
  <div class="headerlogo">
    <img src="assets/mk3-logo.png" alt="logo" style="height:140px; width: 140px; float: center;">
    <a href="javascript:void(0)" (click)="login()">Sign In</a>
  </div>
</div>

<div *ngIf="isLoggedIn()" class=".app-container">
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="topnav" id="myTopnav">
      <a class="nav-link" [routerLink]="['/contractors']" routerLinkActive="active">Contractors</a>
      <a class="nav-link" [routerLink]="['/internal-trades']" routerLinkActive="active">Internal Trades</a>
      <a class="nav-link" [routerLink]="['/add-edit-contractor']" routerLinkActive="active">Add New Contractor</a>
      <div class="dropdown">
        <button class="dropbtn">Add/ Edit Categories&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown-content">
          <a class="nav-link" [routerLink]="['/licences']" routerLinkActive="active">Licence Classifications</a>
          <a class="nav-link" [routerLink]="['/insurers']" routerLinkActive="active">Insurers</a>
          <a class="nav-link" [routerLink]="['/regions']" routerLinkActive="active">Geo Regions</a>
        </div>
      </div>
      <a class="nav-link" [routerLink]="['/invoices-awaiting-approval']" routerLinkActive="active">Invoices Awaiting</a>
      <a class="nav-link" [routerLink]="['/approved-invoices']" routerLinkActive="active">Invoices Approved</a>
      <a class="nav-link" [routerLink]="['/invoices-paid']" routerLinkActive="active">Invoices Paid</a>
      <a class="nav-item nav-link" routerLink="" (click)="logout()">Sign Out</a>
    </div>
    <div class="topnav-right">
      <div class="navbar-brand">{{getUserName()}}</div>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
<div class="footer-container" #footer>
  <p class="footer-content">&copy; Mk3 Pty Ltd {{ year }}</p>
</div>