<div class="main-container">
  <h1>NIB/ Mk3 Contractors</h1>

  <!-- CSS loading animation container -->
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div *ngIf="!isLoading" class="search-option-radio" style="flex: auto;">
    <mat-radio-group (change)=radioChange($event) aria-label="Select an option">
      <mat-radio-button class="search-option-radio-button" [value]=false [checked]=!isSearch>Filter</mat-radio-button>
      <mat-radio-button class="search-option-radio-button" [value]=true [checked]=isSearch>Search</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Filter Box -->
  <div *ngIf="isSearch" class="search-box-container">
    <mat-form-field class="search-box-form-field">
      <mat-label>Search Contractors by Keyword</mat-label>
      <input #search matInput type="text" (keyup)="onSearchKeyUp(search)" [value]="searchValue" autofocus>
      <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="onClearClicked(search)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div *ngIf="!isSearch" class="vertical-form-field">
    <div>
      <mat-form-field *ngFor="let filter of filterSelectObj; let i = index" appearance="fill" class="no-label-field">
        <div *ngIf="i == 0">
          <mat-select [formControl]="stateFilter">
            <mat-option value="">Filter by {{filter.name}}</mat-option>
            <mat-option [value]="item" *ngFor="let item of filter.options">{{item}}</mat-option>
          </mat-select>
        </div>
        <div *ngIf="i == 1">
          <mat-select [formControl]="geoRegionsFilter">
            <mat-option value="">Filter by {{filter.name}}</mat-option>
            <mat-option [value]="item" *ngFor="let item of filter.options">{{item}}</mat-option>
          </mat-select>
        </div>
        <div *ngIf="i == 2">
          <mat-select [formControl]="tradeFilter">
            <mat-option value="">Filter by {{filter.name}}</mat-option>
            <mat-option [value]="item" *ngFor="let item of filter.options">{{item}}</mat-option>
          </mat-select>
        </div>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="no-label-field" *ngIf="tradeServicesList.length>0">
      <mat-select [formControl]="tradeServicesFilter" multiple>
        <mat-option value="" disabled>Filter by Trade Services</mat-option>
        <mat-option [value]="item" *ngFor="let item of tradeServicesList">{{item}}</mat-option>
      </mat-select> 
      </mat-form-field>

      <button *ngIf="!isLoading" class="pc-button btn btn-sm btn-danger" (click)="clearFilters()">Clear Filters</button>
      <div fxShow.sm="true" fxShow.gt-sm="false">
        <button *ngIf="!isLoading" class="btn btn-sm btn-danger" (click)="clearFilters()">Clear Filters</button>
      </div>

    </div>
  </div>

  <div class="main-table-container">
    <!-- Angular Material Table -->
    <table mat-table [dataSource]="dataSource" matSort matSortStart="asc" matSortDisableClear
      class="mat-elevation-z8 table-striped" (matSortChange)="sortData($event)">
      <ng-container matColumnDef="contractorId">
        <th style="width: 1%;" mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell style="text-align: right!important; padding-right: 10px;" *matCellDef="let contractors">
          {{contractors.contractorId}}</td>
      </ng-container>
      <ng-container matColumnDef="tradingName">
        <th class="w-15" mat-header-cell *matHeaderCellDef mat-sort-header>Trading Name</th>
        <td mat-cell *matCellDef="let contractors">
          <div class="red-text-highlighted"
          *ngIf="contractors.complianceStatus < 5 && (contractors.statusId==1 || contractors.statusId==4); else elseNormalTradingName">
          {{contractors.tradingName}}</div>
          <ng-template #elseNormalTradingName>
            {{contractors.tradingName}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th class="w-5" mat-header-cell class="mat-cell-centered" *matHeaderCellDef mat-sort-header>State</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors">{{contractors.state}}</td>
      </ng-container>
      
      <ng-container matColumnDef="suburb">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Suburb</th>
        <td mat-cell *matCellDef="let contractors">{{contractors.suburb}}&nbsp;</td>
      </ng-container>
      <ng-container matColumnDef="postcode">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Postcode</th>
        <td mat-cell *matCellDef="let contractors">{{contractors.postcode}}&nbsp;</td>
      </ng-container>
      
      <ng-container matColumnDef="geoRegion">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Geo Region</th>
        <td mat-cell *matCellDef="let contractors">
          <ng-container *ngIf="contractors.geoRegion == null; else elseValidGeoRegion">
            Unknown
          </ng-container>
          <ng-template #elseValidGeoRegion>
            {{contractors.geoRegion}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th class="w-5" mat-header-cell class="mat-cell-centered" *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors"
          matTooltip="{{restService.getStatusDefinition(contractors.statusId)}}" matTooltipPosition="right">
          {{contractors.status}}</td>
      </ng-container>
      <ng-container matColumnDef="tncStatus">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>T & C Status</th>
        <td mat-cell *matCellDef="let contractors">{{contractors.tncStatus}}&nbsp;</td>
      </ng-container>
      <ng-container matColumnDef="compliantUntil">
        <th class="w-5" mat-header-cell class="mat-cell-centered" *matHeaderCellDef mat-sort-header>Compliant Until</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors">
          <div class="red-text-highlighted"
            *ngIf="(contractors.complianceStatus < 5) && (contractors.statusId==1 || contractors.statusId==4)">
            {{contractors.compliantUntil | date: 'dd/MM/yyyy'}}</div>
          <div *ngIf="(contractors.complianceStatus < 5) && !(contractors.statusId==1 || contractors.statusId==4)">
            {{contractors.compliantUntil | date: 'dd/MM/yyyy'}}</div>
          <!-- <div class="red-text-highlighted" *ngIf="contractors.complianceStatus==1">
            {{contractors.compliantUntil | date: 'dd/MM/yyyy'}}</div>
          <div class="red-text-highlighted" *ngIf="contractors.complianceStatus==2 || contractors.complianceStatus==3">
            {{contractors.compliantUntil | date: 'dd/MM/yyyy'}}</div> -->
          <div class="green-text"
            *ngIf="!(functionsService.expireInMonthDate(contractors.compliantUntil)) && contractors.complianceStatus > 4">
            {{contractors.compliantUntil | date: 'dd/MM/yyyy'}}</div>
          <div class="orange-text"
            *ngIf="(functionsService.expireInMonthDate(contractors.compliantUntil)) && contractors.complianceStatus > 4">
            {{contractors.compliantUntil | date: 'dd/MM/yyyy'}}</div>
          <div class="red-text-highlighted"
            *ngIf="contractors.compliantUntil==null && (contractors.statusId==1 || contractors.statusId==4)">&nbsp;
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="isCompliant">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Compliant</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors">
          <div class="red-text-highlighted"
            *ngIf="contractors.complianceStatus < 5 && (contractors.statusId==1 || contractors.statusId==4)">
            {{contractors.isCompliant}}</div>
          <div *ngIf="contractors.complianceStatus < 5 && !(contractors.statusId==1 || contractors.statusId==4)">
            {{contractors.isCompliant}}</div>
          <div class="green-text"
            *ngIf="!(functionsService.expireInMonthDate(contractors.compliantUntil)) && contractors.complianceStatus > 4">
            {{contractors.isCompliant}}</div>
          <div class="orange-text"
            *ngIf="(functionsService.expireInMonthDate(contractors.compliantUntil)) && contractors.complianceStatus > 4">
            {{contractors.isCompliant}}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="complianceStatus">
        <th style="width: 1%;" mat-header-cell *matHeaderCellDef mat-sort-header>Compliant Score</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors">
          <div class="red-text-highlighted"
            *ngIf="contractors.complianceStatus < 5 && (contractors.statusId==1 || contractors.statusId==4)">
            {{contractors.complianceStatus}}</div>
          <div *ngIf="contractors.complianceStatus < 5 && !(contractors.statusId==1 || contractors.statusId==4)">
            {{contractors.complianceStatus}}</div>
          <div class="green-text" *ngIf="!(functionsService.expireInMonthDate(contractors.compliantUntil)) && contractors.complianceStatus > 4">
            {{contractors.complianceStatus}}</div>
          <div class="orange-text"
            *ngIf="(functionsService.expireInMonthDate(contractors.compliantUntil)) && contractors.complianceStatus > 4">
            {{contractors.complianceStatus}}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="avgRating">
        <th style="width: 1%;" mat-header-cell *matHeaderCellDef mat-sort-header>Quality Rating</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors">{{contractors.avgRating | number :
          '1.1-1'}}&nbsp;</td>
      </ng-container>
      <ng-container matColumnDef="swmsProvidedDate">
        <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header>SWMS Provided</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors">{{contractors.swmsProvidedDate | date:
          'dd/MM/yyyy'}}&nbsp;</td>
      </ng-container>
      <ng-container matColumnDef="dateLastUsed">
        <th style="width: 5%;" mat-header-cell *matHeaderCellDef mat-sort-header>Last Used</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors">{{contractors.dateLastUsed | date:
          'dd/MM/yyyy'}}&nbsp;</td>
      </ng-container>
      <ng-container matColumnDef="dateEvaluated">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Evaluated</th>
        <td mat-cell class="mat-cell-centered" *matCellDef="let contractors">{{contractors.dateEvaluated | date:
          'dd/MM/yyyy'}}&nbsp;</td>
      </ng-container>
      <ng-container matColumnDef="mainContactName">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Contact Name</th>
        <td mat-cell *matCellDef="let contractors">{{contractors.mainContactName}}&nbsp;</td>
      </ng-container>
      <ng-container matColumnDef="mainContactPhone">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Contact Phone</th>
        <td mat-cell *matCellDef="let contractors">{{contractors.mainContactPhone}}&nbsp;</td>
      </ng-container>
      <ng-container matColumnDef="mainTradeCategory">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Main Trade Category</th>
        <td mat-cell *matCellDef="let contractors">{{contractors.mainTradeCategory}}&nbsp;</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [routerLink]="'/contractor-details/'+ row.contractorId" class="row-hover mat-row"
        [ngClass]="{'highlight-table-row': row.status == 'Blacklisted'}"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          <div *ngIf="this.searchValue != ''">
            <b>No contractors found that matches your query. Please try a different query.</b>
          </div>
        </td>
      </tr>
    </table>

    <!-- Paginator -->
    <mat-paginator *ngIf="dataSource" #paginator 
               [pageSizeOptions]="[15, 50, 100, dataSource.data.length]" 
               [pageSize]="pageSizeControl.value"
               (page)="onPageSizeChange()">
  </mat-paginator>
  <!-- <mat-paginator [pageSizeOptions]="pageSizeOptions" ... ></mat-paginator> -->

  </div>

  <!-- Reload Button -->
  <!-- <div class="center-button-container" style="padding: 0px">
      <button mat-raised-button class="button" (click)=refreshData()>Reload</button>
    </div> -->

  <div class="center-button-container" *ngIf="!isLoading">
    <button class="btn btn-sm btn-primary" (click)=addNewContractor()>Add New Contractor</button>
  </div>
</div>