import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActionRequiredForSA, AppId, LargeLossApproval } from '@app/_helpers/constants';
import { Case } from '@app/_models/case';
import { Employee } from '@app/_models/employee';
import { JobFile } from '@app/_models/job-file';
import { JobHistoryView } from '@app/_models/job-history-view';
import { AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import { Observable, catchError, map } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoices-awaiting-approval',
  templateUrl: './invoices-awaiting-approval.component.html',
  styleUrls: ['./invoices-awaiting-approval.component.scss'],
})
export class InvoicesAwaitingApprovalComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'refNo',
    'state',
    'invoiceNumber',
    'invoiceAmount',
    'tradingName',
    'instructionTypeId',
    'date',
    'assessor',
    'addedBy',
    'timeStamp',
    'actionRequired',
    'details',
    'viewDocument',
  ];

  filterValues: any = {
    state: localStorage.getItem('stateFilter')
      ? localStorage.getItem('stateFilter')
      : '',
      actionRequired: localStorage.getItem('actionRequiredFilter')
      ? localStorage.getItem('actionRequiredFilter')
      : '',
  };

  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  isLoading = true;
  searchValue = localStorage.getItem('searchTextInvoicesAwaitingApproval')
    ? localStorage.getItem('searchTextInvoicesAwaitingApproval')
    : '';
  isSearch:boolean =  localStorage.getItem('searchOptionInvoicesAwaitingApproval') != null
    ? localStorage.getItem('searchOptionInvoicesAwaitingApproval')?.toString() == 'true'
    : false;
  filterSelectObj: any = [];
  stateFilter = new FormControl(
    localStorage.getItem('stateFilter')
      ? localStorage.getItem('stateFilter')
      : ''
  );
  actionRequiredFilter = new FormControl(
    localStorage.getItem('actionRequiredFilter')
      ? localStorage.getItem('actionRequiredFilter')
      : ''
  );

  constructor(
    public restService: RestService,
    private alertService: AlertService,
    public functionsService: FunctionsService,
  ) {
  }

  updateFilters() {
    if (this.isSearch) {
      this.searchValue = localStorage.getItem('searchTextInvoicesAwaitingApproval') || '';
      this.dataSource.filter = this.searchValue!;
    } else {
      this.filterSelectObj.forEach((o: { options: any[]; columnProp: string | number }) => {
        o.options = this.getFilterObject(this.dataSource.data, o.columnProp).sort();
      });
      this.fieldListener();
      this.dataSource.filterPredicate = this.createFilter();
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.filterSelectObj = [
      {
        name: 'State',
        columnProp: 'state',
        options: [],
      },
      {
        name: 'Action Required',
        columnProp: 'actionRequired',
        options: [],
      },
    ];
    this.getRemoteData().then(() => {
      if (this.isSearch) {
        this.searchValue = localStorage.getItem('searchTextInvoicesAwaitingApproval') || '';
        this.dataSource.filter = this.searchValue!;
      } else {
        this.filterSelectObj.filter(
          (o: { options: any[]; columnProp: string | number }) => {
            o.options = this.getFilterObject(
              this.dataSource.data,
              o.columnProp
            ).sort();
          }
        );
        this.fieldListener();
        this.dataSource.filterPredicate = this.createFilter();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      }
      this.isLoading = false;
    }).catch(error => {
      console.error('Error fetching data:', error);
      this.isLoading = false;
    });
  }

  radioChange(event: MatRadioChange) {
    this.isSearch = event.value;
    localStorage.setItem('searchOptionInvoicesAwaitingApproval', this.isSearch.toString());

    this.getRemoteData().then(() => {
      if (this.isSearch) {
        this.searchValue = localStorage.getItem('searchTextInvoicesAwaitingApproval') || '';
        this.dataSource.filter = this.searchValue!;
      } else {
        this.filterSelectObj.filter(
          (o: { options: any[]; columnProp: string | number }) => {
            o.options = this.getFilterObject(
              this.dataSource.data,
              o.columnProp
            ).sort();
          }
        );
        this.fieldListener();
        this.dataSource.filterPredicate = this.createFilter();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      }
      this.isLoading = false;
    }).catch(error => {
      console.error('Error fetching data:', error);
      this.isLoading = false;
    });
  }

  clearFilters() {
    try {
      this.stateFilter.setValue('');
      this.actionRequiredFilter.setValue('');
      localStorage.setItem('stateFilter', '');
      localStorage.setItem('actionRequiredFilter', '');

    } catch (error: any) {
      this.alertService.error(error);
    }
  }

  private createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (
      this: any,
      data: {
        state: string | any[];
        actionRequired: string | any[];
      },
      filter: string
    ): boolean {
      let searchTerms = JSON.parse(filter);
      return (
        data.state.indexOf(searchTerms.state) !== -1 &&
        data.actionRequired.indexOf(
          searchTerms.actionRequired == null ? '' : searchTerms.actionRequired
        ) !== -1);
    };
    return filterFunction;
  }

  private fieldListener() {
    try {
      this.stateFilter.valueChanges.subscribe((state) => {
        if (state == null) state = '';
        this.filterValues.state = state;
        this.dataSource.filter = JSON.stringify(this.filterValues);
        localStorage.setItem('stateFilter', this.filterValues.state);
      });
      this.actionRequiredFilter.valueChanges.subscribe((actionRequired) => {
        if (actionRequired == null) actionRequired = '';
        this.filterValues.actionRequired = actionRequired;
        this.dataSource.filter = JSON.stringify(this.filterValues);
        localStorage.setItem('actionRequiredFilter', this.filterValues.actionRequired);
      });
    } catch (error: any) {
      this.alertService.error(error);
    }
  }

  getRemoteData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.getInvoicesAwaitingApproval().subscribe(
        (jobHistory: JobHistoryView[]) => {
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(jobHistory);
          this.dataSource.sort = this.matSort;
          const ids = jobHistory.map((item) => item.refNo);
  
          const casePromises = ids.map((id) => this.restService.getCase(id!).toPromise());
          const ownerPromises = ids.map((id) => this.restService.getClaimOwnerDetails(id!).toPromise());
  
          Promise.all(casePromises).then((cases: Case[]) => {
            cases.forEach((cs, index) => {
              this.dataSource.data[index].state = cs.state;
            });
  
            Promise.all(ownerPromises).then((assessors) => {
              const updatePromises = assessors.map((claimOwnerDetails, index) => {
                this.dataSource.data[index].assessor = claimOwnerDetails!.consultant;
                if (this.dataSource.data[index].statusId == 19) {
                  return this.restService.getADUserManagerDetails(claimOwnerDetails!.consultantID).toPromise().then((userDetails) => {
                    if (userDetails[0].reviewerId != null && userDetails[0].appId == AppId) {
                      return this.restService.getEmployeeDetailsByEmail(userDetails[0].reviewerEmail).toPromise().then((approver: Employee | undefined) => {
                        {
                          this.dataSource.data[index].actionRequired = approver?.employeeName;
                        } 
                        this.dataSource._updateChangeSubscription();
                        return; 
                      });
                    } else if (userDetails[0].workStateId == 6) {
                      this.dataSource.data[index].actionRequired = ActionRequiredForSA;
                      this.dataSource._updateChangeSubscription();
                      return; 
                    } else {
                      return this.getStateManager(claimOwnerDetails!.consultantID).toPromise().then((data) => {
                        this.dataSource.data[index].actionRequired = data;
                        this.dataSource._updateChangeSubscription();
                        return; 
                      });
                    }
                  });
                } else if (this.dataSource.data[index].statusId == 20) {
                  this.dataSource.data[index].actionRequired = LargeLossApproval;
                  return Promise.resolve(); 
                } else {
                  this.dataSource.data[index].actionRequired = this.dataSource.data[index].assessor;
                  return Promise.resolve(); 
                }
              });
  
              Promise.all(updatePromises).then(() => {
                resolve();
              }).catch((error) => {
                Swal.fire('Error updating data<br/>' + error, '', 'error');
                reject(error);
              });
            }).catch((error) => {
              Swal.fire('Error fetching claim owner details<br/>' + error, '', 'error');
              reject(error);
            });
          }).catch((error) => {
            Swal.fire('Error fetching cases<br/>' + error, '', 'error');
            reject(error);
          });
        },
        (error) => {
          this.isLoading = false;
          Swal.fire('Error fetching invoices awaiting approval<br/>' + error, '', 'error');
          reject(error);
        }
      );
    });
  }
  
  getFilterObject(fullObj: any[], key: string | number) {
    const uniqChk: any[] = [];
    fullObj.filter((obj: { [x: string]: any }) => {
      if (!uniqChk.includes(obj[key])) {
        if (obj[key]) uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  getStateManager(employeeId: number): Observable<string> {
    return this.restService.GetStateManagerForEmployee(employeeId).pipe(
      map((data: Employee) => {
        return data ? data.employeeName : 'State/Branch Manager';
      }),
      catchError((error) => {
        this.alertService.error(error);
        return new Observable<string>(); // Return an empty observable or handle the error differently.
      })
    );
  }

  /*getStateManager(employeeEmail: string): string {
    this.restService.getEmployeeDetailsByEmail(employeeEmail).subscribe(
      (data) => {
        this.restService.getStateManagerDetails(data.workStateId!).subscribe(
          (data) => {
            return data.employeeName;
          });
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }*/

  /*getStateManager(employeeEmail: string): Observable<string> {
    return this.restService.getEmployeeDetailsByEmail(employeeEmail).pipe(
      switchMap((assessor: Employee) => {
        return this.restService.getStateManagerDetails(assessor.workStateId!);
      }),
      map((stateManager: Employee) => 
      stateManager.employeeName),
      catchError((error) => {
        this.alertService.error(error);
        return new Observable<string>(); // Return an empty observable or handle the error differently.
      })
    );
  }*/

  openInvoicePDF(id: number) {
    this.restService.getInvoicePDF(id).subscribe(
      (data: JobFile) => {
        this.functionsService.openPdf(data.fileData);
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

    //Search by keyword
  onSearchKeyUp(event: Event) {
      try {
        var currentFilter = (event.target as HTMLInputElement).value;
        this.dataSource.filter = currentFilter;
        localStorage.setItem('searchTextInvoicesAwaitingApproval', currentFilter);
        this.searchValue=currentFilter;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      } catch (error) {
        Swal.fire('Error in Search by keyword : ' + error, '', 'error');
      }
    }
  
    //Clear search by keyword
  onClearClicked(search: { value: string }) {
      this.dataSource.filter = '';
      search.value = '';
      this.searchValue = '';
      localStorage.setItem('searchTextInvoicesAwaitingApproval', '');
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
}
