import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAcnMask]'
})
export class AcnMaskDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Remove all non-numeric characters and spaces
    let cleanedValue = value.replace(/[^0-9]/g, '');

    // Ensure the cleaned value is at most 9 characters (XXX XXX XXX)
    if (cleanedValue.length > 9) {
      cleanedValue = cleanedValue.slice(0, 9);
    }

    // Format the value as "XXX XXX XXX"
    let formattedValue = '';

    for (let i = 0; i < cleanedValue.length; i++) {
      if (i === 3 || i === 6) {
        formattedValue += ' ';
      }
      formattedValue += cleanedValue[i];
    }

    // Update the form control value with the formatted value
    this.control.control?.setValue(formattedValue, { emitEvent: false });
  }
}