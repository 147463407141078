import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private apiUrl =
    'https://wgil-api-management.azure-api.net/common/api/Errors?subscription-key=79c1934d47f54b818f72e00b76118d6a'; // Your API URL
  constructor(private http: HttpClient) {}

  sendErrorDetails(
    err: any,
    functionName: string,
    errorMessage: string,
    adName: string
  ) {
    let errorDetail = '';

    // Check if err is a string or an object with a message property
    if (typeof err === 'string') {
      errorDetail = err;
    } else if (err && err.message) {
      errorDetail = err.message;
    }
    // Escape newline characters
    const escapedErrorDetail = errorDetail.replace(/\\\\n/g, '<br>');
    const errorData = {
      appId: 11,
      functionName: functionName,
      severity: 0,
      errorCode: 0,
      errorMessage: errorMessage,
      errorDetail: escapedErrorDetail,
      adName: adName,
    };

    // Send the JSON data to the backend API
    this.http.post(this.apiUrl, errorData).subscribe({
      next: (response) =>
        console.log('Error details sent successfully', response),
      error: (error) => console.error('Error sending error details', error),
    });
  }
}
