export class QualityRating {
    id: number = 0;
    contractorId: number | null = null;
    jobId: number | null = null;
    qoWscore: number | null = null;   
    hscandAqscore: number | null = null;
    jobTimeScore: number | null = null;
    totalScore: number | null = null;
    comments: string | null = null;
    addedBy: string | null = null;
    addedOn: Date | null = null;
}