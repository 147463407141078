export class CommentOnly {
    jobId: number=0;
    refNo: number=0;
    insured: string ='';
    tradeName : string ='';
    comment: string = '';
    claimOwnerEmails: string = '';
    addedBy: string = '';
    notifyFinance : boolean=false;
    NotifyOwner : boolean=false;
}