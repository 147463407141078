export class TncAgreement {
    id: string='';
    contractorId: number | null = null;
    documentTitle: string | null = null;
    dateSigned: Date | null = null;
    dateSent:Date | null = null;
    versionNo: number | null = null;
    signedPdfData: string | null = null;
    signeeName: string | null = null;
    signeeTitle: string | null = null;
}