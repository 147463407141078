import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { Contractor } from '@app/_models/contractor';
import { GenerateDocumentDialogComponent } from '@app/generate-document-dialog/generate-document-dialog.component';
import { Case } from '@app/_models/case';
import { InternalTrade } from '@app/_models/internal-trade';
import { AlertService } from '@app/_services';
import { RestService } from '@app/_services/rest.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(public dialog: MatDialog, public appComponent: AppComponent, private router: Router, private alertService: AlertService,
    @Optional() public inputRefNoDialogRef: MatDialogRef<InputRefNoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) {
    this.router.navigate(['/contractors']);
  }

  ngOnInit(): void {
    this.alertService.clear();
  }
}

// ==================================== Input-RefNo Dialog ====================================================
@Component({
  selector: 'input-refno-dialog',
  templateUrl: 'input-refno-dialog.html',
  styleUrls: ['home.component.scss']
})
export class InputRefNoDialog {
  public form!: FormGroup;
  isLoading = true;
  case!: Case;
  refNo!: number;
  consultantPhone: string = '';
  contractor!: Contractor;
  internalTrade!: InternalTrade;

  constructor(private restService: RestService, private alertService: AlertService,
    public dialog: MatDialog,
    public inputRefNoDialogRef: MatDialogRef<InputRefNoDialog>,
    public generateDocumentDialogRef: MatDialogRef<GenerateDocumentDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
      contractor: Contractor,
      internalTrade: InternalTrade,
      userName: string,
      userEmail: string
    }) {
    this.form = new FormGroup({
      refNo: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])
    });
  }

  ngOnInit(): void {
    if (this.data) {
      if (this.data.contractor)
        this.contractor = this.data.contractor;

      if (this.data.internalTrade)
        this.internalTrade = this.data.internalTrade;
    }
  }

  public onSubmitInputRefNoDialog(data: any): void {
    try {
      if (this.form.invalid)
        return;
      this.isLoading = true;
      this.restService.getCase(this.form.controls['refNo'].value).subscribe((caseDetails: Case) => {
        if(caseDetails.statusClaimTypeId == 2 || caseDetails.statusClaimTypeId == 4)
        {
          Swal.fire('Claim Finalised!', 'The claim <b><u>' + caseDetails.refNo + '</u></b> is finalised and needs to be reopened to send instructions!', 'error');
          this.isLoading = false;
          return;
        }

        this.case = caseDetails;
        this.inputRefNoDialogRef.close(caseDetails);
        this.isLoading = false;
        this.generateDocumentDialogRef = this.dialog.open(GenerateDocumentDialogComponent, {
          disableClose: true,
          closeOnNavigation: false,
          data: { contractor: this.contractor, case: this.case, internalTrade: this.internalTrade, userName: this.data.userName, userEmail: this.data.userEmail }
        });
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
        this.form.controls['refNo'].setErrors({ pattern: '*****' })
      });
    }
    catch (error: any) {
      this.isLoading = false;
      return;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }
}
