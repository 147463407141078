<div class="main-container">
  <h1>Job/ Trade Instruction Details</h1>

  <!-- CSS loading animation container -->
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="contractor-details-container mat-elevation-z8  bg-light" style="vertical-align: top;">
    <mat-grid-list class="grid-tile-content" cols="4">
      <mat-grid-tile class="grid-tile data-tile">
        <mat-list>
          <mat-list-item class="list-key-column"> Job/ Trade Instruction ID </mat-list-item>
          <mat-list-item class="list-key-column" *ngIf="!this.isInternalInstructions"> Contractor</mat-list-item>
          <mat-list-item class="list-key-column" *ngIf="this.isInternalInstructions"> Internal Trade
            Name</mat-list-item>
          <mat-list-item class="list-key-column"> Ref No </mat-list-item>
          <mat-list-item class="list-key-column"> Total of all Invoices Approved for the Claim </mat-list-item>
          <mat-list-item class="list-key-column"> Assessor </mat-list-item>
          <mat-list-item class="list-key-column"> Coordinator </mat-list-item>
          <mat-list-item class="list-key-column"> From Email </mat-list-item>
          <mat-list-item class="list-key-column"> To Email </mat-list-item>
          <mat-list-item class="list-key-column"> Subject </mat-list-item>
          <!-- <mat-list-item class="list-key-column"> Start Date </mat-list-item>
          <mat-list-item class="list-key-column"> End Date </mat-list-item>
          <mat-list-item class="list-key-column"> Job Status </mat-list-item> -->
        </mat-list>
      </mat-grid-tile>
      <mat-grid-tile class="grid-tile data-tile">
        <mat-list>
          <mat-list-item class="list-value-column" *ngIf="emailRecord"> {{emailRecord.id}}</mat-list-item>
          <mat-list-item class="list-value-column green-text" *ngIf="!this.isInternalInstructions && this.contractor">
            <!-- {{this.contractor.tradingName}} -->
            <a href="/contractor-details/{{this.contractor.contractorId}}">{{this.contractor.tradingName}}</a>
          </mat-list-item>
          <mat-list-item class="list-value-column green-text" *ngIf="this.isInternalInstructions && !this.contractor">
            <!-- {{this.internalTrade.displayName}} -->
            <div *ngIf="this.internalTrade.employeeId">
              <a href="/internal-trade-details/{{this.internalTrade.employeeId}}">{{this.internalTrade.displayName}}</a>
            </div>
            <div *ngIf="!this.internalTrade.employeeId">NIB Internal Trade</div>
          </mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="emailRecord"><a
              href="https://portal.mk3apps.com.au/case-details/{{emailRecord.refNo}}"
              target="_blank">{{emailRecord.refNo}}</a></mat-list-item>
          <mat-list-item class="list-value-column red-text">${{(this.invoicesApprovedTotal ? this.invoicesApprovedTotal : 0) | number : '1.2-2'}} </mat-list-item>
          <mat-list-item class="list-value-column blue-text"> {{this.assessorName}} </mat-list-item>
          <mat-list-item class="list-value-column blue-text"> {{this.coordinatorName}} </mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="emailRecord"> <a
              href="tel:{{emailRecord.fromEmail}}">{{emailRecord.fromEmail}}</a></mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="emailRecord"> <a
              href="mailto:{{emailRecord.toEmail}}">{{emailRecord.toEmail}}</a> </mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="emailRecord"> {{emailRecord.subject}} </mat-list-item>
          <!-- <mat-list-item class="list-value-column" *ngIf="emailRecord"> {{emailRecord.startDate | date: 'dd/MM/yyyy'}}
          </mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="emailRecord"> {{emailRecord.endDate | date: 'dd/MM/yyyy'}}
          </mat-list-item>
          <mat-list-item *ngIf="emailRecord && emailRecord.statusId != 2" class="list-value-column green-text">
            {{getJobStatus(emailRecord.statusId)}} </mat-list-item>
          <mat-list-item *ngIf="emailRecord && emailRecord.statusId == 2" class="list-value-column red-text-highlighted">
            {{getJobStatus(emailRecord.statusId)}} </mat-list-item> -->
        </mat-list>
      </mat-grid-tile>
      <mat-grid-tile class="grid-tile data-tile">
        <mat-list>
          <mat-list-item class="list-key-column"> Emergency Repair Estimate </mat-list-item>
          <mat-list-item class="list-key-column"> Restoration Estimate</mat-list-item>
          <mat-list-item class="list-key-column"> Building Estimate</mat-list-item>
          <mat-list-item class="list-key-column"> Contents Estimate</mat-list-item>
          <mat-list-item class="list-key-column"> Building Amount Settled</mat-list-item>
          <mat-list-item class="list-key-column"> Contents Amount Settled</mat-list-item>
          <mat-list-item class="list-key-column"> Restoration Amount Settled</mat-list-item>
        </mat-list>
      </mat-grid-tile>
      <mat-grid-tile class="grid-tile data-tile">
        <mat-list>
          <mat-list-item class="list-value-column"> {{this.claimAll.emergencyRepairEst==null?'':'$'+(this.claimAll.emergencyRepairEst | number:"1.2-2")}}</mat-list-item>
          <mat-list-item class="list-value-column"> {{this.claimAll.restorationEstimate==null?'':'$'+(this.claimAll.restorationEstimate | number:"1.2-2")}}</mat-list-item>
          <mat-list-item class="list-value-column"> {{this.claimAll.buildingEstimate==null?'':'$'+(this.claimAll.buildingEstimate | number:"1.2-2")}}</mat-list-item>
          <mat-list-item class="list-value-column"> {{this.claimAll.contentsEstimate==null?'':'$'+(this.claimAll.contentsEstimate | number:"1.2-2")}}</mat-list-item>
          <mat-list-item class="list-value-column"> {{this.claimAll.buildingAmountSettled==null?'':'$'+(this.claimAll.buildingAmountSettled | number:"1.2-2")}}</mat-list-item>
          <mat-list-item class="list-value-column"> {{this.claimAll.contentsAmountSettled==null?'':'$'+(this.claimAll.contentsAmountSettled | number:"1.2-2")}}</mat-list-item>
          <mat-list-item class="list-value-column"> {{this.claimAll.restorationSettledAmount==null?'':'$'+(this.claimAll.restorationSettledAmount | number:"1.2-2")}}</mat-list-item>
        </mat-list>
      </mat-grid-tile>
    </mat-grid-list>
    <!-- <button *ngIf="emailRecord" mat-raised-button class="btn btn-sm btn-success" (click)='onUploadInvoice()'
    [disabled]="emailRecord.statusId == 2 || emailRecord.statusId == 10 || emailRecord.statusId == 16 || (isFinalInvoiceApproved() && !isFinalInvoiceRejected())">Upload Invoice</button>&nbsp; -->
    <button *ngIf="emailRecord" mat-raised-button class="btn btn-sm btn-success" (click)='onUploadInvoice()'
    [disabled]="emailRecord.statusId == 2 || emailRecord.statusId == 10  || emailRecord.statusId == 16 || isFinalInvoiceApproved()">Upload Invoice</button>&nbsp;
    <button *ngIf="emailRecord" mat-raised-button class="btn btn-sm btn-base"
      (click)='openDateChangePopup(emailRecord.id, true)' [disabled]="emailRecord.statusId == 2 || emailRecord.statusId == 10">Change Start
      Date</button>&nbsp;
    <button *ngIf="emailRecord" mat-raised-button class="btn btn-sm btn-base"
      (click)='openDateChangePopup(emailRecord.id, false)' [disabled]="emailRecord.statusId == 2 || emailRecord.statusId == 10">Change End
      Date</button>&nbsp;
      <button mat-raised-button class="btn btn-sm btn-danger"
            [hidden]="(emailRecord.instructionTypeId == 2 || emailRecord.instructionTypeId == 4)"
            [disabled]="emailRecord.retracted || emailRecord.statusId == 9 || emailRecord.statusId == 10"
            (click)="retract(emailRecord.id)">
            <div *ngIf="emailRecord.retracted" class="btn-disabled">Retracted</div>
            <div *ngIf="(!emailRecord.retracted) && emailRecord.statusId !== 9 && emailRecord.statusId !== 10">&nbsp;&nbsp;Retract&nbsp;&nbsp;
            </div>
            <div *ngIf="(!emailRecord.retracted) && (emailRecord.statusId == 9 || emailRecord.statusId == 10)" class="btn-disabled">
              &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
          </button>&nbsp;
          <button mat-raised-button class="btn btn-sm btn-color-navi margin-right-5" *ngIf="((emailRecord.statusId == 10 || emailRecord.statusId == 2) && hasPermissionForReopenInstruction)" (click)=reopenInstruction(emailRecord.id)>Reopen</button>
          <button mat-raised-button class="btn btn-sm btn-success"
      (click)='addComment()'>Add Comment</button>
    <a *ngIf="!this.isInternalInstructions && contractor" class="btn btn-link"
      routerLink="/contractor-details/{{this.contractor.contractorId}}" [queryParams]="{ tabId: 4 }">Back</a>
    <a *ngIf="this.isInternalInstructions && internalTrade" class="btn btn-link"
      routerLink="/internal-trade-details/{{this.internalTrade.employeeId}}">Back</a>
    <h2>Job History</h2>
    <div>
      <mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSource" matSort matSortStart="asc">
        <ng-container matColumnDef="id">
          <th class="w-3" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
          <td mat-cell *matCellDef="let jobHistory">{{jobHistory.id}}</td>
        </ng-container>

        <ng-container matColumnDef="statusId">
          <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let jobHistory">{{getJobStatus(jobHistory.statusId)}}</td>
        </ng-container>

        <ng-container matColumnDef="invoiceNumber">
          <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Invoice #</th>
          <td mat-cell *matCellDef="let jobHistory">{{jobHistory.invoiceNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="invoiceAmount">
          <th class="w-3" style="text-align: right;" mat-header-cell *matHeaderCellDef mat-sort-header>Amount
          </th>
          <td mat-cell *matCellDef="let jobHistory" style="text-align: right;">
            <div *ngIf="jobHistory.invoiceAmount">${{jobHistory.invoiceAmount | number : '1.2-2'}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
          <td mat-cell *matCellDef="let jobHistory" [innerHTML]="jobHistory.comments"></td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let jobHistory">{{jobHistory.date | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="addedBy">
          <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Actioned By</th>
          <td mat-cell *matCellDef="let jobHistory">{{jobHistory.addedBy}}</td>
        </ng-container>

        <ng-container matColumnDef="timeStamp">
          <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Actioned On</th>
          <td mat-cell *matCellDef="let jobHistory">{{jobHistory.timeStamp | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <ng-container matColumnDef="approveInvoice">
          <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Invoice</th>
          <td mat-cell *matCellDef="let jobHistory">
              <button
              [disabled]="!isAuthorisedToApproveInvoice() && (userEmail.toLowerCase() != 'jay.rupasinghe@mk3.com.au')"
              *ngIf="(jobHistory.statusId == 6 || jobHistory.statusId == 19) && jobHistory.originalHistoryId == null && !isInvoiceApproved(jobHistory.id) && !isInvoiceRejected(jobHistory.id)"
              mat-raised-button class="btn btn-sm btn-success" (click)="onApproveRejectClicked(jobHistory.id)">Approve/ Reject</button>
            <button [disabled]="true" *ngIf="isInvoiceApproved(jobHistory.id) && !isInvoiceRejected(jobHistory.id)"
              mat-raised-button class="btn btn-sm btn-success">Approved</button>
            <button [disabled]="true" *ngIf="isInvoiceRejected(jobHistory.id)" mat-raised-button
              class="btn btn-sm btn-danger">Rejected</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="viewDocument">
          <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>File</th>
          <td mat-cell *matCellDef="let jobHistory">
            <button *ngIf="jobHistory.jobFileId" mat-raised-button class="btn btn-sm btn-primary"
              (click)="openInvoicePDF(jobHistory.jobFileId)">PDF</button>
            <button *ngIf="jobHistory.jobId && jobHistory.statusId == 1" mat-raised-button
              class="btn btn-sm btn-primary" (click)="openInstructionPDF(jobHistory.jobId)">PDF</button>
          </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">Job details not available</td>
        </tr>
      </mat-table>
    </div>
    <div class="center-button-container" *ngIf="!isLoading">
    </div>
  </div>