import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { Email } from '@app/_models/email';
import { EmailRecord } from '@app/_models/email-record';
import { JobFile } from '@app/_models/job-file';
import { JobHistory } from '@app/_models/job-history';
import { JobHistoryView } from '@app/_models/job-history-view';
import { QualityRating } from '@app/_models/quality-rating';
import { AlertService } from '@app/_services';
import { RestService } from '@app/_services/rest.service';
import { Contractor } from '@app/_models/contractor';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import { Observable, catchError, first, map, of, switchMap } from 'rxjs';
import Swal from 'sweetalert2';
import { Employee } from '@app/_models/employee';
import { groups } from '@app/auth-config';
import { InternalTrade } from '@app/_models/internal-trade';
import { ClaimOwnerDetails } from '@app/_models/claim-owner-details';
import { FunctionsService } from '@app/_services/functions.service';
import { ADUserManager } from '@app/_models/user-manager-details-ad';
import { Success } from '@app/_helpers/constants';

interface ApprovalOptions {
  id: number;
  name: string;
}

@Component({
  selector: 'app-invoice-approval-dialog',
  templateUrl: './invoice-approval-dialog.component.html',
  styleUrls: ['./invoice-approval-dialog.component.scss'],
})
export class InvoiceApprovalDialogComponent implements OnInit {
  public form!: FormGroup;
  currentUser: string = '';
  title!: string;
  isLoading = false;
  documentTypeQuote: boolean = false;
  isInternalInstructions: boolean = false;
  description: string = '';
  companyName: string = '';
  companyEmail: string = '';
  companyPhone: string = '';
  emailRecord!: EmailRecord;
  jobHistoryView!: JobHistoryView;
  riskAddress: string = '';
  claimOwnerEmails: string = '';
  claimOwnerDetails: ClaimOwnerDetails = new ClaimOwnerDetails();
  internalTradeEmailGreeting: string = '';
  employee!: Employee;
  stateManager!: Employee;
  invoicesApprovedTotal: number = 0.0;
  isSeniorApprovalRequired: boolean = false;
  isLargeLossApprovalRequired: boolean = false;
  previousJobStatusId: number = 0;
  //isCheckedInvoice: boolean = false;
  aDUserManagerDetails!: ADUserManager;
  isChecked: boolean = false;

  @Input() max: any;

  approvalOptionSelected: number = 1;
  today = moment().toDate();

  radioButtons: ApprovalOptions[] = [
    //{ id: 1, name: 'Partial/ Progress Approval' },
    { id: 1, name: 'Approve Invoice' },
    { id: 2, name: 'Reject Invoice' },
  ];

  rateOptions = [
    { id: 5, name: '5' },
    { id: 4, name: '4' },
    { id: 3, name: '3' },
    { id: 2, name: '2' },
    { id: 1, name: '1' },
  ];

  constructor(
    private restService: RestService,
    private alertService: AlertService,
    private router: Router,
    private msalService: MsalService,
    public dialog: MatDialog,
    public functionsService: FunctionsService,
    public dialogRef: MatDialogRef<InvoiceApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      jobHistoryView: JobHistoryView;
      contractor: Contractor;
      internalTrade: InternalTrade;
      emailRecord: EmailRecord;
      userName: string;
      userEmail: string;
      userGroupsArray: string[];
    }
  ) {
    if (data) {
      this.emailRecord = data.emailRecord;
      this.jobHistoryView = data.jobHistoryView;
      this.previousJobStatusId = this.emailRecord.statusId;

      if (this.previousJobStatusId == 19) this.isSeniorApprovalRequired = true;
      else if (this.previousJobStatusId == 20)
        this.isLargeLossApprovalRequired = true;
    }
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      approvalOptions: new FormControl(1, [Validators.required]),
      invoiceNo: new FormControl(
        {
          value: this.jobHistoryView.invoiceNumber
            ? this.jobHistoryView.invoiceNumber
            : '',
          disabled: true,
        },
        [Validators.required]
      ),
      originalInvoiceAmount: new FormControl(
        this.jobHistoryView.invoiceAmount
          ? this.jobHistoryView.invoiceAmount.toFixed(2)
          : '0.00'
      ),
      invoiceAmount: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(
          /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
        ),
        Validators.max(this.jobHistoryView.invoiceAmount?this.jobHistoryView.invoiceAmount:0)
      ]),
      isFinalInvoice: new FormControl(null, [Validators.required]),
      qowRating: new FormControl(0),
      jobTimeRating: new FormControl(0),
      approveRejectComment: new FormControl('', [Validators.required]),
      ratingComments: new FormControl(''),
    });

    this.restService
      .getClaimOwnerDetails(this.data.emailRecord.refNo)
      .subscribe((result): any => {
        this.claimOwnerDetails = result;
        this.getADUserManagerDetails();
        this.currentUser=this.msalService.instance.getActiveAccount()!.username.toLowerCase();
        this.claimOwnerEmails = (result.consultantEmail && (result.consultantEmail.toLowerCase() != this.msalService.instance.getActiveAccount()!
        .username.toLowerCase()) ? result.consultantEmail + ';' : '') + (result.coordinatorEmail ? result.coordinatorEmail : '');

        this.stateManager = new Employee();
        this.getStateManagerEmailForJuniorAssessors();

        if (this.isSeniorApprovalRequired || this.isLargeLossApprovalRequired)
          this.changeSeniorValidators(true);
        else this.changeSeniorValidators(false);
      });

    this.restService
      .getInvoicesApprovedTotal(this.emailRecord.refNo)
      .subscribe((result) => {
        this.invoicesApprovedTotal = result;
      });
  }

  radioChange(event: MatRadioChange) {
    this.approvalOptionSelected = event.value;
    this.form.controls.approvalOptions.setValue(this.approvalOptionSelected);
    this.form.controls.approvalOptions.updateValueAndValidity();
    if (event.value == 1) {
      this.form.controls.invoiceAmount.setValidators([
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(
          /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
        ),
        Validators.max(this.jobHistoryView.invoiceAmount?this.jobHistoryView.invoiceAmount:0)//this.lessThanOrEqualValidator(this.form.controls.originalInvoiceAmount),
      ]);

      if (this.form.controls.isFinalInvoice.value == true)
        this.onChangeRating();
    } else {
      this.form.controls.invoiceAmount.removeValidators;
      this.form.controls.qowRating.removeValidators;
      this.form.controls.jobTimeRating.removeValidators;
      this.form.controls.ratingComments.removeValidators;

      this.form.controls.invoiceAmount.setValidators(null);
      this.form.controls.qowRating.setValidators(null);
      this.form.controls.jobTimeRating.setValidators(null);
      this.form.controls.ratingComments.setValidators(null);
    }

    if (this.isSeniorApprovalRequired || this.isLargeLossApprovalRequired)
      this.changeSeniorValidators(true);
    else this.changeSeniorValidators(false);

    this.form.controls.invoiceAmount.updateValueAndValidity();
    this.form.controls.qowRating.updateValueAndValidity();
    this.form.controls.jobTimeRating.updateValueAndValidity();
    this.form.controls.ratingComments.updateValueAndValidity();
    return;
  }

  changeSeniorValidators(isSenior: boolean) {
    if (isSenior && this.emailRecord.statusId != 6) {
      // this.form.controls.invoiceAmount.removeValidators;
      // this.form.controls.invoiceAmount.setValidators(null);
      // this.form.controls.invoiceAmount.updateValueAndValidity();

      this.form.controls.isFinalInvoice.removeValidators;
      this.form.controls.isFinalInvoice.setValidators(null);
      this.form.controls.isFinalInvoice.updateValueAndValidity();
    } else {
      if (this.approvalOptionSelected == 1) {
        this.form.controls.invoiceAmount.setValidators([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(
            /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
          ),
          Validators.max(this.jobHistoryView.invoiceAmount?this.jobHistoryView.invoiceAmount:0)
          // this.lessThanOrEqualValidator(
          //   this.form.controls.originalInvoiceAmount
          // ),
        ]);
      }
      this.form.controls.isFinalInvoice.setValidators(Validators.required);
    }
    this.form.controls.invoiceAmount.updateValueAndValidity();
    this.form.controls.isFinalInvoice.updateValueAndValidity();
  }

  onChangeIsFinalInvoice() {
    if (
      this.approvalOptionSelected == 1 &&
      this.form.controls.isFinalInvoice.value == true
    ){
      this.onChangeRating();
      this.centerDialogVertically(this.dialogRef);
    }
    else {
      //this.form.controls.invoiceAmount.removeValidators;
      this.form.controls.qowRating.removeValidators;
      this.form.controls.jobTimeRating.removeValidators;
      this.form.controls.ratingComments.removeValidators;

      //this.form.controls.invoiceAmount.setValidators(null);
      this.form.controls.qowRating.setValidators(null);
      this.form.controls.jobTimeRating.setValidators(null);
      this.form.controls.ratingComments.setValidators(null);

      //this.form.controls.invoiceAmount.updateValueAndValidity();
      this.form.controls.qowRating.updateValueAndValidity();
      this.form.controls.jobTimeRating.updateValueAndValidity();
      this.form.controls.ratingComments.updateValueAndValidity();
    }

    // const contentHeight = this.dialogRef.componentInstance.elementRef.nativeElement.offsetHeight;
    // const topPosition = Math.max(0, (window.innerHeight - contentHeight) / 2);
    // this.dialogRef.updatePosition({ top: topPosition + 'px' });

    //this.centerDialogVertically(this.dialogRef);

  }

  centerDialogVertically(dialogRef: MatDialogRef<InvoiceApprovalDialogComponent>): void {
    if (dialogRef.componentInstance/* && dialogRef.componentInstance.elementRef*/) {
      //const contentHeight = dialogRef.componentInstance.elementRef.nativeElement.offsetHeight;
      //const contentHeight = <HTMLElement>(document.getElementsByClassName("mat-dialog-container")[0])..offsetHeight;
      //const topPosition = Math.max(0, (window.innerHeight - contentHeight) / 2);
      const topPosition = 0;
      dialogRef.updatePosition({ top: topPosition + 'px' });
    } else {
      console.error('Component or elementRef is undefined.');
    }
  }

  onChangeRating() {
    if (this.approvalOptionSelected == 1) {
      if (this.form.controls.jobTimeRating.value > 0) {
        this.form.controls.qowRating.removeValidators;
        this.form.controls.qowRating.setValidators(null);
      } else {
        this.form.controls.qowRating.setValidators([
          Validators.required,
          Validators.min(1),
        ]);
      }
      if (this.form.controls.qowRating.value > 0) {
        this.form.controls.jobTimeRating.removeValidators;
        this.form.controls.jobTimeRating.setValidators(null);
      } else {
        this.form.controls.jobTimeRating.setValidators([
          Validators.required,
          Validators.min(1),
        ]);
      }
      this.form.controls.ratingComments.setValidators(Validators.required);
    } else {
      this.form.controls.qowRating.removeValidators;
      this.form.controls.jobTimeRating.removeValidators;
      this.form.controls.ratingComments.removeValidators;

      this.form.controls.qowRating.setValidators(null);
      this.form.controls.jobTimeRating.setValidators(null);
      this.form.controls.ratingComments.setValidators(null);
    }

    this.form.controls.qowRating.updateValueAndValidity();
    this.form.controls.jobTimeRating.updateValueAndValidity();
    this.form.controls.ratingComments.updateValueAndValidity();
  }

  getCompany(instructionTypeId: number | null): string {
    switch (instructionTypeId) {
      case 1:
        return 'NIB';
      case 3:
        return 'RR';
      case 5:
        return 'Mk3';
    }
    return '';
  }

  isInvoiceRejected(): Observable<boolean> {
    return this.restService
      .getJobHistoryByOriginalHistoryId(this.jobHistoryView.id /*12273*/)
      .pipe(
        switchMap((data: JobHistoryView[]) => {
          const isRejected = data.some(
            (element) => element.statusId === 15 || element.statusId === 17
          );
          return of(isRejected);
        }),
        catchError((err: Error) => {
          Swal.fire(
            'Error getting the status of the instruction:' + err,
            '',
            'error'
          );
          return of(false); // or return Observable.throw(err); if you want to propagate the error
        })
      );
  }

  public async onSubmit(approvalOptionSelected: number) {
    this.isLoading = true;
    let approveRejectComment: string = '';
    let jobStatusId: number;
    let emailSubject: string = this.emailRecord.refNo.toString() + ' - ';

    switch (approvalOptionSelected) {
      case 1:
        if (this.form.controls.isFinalInvoice.value == true) {
          if (this.isSeniorApprovalRequired)
            jobStatusId = this.previousJobStatusId != 19 ? 19 : 18;
          //Final Invoice Approved (Require Secondary)
          else if (this.isLargeLossApprovalRequired)
            jobStatusId = this.previousJobStatusId != 20 ? 20 : 18;
          //Final Invoice Approved (Large Loss)
          else jobStatusId = 18; //Final Invoice Approved

          approveRejectComment =
            (this.isSeniorApprovalRequired &&
            !(this.previousJobStatusId == 19 || this.previousJobStatusId == 20)
              ? '<b>Approved Final (Require Secondary) Invoice #: </b>'
              : '<b>Approved Final Invoice #:</b> ') +
            this.form.controls.invoiceNo.value +
            '<br/><b>Amount Approved:</b> $' +
            (this.form.controls.invoiceAmount.value != ''
              ? this.form.controls.invoiceAmount.value
              : this.form.controls.originalInvoiceAmount.value) +
            '<br/><b>Approved By:</b> ' +
            this.msalService.instance.getActiveAccount()!.name +
            '<br/><b>Comments:</b> ' +
            this.form.controls.approveRejectComment.value;

          emailSubject +=
            (this.isSeniorApprovalRequired &&
            !(this.previousJobStatusId == 19 || this.previousJobStatusId == 20)
              ? 'Approved Final (Require Secondary) Invoice #: '
              : 'Approved Final Invoice #: ') +
            this.form.controls.invoiceNo.value +
            ', Amount Approved: $' +
            (this.form.controls.invoiceAmount.value != ''
              ? this.form.controls.invoiceAmount.value
              : this.form.controls.originalInvoiceAmount.value);
        } else {
          if (this.isSeniorApprovalRequired)
            jobStatusId = this.previousJobStatusId != 19 ? 19 : 14;
          //Invoice Approved (Require Secondary)
          else if (this.isLargeLossApprovalRequired)
            jobStatusId = this.previousJobStatusId != 20 ? 20 : 14;
          //Invoice Approved (Large Loss)
          else jobStatusId = 14; //Invoice Approved

          approveRejectComment =
            (this.isSeniorApprovalRequired &&
            !(this.previousJobStatusId == 19 || this.previousJobStatusId == 20)
              ? '<b>Approved (Require Secondary) Invoice #: </b>'
              : '<b>Approved Invoice #:</b> ') +
            this.form.controls.invoiceNo.value +
            '<br/><b>Amount Approved:</b> $' +
            (this.form.controls.invoiceAmount.value != ''
              ? this.form.controls.invoiceAmount.value
              : this.form.controls.originalInvoiceAmount.value) +
            '<br/><b>Approved By:</b> ' +
            this.msalService.instance.getActiveAccount()!.name +
            '<br/><b>Comments:</b> ' +
            this.form.controls.approveRejectComment.value;

          emailSubject +=
            (this.isSeniorApprovalRequired &&
            !(this.previousJobStatusId == 19 || this.previousJobStatusId == 20)
              ? 'Approved (Require Secondary) Invoice #: '
              : 'Approved Invoice #: ') +
            this.form.controls.invoiceNo.value +
            ', Amount Approved: $' +
            (this.form.controls.invoiceAmount.value != ''
              ? this.form.controls.invoiceAmount.value
              : this.form.controls.originalInvoiceAmount.value);
        }
        break;
      case 2: //Invoice Rejected
        jobStatusId = 15;
        approveRejectComment =
          '<b>Rejected Invoice #:</b> ' +
          this.form.controls.invoiceNo.value +
          '<br/><b>Rejected By:</b> ' +
          this.msalService.instance.getActiveAccount()!.name +
          '<br/><b>Comments:</b> ' +
          this.form.controls.approveRejectComment.value;

        emailSubject +=
          'Rejected Invoice #: ' + this.form.controls.invoiceNo.value;
        break;
    }
    this.restService.getEmailRecordStatusForJobId(this.emailRecord.id).subscribe(x=>{
      let resultMessage=x.message;
      let currentStatus = x.response;
      if(resultMessage == Success  && this.previousJobStatusId == currentStatus)
        {
    let swalTitle: string =
      'Are you sure you want to ' +
      (approvalOptionSelected == 1 ? 'Approve' : 'Reject') +
      ' this Invoice?';
      if(this.form.controls.isFinalInvoice.value && approvalOptionSelected ==1)
      {
        swalTitle = "This will close the instruction as no more invoices are expected – is this the final invoice Yes/No?"
      }
    Swal.fire({
      title: swalTitle, //'Are you sure you want to Approve/ Reject this Invoice?',
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: '#007bff',
      denyButtonColor: '#dc3545',
      focusDeny: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      preConfirm: (retVal) => {
        if (!retVal) {
          Swal.showValidationMessage(
            '<i class="fa fa-info-circle"></i> You need to select a Status'
          );
        } else {
          return retVal;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.isInvoiceRejected().subscribe(
          (result: boolean) => {
            if (result) {
              Swal.fire(
                'This instruction has been rejected!',
                '',
                'error'
              ).then(() => {
                if (this.emailRecord.id) {
                  this.router
                    .navigate(['../job-details/' + this.emailRecord.id])
                    .then(() => {
                      window.location.reload();
                    });
                }
              });
              this.isLoading = false;
              return;
            } else {
              this.approveRejectInvoice(
                approveRejectComment,
                jobStatusId,
                emailSubject,
                approvalOptionSelected
              );
            }
          },
          (error) => {
            Swal.fire(
              'Error getting the status of the instruction:' + error,
              '',
              'error'
            );
          }
        );
      } else {
        this.isLoading = false;
      }
    });
  }
  else{
    Swal.fire(
      'Cannot Approve/Reject now. The job status got changed. Try again after refresh.',
      '',
      'warning'
    ).then(()=>{
      window.location.reload();
    })
      }
    });
  }

  getSeniorOrLLEmails(): string {
    let email: string = '';
    if (this.isSeniorApprovalRequired) {
      {
        if (this.aDUserManagerDetails.reviewerId != null)
          email = this.aDUserManagerDetails.reviewerEmail
            ? this.aDUserManagerDetails.reviewerEmail
            : '';
        else email = this.stateManager.email;
      }
    } else if (this.isLargeLossApprovalRequired) {
      email = 'jonathan.shelley@mk3.com.au';
    }
    return email;
  }

  approveRejectInvoice(
    approveRejectComment: string,
    jobStatusId: number,
    emailSubject: string,
    approvalOptionSelected: number
  ) {
    try {
      if (
        (this.previousJobStatusId == 19 || this.previousJobStatusId == 20) &&
        !(jobStatusId == 15 || jobStatusId == 17)
      ) {
        jobStatusId = 14;
      }

      let jobHistoryNewRecord = new JobHistory();
      let emailRecord = new EmailRecord();
      emailRecord.id = this.emailRecord.id;
      emailRecord.statusId = jobStatusId;

      jobHistoryNewRecord.jobId = this.emailRecord.id;
      jobHistoryNewRecord.date = null;//new Date();
      jobHistoryNewRecord.addedBy =
        this.msalService.instance.getActiveAccount()!.name!;
      jobHistoryNewRecord.timeStamp = null;
      jobHistoryNewRecord.statusId = jobStatusId;
      jobHistoryNewRecord.invoiceNumber = this.form.controls.invoiceNo.value;
      jobHistoryNewRecord.invoiceAmount =
        this.form.controls.invoiceAmount.value != ''
          ? this.form.controls.invoiceAmount.value
          : this.form.controls.originalInvoiceAmount.value;
      jobHistoryNewRecord.originalHistoryId = this.jobHistoryView.id;
      jobHistoryNewRecord.isFinalInvoice =
        this.form.controls.isFinalInvoice.value != null
          ? this.form.controls.isFinalInvoice.value
          : this.jobHistoryView.isFinalInvoice;
      jobHistoryNewRecord.comments = approveRejectComment;

      //Large Loss function disabled on 07/12/2023 as per the equest by Teresa

      /*if (
        (jobHistoryNewRecord.invoiceAmount! >= 20000 ||
          this.invoicesApprovedTotal +
            Number(jobHistoryNewRecord.invoiceAmount) >=
            30000) &&
        !(this.emailRecord.statusId == 15 || this.emailRecord.statusId == 17) &&
        this.previousJobStatusId != 20
      ) {
        this.isLargeLossApprovalRequired = true;
        jobHistoryNewRecord.statusId = 20;
        jobStatusId = 20;
      }*/

      if (this.jobHistoryView.jobFileId) {
        this.restService.getInvoicePDF(this.jobHistoryView.jobFileId).subscribe({
          next: (data: JobFile) => {
            this.restService
              .updateEmailRecord(this.emailRecord.id, jobStatusId, emailRecord)
              .pipe(first())
              .subscribe({
                next: () => {
                  this.restService
                    .addNewJobHistoryRecord(jobHistoryNewRecord)
                    .subscribe({
                      next: () => {
                        var email: Email;
                        email = {
                          from: 'trades@mk3.com.au',
                          to:
                            (!(jobStatusId == 19 || jobStatusId == 20)
                              ? 'accounts@mk3.com.au'
                              : '') +
                            (this.getSeniorOrLLEmails()
                              ? ';' + this.getSeniorOrLLEmails()
                              : ''),
                          cc:
                            /*this.msalService.instance.getActiveAccount()!
                              .username +
                            ';' +*/
                            ((approvalOptionSelected !=1)?this.claimOwnerEmails:null),
                          bcc: 'test@mk3.com.au',
                          subject: '',
                          body:
                            approveRejectComment +
                            '<br/><b>RefNo:</b> <a href=https://portal.mk3apps.com.au/case-details/' +
                            this.emailRecord.refNo +
                            '>' +
                            this.emailRecord.refNo +
                            '</a>' +
                            (this.data.contractor
                              ? '<br/><b>Contractor:</b> <a href=https://contractors.mk3apps.com.au/contractor-details/' +
                                this.data.contractor.contractorId
                              : '<br/><b>Internal Trade:</b> <a href=https://contractors.mk3apps.com.au/internal-trade-details/' +
                                this.data.internalTrade.employeeId) +
                            '>' +
                            (this.data.contractor
                              ? this.data.contractor.tradingName
                              : this.data.internalTrade.displayName) +
                            '</a>' +
                            '<br/><b>Company:</b> ' +
                            this.getCompany(
                              this.jobHistoryView.instructionTypeId
                            ) +
                            '<br/><br/>Please see the <a href=https://contractors.mk3apps.com.au/job-details/' +
                            this.emailRecord.id +
                            ' target="_blank">details here.</a>',
                          attachmentName: !data.fileName ? null : data.fileName,
                          attachmentContent: !data.fileData
                            ? null
                            : data.fileData
                                ?.toString()
                                .replace('data:', '')
                                .replace(/^.+,/, ''),
                          extraAttachmentName: null,
                          extraAttachmentContent:null,
                        };

                        let alertMessage: string = '';
                        switch (jobStatusId) {
                          case 14:
                            alertMessage = 'Invoice Approved!';
                            break;
                          case 15:
                            alertMessage = 'Invoice Rejected!';
                            break;
                          case 17:
                            alertMessage = 'Invoice Rejected by Finance!';
                            break;
                          case 18:
                            alertMessage =
                              'Invoice Approved and the Contractor has been rated!';
                            break;
                          case 19:
                            alertMessage =
                              'Invoice Approved and Awaiting Senior Approval!';
                            break;
                          case 20:
                            alertMessage =
                              'Invoice Approved and Awaiting Large Loss Approval!';
                            break;
                        }

                        if (
                          jobStatusId == 14 ||
                          jobStatusId == 18 ||
                          jobStatusId == 19 ||
                          jobStatusId == 20
                        ) {
                          if (this.form.controls.isFinalInvoice.value == true) {
                            //Final Invoice Approved
                            alertMessage = 'Final ' + alertMessage;
                            let qualityRating = new QualityRating();
                            qualityRating.contractorId = this.emailRecord
                              .contractorId
                              ? this.emailRecord.contractorId
                              : null;
                            qualityRating.jobId = this.emailRecord.id;
                            qualityRating.qoWscore =
                              this.form.controls.qowRating.value;
                            qualityRating.jobTimeScore =
                              this.form.controls.jobTimeRating.value;
                            qualityRating.comments =
                              this.form.controls.ratingComments.value;
                            qualityRating.addedBy =
                              this.msalService.instance.getActiveAccount()!.name!;

                            jobHistoryNewRecord.statusId = 7;
                            jobHistoryNewRecord.comments = 'Contractor Rated';
                            this.restService
                              .addNewJobHistoryRecord(jobHistoryNewRecord)
                              .subscribe();

                            this.restService
                              .addNewQualityRating(qualityRating)
                              .subscribe((x) => {
                                Swal.fire(alertMessage, '', 'success').then(
                                  () => {
                                    if (this.emailRecord.id) {
                                      this.router
                                        .navigate([
                                          '../job-details/' +
                                            this.emailRecord.id,
                                        ])
                                        .then(() => {
                                          window.location.reload();
                                        });
                                    }
                                  }
                                );
                              });
                          } else {
                            //Invoice Approved
                            Swal.fire(alertMessage, '', 'success').then(() => {
                              if (this.emailRecord.id) {
                                this.router
                                  .navigate([
                                    '../job-details/' + this.emailRecord.id,
                                  ])
                                  .then(() => {
                                    window.location.reload();
                                  });
                              }
                            });
                          }
                        } else if (jobStatusId == 15) {
                          //Invoice Rejected
                          Swal.fire(alertMessage, '', 'success').then(() => {
                            if (this.emailRecord.id) {
                              this.router
                                .navigate([
                                  '../job-details/' + this.emailRecord.id,
                                ])
                                .then(() => {
                                  window.location.reload();
                                });
                            }
                          });
                        }
                        email.subject = emailSubject;

                        this.restService.sendEmail(email, 'Email Type:Approve Invoice'+ ', User Name:' + this.msalService.instance.getActiveAccount()!.name! 
                                                +', ApproveInvoice No: ' + jobHistoryNewRecord.invoiceNumber+', RefNo:'+ emailRecord.refNo?.toString()
                                                +', Contractor:'+(this.data.contractor? this.data.contractor.tradingName: this.data.internalTrade.displayName)
                                                +', ContractorId:'+(this.data.contractor? this.data.contractor.contractorId.toString(): this.data.internalTrade.employeeId.toString())
                                                +', JobHistoryId:'+jobHistoryNewRecord.id.toString()+', EmailRecordId:'+emailRecord.id.toString()).subscribe({
                          next: () => {},
                          error: (err: any) => {
                            Swal.fire(
                              'Error (Invoice Approval): restService.sendEmail: ' +
                                err,
                              '',
                              'error'
                            );
                            console.log(
                              'Error (Invoice Approval): restService.sendEmail: ' +
                                err
                            );
                          },
                        });
                      },
                      error: (err) => {
                        // Handle error
                        Swal.fire(
                          'Error: restService.addNewJobHistoryRecord<br/>' +
                            err,
                          '',
                          'error'
                        );
                        this.isLoading = false;
                      },
                    });
                },
                error: (err) => {
                  Swal.fire(
                    'Error: restService.updateEmailRecord<br/>' + err,
                    '',
                    'error'
                  );
                  this.isLoading = false;
                },
              });
          },
          error: (err) => {
            Swal.fire('Error: restService.getInvoicePDF<br/>' + err, '', 'error');
          },
        });
      } else {
        Swal.fire(
          'Invoice Not Found!',
          'Please contact the Mk3 Accounts Department or the IT Helpdesk',
          'error'
        ).then(() => {
          if (this.emailRecord.id) {
            this.router
              .navigate(['../job-details/' + this.emailRecord.id])
              .then(() => {
                window.location.reload();
              });
          }
        });
      }
    } catch (err) {
      Swal.fire(
        'Error: restService.approveRejectInvoice<br/>' + err,
        '',
        'error'
      );
      this.isLoading = false;
    }
  }

  getStateManagerEmailForJuniorAssessors(): string {
    let stateManagerEmail: string = '';
    if (this.data.userGroupsArray.includes(groups.groupAssessorLevel1)) {
      this.restService
        .getEmployeeDetailsByEmail(
          this.claimOwnerDetails.consultantEmail
        )
        .subscribe({
          next: (employee: Employee) => {
            this.restService
              .getStateManagerDetails(
                employee.workStateId ? employee.workStateId : 0
              )
              .subscribe({
                next: (stateManager: Employee) => {
                  this.restService
                    .getADUserManagerDetails(
                      this.claimOwnerDetails.consultantID
                    )
                    .subscribe((userDetails) => {
                      if (userDetails[0].reviewerId != null) {
                        this.restService
                          .getEmployeeDetailsByEmail(userDetails[0].reviewerEmail)
                          .subscribe({
                            next: (approver: Employee) => {
                              this.stateManager = approver;
                              stateManagerEmail = approver.email;
                            },
                            error: (err) => {
                              Swal.fire(
                                'Error: restService.getEmployeeDetailsByEmail in getStateManagerEmailForJuniorAssessors<br/>' +
                                  err,
                                '',
                                'error'
                              );
                            },
                          });
                      } else if (employee.workStateId == 6) {
                        //SA
                        let sAStateManager = new Employee();
                        sAStateManager.employeeId = 5; //11;
                        sAStateManager.employeeName = 'Paul Vaughn';
                        sAStateManager.email = 'paul.vaughn@mk3.com.au';//'John.Kontalipos@mk3.com.au';
                        sAStateManager.workStateId = 6;
                        this.stateManager = sAStateManager;
                        stateManagerEmail = sAStateManager.email;
                      } else {
                        this.stateManager = stateManager;
                        stateManagerEmail = stateManager.email;
                      }

                      this.isSeniorApprovalRequired = true;

                      if (this.isSeniorApprovalRequired || this.isLargeLossApprovalRequired)
                        this.changeSeniorValidators(true);
                      else 
                      this.changeSeniorValidators(false);
                    });
                },
                error: (err) => {
                  Swal.fire(
                    'Error: restService.getStateManagerDetails<br/>' + err,
                    '',
                    'error'
                  );
                },
              });
          },
          error: (err) => {
            Swal.fire(
              'Error: restService.getEmployeeDetailsByEmail<br/>' + err,
              '',
              'error'
            );
          },
        });
    }
    return stateManagerEmail;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  // lessThanOrEqualValidator(otherControl: AbstractControl) {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     const selfValue = control.value;
  //     const otherValue = otherControl.value;
      
  //     if (Number(selfValue) > Number(otherValue)) {
  //       return { lessThanOrEqual: true };
  //     }
  //     return null;
  //   };
  // }

  openInvoicePDF(id: number) {
    this.restService.getJobHistoryView(id).subscribe((data) => {
      if (data.jobFileId) {
        this.restService.getInvoicePDF(data.jobFileId).subscribe(
          (data: JobFile) => {
            this.functionsService.openPdf(data.fileData);
          },
          (error) => {
            this.alertService.error(error);
          }
        );
      }
    });
  }

  getADUserManagerDetails() {
    return this.restService
      .getADUserManagerDetails(this.claimOwnerDetails.consultantID)
      .subscribe((userDetails) => {
        this.aDUserManagerDetails = userDetails;
      });
  }
}
