export class ClaimOwnerDetails { 
    refNo!: number;
    consultantID!: number;
    consultant!: string;    
    consultantEmail!: string;
    coordinatorID!: number;
    coordinator!: string;
    coordinatorEmail!: string;
    lastName!: string;
    isAssessorExternal!: number;
}