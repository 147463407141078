import { Injectable } from "@angular/core"; 
import { MsalService } from '@azure/msal-angular';
import { Subject } from "rxjs";
import { AppComponent } from "@app/app.component";
import { groups } from "@app/auth-config";

@Injectable({
    providedIn:'root'
})

export class GlobalValues{

    isDescriptionDDLCloseOnSelect:boolean = true;

    userInGroupList = this.msalService.instance.getAllAccounts()[0].idTokenClaims;
    userEmailId = this.msalService.instance.getActiveAccount()!.username! ;
    userName = this.msalService.instance.getActiveAccount()!.name!;
    userGroupsArray : Array<string> = [];
    accessToken : any;
    globalVariableSubject  = new Subject<any>();
    

constructor(private msalService: MsalService 
  ){
      this.msalService.instance.acquireTokenSilent({
        scopes: ['https://graph.microsoft.com/.default']
      }).then(response => {
        this.accessToken = response.accessToken;
        // You now have an access token to call the Microsoft Graph API.
        this.globalVariableSubject.next(true)
      }).catch(error => {
        console.error('Error acquiring token:', error);
      });
    (this.userInGroupList as any).groups.forEach((group: any) => {
      this.userGroupsArray.push(group);
    });
  }
}