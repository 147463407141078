<div class="main-container">
  <!-- CSS loading animation container -->
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <h1>Internal Trade Details</h1>
  <div class="contractor-details-container mat-elevation-z8  bg-light" style="vertical-align: top;">
    <mat-grid-list class="grid-tile-content" cols="4">
      <mat-grid-tile class="grid-tile"></mat-grid-tile>
      <mat-grid-tile class="grid-tile">
        <mat-list>
          <mat-list-item class="list-key-column"> Employee ID </mat-list-item>
          <mat-list-item class="list-key-column"> Name </mat-list-item>
          <mat-list-item class="list-key-column"> Title </mat-list-item>
          <mat-list-item class="list-key-column"> Mobile </mat-list-item>
          <mat-list-item class="list-key-column"> Email </mat-list-item>
          <mat-list-item class="list-key-column"> State </mat-list-item>
        </mat-list>
      </mat-grid-tile>
      <mat-grid-tile class="grid-tile">
        <mat-list>
          <mat-list-item class="list-value-column" *ngIf="internalTrade"> {{internalTrade.employeeId}}</mat-list-item>
          <mat-list-item class="list-value-column green-text" *ngIf="internalTrade"> {{internalTrade.displayName}} </mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="internalTrade"> {{internalTrade.title}} </mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="internalTrade"> <a href="tel:{{internalTrade.mobile}}">{{internalTrade.mobile}}</a>
          </mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="internalTrade"> <a href="mailto:{{internalTrade.mail}}">{{internalTrade.mail}}</a>
          </mat-list-item>
          <mat-list-item class="list-value-column" *ngIf="internalTrade"> {{internalTrade.state}} </mat-list-item>
        </mat-list>
      </mat-grid-tile>
      <mat-grid-tile class="grid-tile"></mat-grid-tile>
    </mat-grid-list>
    <h2>Instructions</h2>

    <div class="search-box-container">
      <mat-form-field class="search-box-form-field">
        <mat-label>Search by Keyword</mat-label>
        <input #searchAll matInput type="text" (keyup)="onSearchKeyUp(searchAll)"
        [value]="searchValue" autoFocus>
      <button mat-button matSuffix mat-icon-button aria-label="Clear"
        (click)="onClearClicked(searchAll)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div>
      <mat-table class="mat-elevation-z8 table-striped" [dataSource]="dataSourceEmailRecords" matSort
        matSortStart="asc">
        <ng-container matColumnDef="refNo">
          <th class="w-3" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Ref No</th>
          <td mat-cell *matCellDef="let emailRecords">{{emailRecords.refNo}}</td>
        </ng-container>

        <ng-container matColumnDef="instructionTypeId">
          <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Instruction Type</th>
          <td mat-cell *matCellDef="let instructionTypes">
            <div *ngIf="instructionTypes.instructionTypeId == 1">NIB Instructions</div>
            <div *ngIf="instructionTypes.instructionTypeId == 2">NIB Quote Request</div>
            <div *ngIf="instructionTypes.instructionTypeId == 3">RR Instructions</div>
            <div *ngIf="instructionTypes.instructionTypeId == 4">RR Quote Request</div>
            <div *ngIf="instructionTypes.instructionTypeId == 5">Mk3 Instructions</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="attachmentName">
          <th class="w-20" style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Attachment Name
          </th>
          <td mat-cell *matCellDef="let emailRecords">{{emailRecords.attachmentName}}</td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell *matCellDef="let emailRecords">{{emailRecords.startDate | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th class="w-1" mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
          <td mat-cell *matCellDef="let emailRecords">{{emailRecords.endDate | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="sentBy">
          <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Sent By</th>
          <td mat-cell *matCellDef="let emailRecords">{{emailRecords.sentBy}}</td>
        </ng-container>

        <ng-container matColumnDef="jobCost">
          <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Job Cost</th>
          <td mat-cell style="text-align: right;" *matCellDef="let emailRecords"><span *ngIf="emailRecords.jobCost">
              $
            </span>{{emailRecords.jobCost | number : '1.2-2'}}</td>
        </ng-container>

        <ng-container matColumnDef="statusId">
          <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Job Status</th>
          <td mat-cell *matCellDef="let emailRecords">{{getJobStatus(emailRecords.statusId)}}</td>
        </ng-container>

        <ng-container matColumnDef="dateSent">
          <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Date/ Time</th>
          <td mat-cell *matCellDef="let emailRecords">{{emailRecords.dateSent | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th class="w-0" mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
            <button mat-raised-button class="btn btn-sm btn-success"
              [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
              (click)="jobDetails(emailRecords.id)">Details</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="viewDocument">
          <th class="w-0" mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let emailRecords">
              <button style="width: fit-content;" mat-raised-button class="btn btn-sm btn-primary"
                  (click)="openInstructionPDF(emailRecords.id)">PDF</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="retract">
          <th class="w-0" mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell style="white-space: nowrap;" *matCellDef="let emailRecords">
            <!-- <button mat-raised-button class="btn btn-sm btn-danger" [disabled]="emailRecords.retracted"
              [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
              [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId >= 5))"
              (click)="retract(emailRecords.id)">
              <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
              <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId < 5)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
              </div>
              <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId >= 5)" class="btn-disabled">
                &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
            </button> -->
            <button mat-raised-button class="btn btn-sm btn-danger"
            [hidden]="(emailRecords.instructionTypeId == 2 || emailRecords.instructionTypeId == 4)"
            [disabled]="(emailRecords.retracted) || ((!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10))"
            (click)="retract(emailRecords.id)">
            <div *ngIf="emailRecords.retracted" class="btn-disabled">Retracted</div>
            <div *ngIf="(!emailRecords.retracted) && !(emailRecords.statusId == 9 || emailRecords.statusId == 10)">&nbsp;&nbsp;Retract&nbsp;&nbsp;
            </div>
            <div *ngIf="(!emailRecords.retracted) && (emailRecords.statusId == 9 || emailRecords.statusId == 10)" class="btn-disabled">
              &nbsp;&nbsp;Retract&nbsp;&nbsp;</div>
          </button>
            &nbsp;&nbsp;
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedEmailRecordsColumns"></tr>
        <tr mat-row class="row-hover mat-row" *matRowDef="let row; columns: displayedEmailRecordsColumns;"
          [ngClass]="{'highlight-table-row': row.retracted == '1'}"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            No Instructions available for this Internal Trade
          </td>
        </tr>
      </mat-table>
    </div>
    <div class="center-button-container" *ngIf="!isLoading">
      <button mat-raised-button class="btn btn-sm btn-primary" (click)=newWorkInstructions()>Send Work
        Instructions</button>&nbsp;&nbsp;
      <a class="btn btn-link" routerLink="/internal-trades">Back</a>
    </div>
  </div>
</div>