<h1 mat-dialog-title style="color: white; background-color:var(--nibblue); text-align: center;">Select Internal Trades
    to add</h1>
  
  <form autocomplete="off" novalidate fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
    <mat-dialog-content text-align="middle" style="width: 100%; max-height: 550px;">
      <div class="mat-eleation-z8">
        <table mat-table [dataSource]="dataSourceInternalTrades" matSort class="mat-elevation-z8 table-striped"
          style="width: 100%;">
  
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th class="w-2" mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="employeeId">
            <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Employee ID</th>
            <td mat-cell *matCellDef="let internalTrades">{{internalTrades.employeeId}}</td>
          </ng-container>
          <ng-container matColumnDef="displayName">
            <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let internalTrades">{{internalTrades.displayName}}</td>
          </ng-container>
          <ng-container matColumnDef="mail">
            <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let internalTrades">{{internalTrades.mail}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
  
        </table>
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="true" class="btn mat-raised-button btn-sm btn-primary" (click)="onInsertToSoW()"
        [disabled]="this.selection.selected.length == 0">Insert to Scope of Works</button>
      <button mat-button [mat-dialog-close]="true" mat-dialog-close type="button" mat-raised-button
        class="btn mat-button-base" (click)="onCancel()">Cancel</button>
    </mat-dialog-actions>
  </form>
  