import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { EmailRecord } from '@app/_models/email-record';
import { JobFile } from '@app/_models/job-file';
import { JobHistory } from '@app/_models/job-history';
import { RestService } from '@app/_services/rest.service';
import { MsalService } from '@azure/msal-angular';
import { AsyncSubject, Observable, Subscription, catchError, first, map } from 'rxjs';
import Swal from 'sweetalert2';
import { InvoiceApprovalDialogComponent } from '../invoice-approval-dialog/invoice-approval-dialog.component';
import { Email } from '@app/_models/email';
import { Contractor } from '@app/_models/contractor';
import { AlertService } from '@app/_services';
import { InternalTrade } from '@app/_models/internal-trade';
import { ErrorService } from '@app/_services/error.service';
import { Employee } from '@app/_models/employee';

export interface SelectedFiles {
  name: string;
  file: any;
  base64?: string;
}

@Component({
  selector: 'app-invoice-upload-dialog',
  templateUrl: './invoice-upload-dialog.component.html',
  styleUrls: ['./invoice-upload-dialog.component.scss'],
})
export class InvoiceUploadDialogComponent implements OnInit {
  userName: string = '';
  public form!: FormGroup;
  emailRecord!: EmailRecord;
  isLoading = false;
  isLevelZeroAssessor:boolean = false;
  fileReader = new FileReader();
  uploadProgress: number | null = 0;
  uploadSub: Subscription | null = new Subscription();
  fileName = '';
  file: File | null = null;
  base64Output!: string;
  public selectedFiles: SelectedFiles[] = [];
  private subscription: Subscription | undefined;
  claimOwnerEmails: string = '';
  isChecked: boolean = false;
  pdfSrc = '';

  constructor(
    private restService: RestService,
    private errorService: ErrorService,
    private alertService: AlertService,
    private msalService: MsalService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InvoiceApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contractor: Contractor;
      internalTrade: InternalTrade;
      emailRecord: EmailRecord;
      userName: string;
      userEmail: string;
    }
  ) {
    if (data) {
      this.emailRecord = data.emailRecord;
    }

    this.userName = this.msalService.instance.getActiveAccount()!.name!
      ? this.msalService.instance.getActiveAccount()!.name!
      : this.data.userName;

      this.restService
      .getClaimOwnerDetails(this.data.emailRecord.refNo)
      .subscribe((result): any => {

        var storedJsonString = localStorage.getItem('levelZeroAssessor');
        var storedList = JSON.parse(storedJsonString!);
          let data = storedList;
          if(data)
          {
          let isLevelZero =false;
          this.isLevelZeroAssessor =false;
           data.map((item:any) =>{
            if(item.mail.toLowerCase() == result.consultantEmail.toLowerCase())
            {
              isLevelZero = true;
            }
          })
          this.isLevelZeroAssessor = isLevelZero;
          if(this.isLevelZeroAssessor)
          {
            this.getStateManager(result.consultantEmail).subscribe(email =>{
              this.claimOwnerEmails =
              (email ? email + ';' : '') +
              (result.coordinatorEmail ? result.coordinatorEmail : '');
            });
          }
          else if (!result.isAssessorExternal) {
            this.claimOwnerEmails =
              (result.consultantEmail ? result.consultantEmail + ';' : '') +
              (result.coordinatorEmail ? result.coordinatorEmail : '');
          } else {
            this.claimOwnerEmails =
              (result.coordinatorEmail ? result.coordinatorEmail : '');
          }
        }
        else{
          Swal.fire(
            'Please sign out and sign back into the Contractors app for invoice upload!',
            'There is an Authorization issue',
            'error'
          ).then( result => {
            this.dialogRef.close();
          });
        }
      });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      invoiceNo: new FormControl('', [Validators.required]),
      invoiceAmount: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(
          /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
        ),
      ]),
      invoiceFile: new FormControl('', [Validators.required]),
      invoiceComment: new FormControl('', [Validators.maxLength(499)]),
    });
  }
  getStateManager(email: string): Observable<string> {
    return this.restService.GetStateManagerForEmployeeEmail(email).pipe(
      map((data: Employee) => {
        return data ? data.email : '';
      }),
      catchError((error) => {
        this.alertService.error(error);
        return new Observable<string>(); // Return an empty observable or handle the error differently.
      })
    );
  }

  public toFilesBase64(
    files: File[],
    selectedFiles: SelectedFiles[]
  ): Observable<SelectedFiles[]> {
    const result = new AsyncSubject<SelectedFiles[]>();
    if (files?.length) {
      Object.keys(files)?.forEach(async (file, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          selectedFiles = selectedFiles?.filter(
            (f) => f?.name != files[i]?.name
          );
          selectedFiles.push({
            name: files[i]?.name,
            file: files[i],
            base64: reader?.result as string,
          });
          result.next(selectedFiles);
          if (files?.length === i + 1) {
            result.complete();
          }
        };
      });
      return result;
    } else {
      result.next([]);
      result.complete();
      return result;
    }
  }

  onFileInput(files: FileList | null): void {
    if (files) {
      this.file = files.item(0);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onFileSelected(event: any) {
    if (event.target && event.target.files.length>0 && event.target.files[0].name.split('.').pop().toLowerCase() == 'pdf') {
      //remove any previous files from the array
      this.selectedFiles.splice(0, this.selectedFiles.length);

      this.toFilesBase64(
        Array.from(event.target.files),
        this.selectedFiles
      ).subscribe((res: SelectedFiles[]) => {
        this.selectedFiles = res;
        this.pdfSrc = this.selectedFiles[0].base64!;
      });
    } else if(event.target && event.target.files.length>0){
      this.pdfSrc='';
      this.form.controls.invoiceFile.setValue(null);
      Swal.fire(
        'Only PDF files please!',
        'Sorry, you can only upload pdf invoices here. Please convert your invoice to a pdf and upload. <br/><br/>Nice try though... :)',
        'error'
      );
    }
    else{
      this.pdfSrc='';
    }
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }

  public async onSubmit() {
    if((this.data.contractor && this.data.contractor.contractorId)  || (this.data.internalTrade && this.data.internalTrade.employeeId))
  {
    this.isLoading = true;
    Swal.fire({
      title: 'Are you sure you want to Upload this Invoice?',
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: '#007bff',
      denyButtonColor: '#dc3545',
      focusDeny: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      preConfirm: (retVal) => {
        if (!retVal) {
          Swal.showValidationMessage(
            '<i class="fa fa-info-circle"></i> You need to select a Status'
          );
        } else {
          return retVal;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.selectedFiles.length > 0) {
          let base64String: string | null = '';
          base64String = this.selectedFiles[0].base64
            ? this.selectedFiles[0].base64
                ?.toString()
                .replace('data:', '')
                .replace(/^.+,/, '')
            : '';

          if (base64String == '' || base64String == 'null') {
            Swal.fire(
              'Failed!',
              'Upload failed. Invalid or corrupted file!',
              'error'
            );
          } else {
            this.isLoading = true;
            try {
              let jobHistoryNewRecord = new JobHistory();
              jobHistoryNewRecord.jobId = this.emailRecord.id;
              jobHistoryNewRecord.statusId = this.isLevelZeroAssessor?19:6;
              jobHistoryNewRecord.comments = this.form.controls.invoiceComment.value??'Invoice Received';
              jobHistoryNewRecord.date = null;//new Date();
              jobHistoryNewRecord.addedBy = this.userName;
              jobHistoryNewRecord.timeStamp = null;
              jobHistoryNewRecord.invoiceNumber =
                this.form.controls.invoiceNo.value;
              jobHistoryNewRecord.invoiceAmount = parseFloat(
                this.form.controls.invoiceAmount.value.replaceAll(',', '')
              );

              this.restService
                .addNewJobHistoryRecord(jobHistoryNewRecord)
                .subscribe({
                  next: (x) => {
                    try {
                      let jobFile: JobFile = {
                        id: 0,
                        jobHistoryId: x.id,
                        fileName: this.selectedFiles[0].name,
                        fileData: base64String,
                      };
                      this.restService.addNewJobFile(jobFile).subscribe({
                        //(x) => {
                        next: () => {
                          // Swal.fire(
                          //   'Success!',
                          //   'Document uploaded successfully and the details has been recorded!',
                          //   'success'
                          // ).then(() => {
                          let emailRecord = new EmailRecord();
                          emailRecord.id = this.emailRecord.id;
                          emailRecord.statusId = Number(
                            jobHistoryNewRecord.statusId
                          );
                          this.restService
                            .updateEmailRecord(
                              this.emailRecord.id,
                              emailRecord.statusId,
                              emailRecord
                            )
                            .pipe(first())
                            .subscribe({
                              next: () => {
                                try {
                                  var email: Email;
                                  // if (
                                  //   this.claimOwnerEmails
                                  //     .toLowerCase()
                                  //     .includes('dan.loft')
                                  // )
                                  //   this.claimOwnerEmails =
                                  //     this.claimOwnerEmails.toLowerCase().replace(
                                  //       'dan.loft',
                                  //       'michael.gardiner'
                                  //     );

                                  email = {
                                    from: 'trades@mk3.com.au',
                                    to: this.claimOwnerEmails,
                                    cc:
                                      'accounts@mk3.com.au',
                                    bcc: 'test@mk3.com.au',
                                    subject:
                                      this.emailRecord.refNo +
                                      ' - Invoice Awaiting Assessor Approval. Invoice #: ' +
                                      this.form.controls.invoiceNo.value +
                                      ', Amount: $' +
                                      this.form.controls.invoiceAmount.value,
                                    body:
                                      'New invoice for the Job ' +
                                      this.emailRecord.refNo +
                                      ' has been uploaded to the Contractors App and awaiting for Approval' +
                                      '<br/><br/>Please see the <a href=https://contractors.mk3apps.com.au/job-details/' +
                                      this.emailRecord.id +
                                      ' target="_blank">details here.</a>' +
                                      '<br/><b>RefNo:</b> <a href=https://portal.mk3apps.com.au/case-details/' +
                                      this.emailRecord.refNo +
                                      '>' +
                                      this.emailRecord.refNo +
                                      '</a>' +
                                      (this.data.contractor
                                        ? '<br/><b>Contractor:</b> <a href=https://contractors.mk3apps.com.au/contractor-details/' +
                                          this.data.contractor.contractorId
                                        : '<br/><b>Internal Trade:</b> <a href=https://contractors.mk3apps.com.au/internal-trade-details/' +
                                          this.data.internalTrade?.employeeId) +
                                      '>' +
                                      (this.data.contractor
                                        ? this.data.contractor.tradingName
                                        : this.data.internalTrade
                                            ?.displayName) +
                                      '</a>' +
                                      '<br/><b>Invoice #:</b> ' +
                                      this.form.controls.invoiceNo.value +
                                      '<br/><b>Invoice Amount:</b> $' +
                                      this.form.controls.invoiceAmount.value,
                                    attachmentName: !jobFile.fileName
                                      ? null
                                      : jobFile.fileName,
                                    attachmentContent: !jobFile.fileData
                                      ? null
                                      : jobFile.fileData
                                          ?.toString()
                                          .replace('data:', '')
                                          .replace(/^.+,/, ''),
                                    extraAttachmentName: null,
                                    extraAttachmentContent:null,
                                  };
                                  this.restService.sendEmail(email,'Email Type:Upload Invoice'+ ', User Name:' + this.msalService.instance.getActiveAccount()!.name! 
                                                +', Upload Invoice No: ' + this.form.controls.invoiceNo.value+', RefNo:'+this.data.emailRecord.refNo?.toString()
                                                +', Contractor:'+(this.data.contractor? this.data.contractor.tradingName: this.data.internalTrade.displayName)
                                                +', ContractorId:'+(this.data.contractor? this.data.contractor.contractorId.toString(): this.data.internalTrade.employeeId.toString())
                                                +', EmailRecordId:'+emailRecord.id.toString()).subscribe({
                                    next: () => {
                                      Swal.fire(
                                        'Success!',
                                        'Document uploaded successfully and the details has been recorded!',
                                        'success'
                                      ).then(() => {
                                        this.isLoading = false;
                                        this.dialogRef.close();
                                        window.location.reload();
                                      });
                                    },
                                    error: (err: any) => {
                                      try {
                                        this.errorService.sendErrorDetails(
                                          err,
                                          'InvoiceUploadDialog.onSubmit()',
                                          'Catch Error (Invoice Upload): restService.sendEmail',
                                          this.userName
                                        );
                                      } catch (error: any) {
                                        Swal.fire(
                                          'Error Sending Email: Please report to IT with the details and a screenshot of your full page.' +
                                            error.message,
                                          '',
                                          'error'
                                        );
                                      }

                                      Swal.fire(
                                        'Error (Invoice Upload): restService.sendEmail: ' +
                                          err,
                                        '',
                                        'error'
                                      );
                                      console.log(
                                        'Error (Invoice Upload): restService.sendEmail: ' +
                                          err
                                      );
                                    },
                                  });
                                } catch (error: any) {
                                  this.isLoading = false;
                                  try {
                                    this.errorService.sendErrorDetails(
                                      error,
                                      'InvoiceUploadDialog.onSubmit()',
                                      'Catch Error (Invoice Upload): restService.updateEmailRecord1',
                                      this.userName
                                    );
                                  } catch (error: any) {
                                    Swal.fire(
                                      'Error Sending Email: Please report to IT with the details and a screenshot of your full page.' +
                                        error.message,
                                      '',
                                      'error'
                                    );
                                  }

                                  Swal.fire(
                                    'Error (Invoice Upload Catch Error): restService.updateEmailRecord: ' +
                                      error.message,
                                    '',
                                    'error'
                                  );
                                }
                                //this.isLoading = false;
                                // this.dialogRef.close();
                                // window.location.reload();
                              },
                              error: (err: any) => {
                                this.isLoading = false;
                                try {
                                  this.errorService.sendErrorDetails(
                                    err,
                                    'InvoiceUploadDialog.onSubmit()',
                                    'Catch Error (Invoice Upload): restService.updateEmailRecord2',
                                    this.userName
                                  );
                                } catch (error: any) {
                                  Swal.fire(
                                    'Error Update Email Record: Please report to IT with the details and a screenshot of your full page.' +
                                      error.message,
                                    '',
                                    'error'
                                  );
                                }
                                Swal.fire(
                                  'Error (Invoice Upload): restService.updateEmailRecord: ' +
                                    err,
                                  '',
                                  'error'
                                );
                                console.log(
                                  'Error (Invoice Upload): restService.updateEmailRecord: ' +
                                    err
                                );
                              },
                            });
                          //});
                        },
                        error: (err) => {
                          this.isLoading = false;
                          Swal.fire(
                            'Error: restService.addNewJobFile<br/>' + err,
                            '',
                            'error'
                          );
                        },
                      });
                    } catch (error: any) {
                      this.isLoading = false;
                      Swal.fire(
                        'Error: restService.addNewJobHistoryRecord: Catch Error<br/>' +
                          error,
                        '',
                        'error'
                      );
                    }
                  },
                  error: (err) => {
                    this.isLoading = false;
                    Swal.fire(
                      'Error: restService.addNewJobHistoryRecord<br/>' + err,
                      '',
                      'error'
                    );
                  },
                });
            } catch (error: any) {
              this.isLoading = false;
              Swal.fire(
                'Error: InvoiceUploadDialog.onSubmit(): Catch Error<br/>' +
                  error,
                '',
                'error'
              );
            }
          }
        }
      } else {
        this.isLoading = false;
      }
    });
  }
  else{
    Swal.fire(
      'Found invalid Contractor or Internal Trade. Please refresh and try again!',
      '',
      'warning'
    ).then(()=>{
      window.location.reload();
    });
  }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };
}
