<h1 *ngIf="contractor" style="color: white; background-color: var(--nibblue); text-align: center;">Instructions/ Quote
  to<br>{{contractor.tradingName}}</h1>
<h1 *ngIf="internalTrade" style="color: white; background-color:var(--nibblue); text-align: center;">Instructions to
  {{internalTrade.displayName}}</h1>

<form [formGroup]="form" autocomplete="off" novalidate fxLayout="column wrap" fxLayoutAlign="center center"
  style="margin-bottom: 10px;" fxLayoutGap="10px">
  <mat-dialog-content text-align="middle">
    <small>Mk3 RefNo</small>
    <input type="text" digitOnly inputmode="numeric" formControlName="refNo" class="form-control" maxlength="5"
      placeholder="00000" (keyup.enter)="onSubmitInputRefNoDialog(data)" autofocus />
    <mat-error *ngIf="hasError('refNo', 'pattern')">Invalid RefNo!</mat-error>
  </mat-dialog-content>
</form>

<mat-dialog-actions>
  <button appDisableMultipleClicks [debounceTimeMs]="10" mat-raised-button class="btn btn-sm btn-primary" type="button" [disabled]="!form.valid"
    (click)="onSubmitInputRefNoDialog(data)" (keyup.enter)="onSubmitInputRefNoDialog(data)">Open</button>&nbsp;
  <button mat-raised-button mat-dialog-close type="button" mat-raised-button class="btn mat-button-base">Cancel</button>
</mat-dialog-actions>
