import { DatePipe, Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
//import moment from 'moment';
import * as business from 'moment-business';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  constructor(private datePipe: DatePipe) {}
  partitionV(list: number[] = [], numCols: number) {
    numCols = Math.floor(numCols / 6);
    const partitions: any[] = [];
    for (let c = 0; c < numCols; c++) {
      partitions.push([]);
    }
    for (let i = 0; i < list.length; i++) {
      const partition = i % numCols;
      partitions[partition].push(list[i]);
    }
    return partitions;
  }

  partitionH(list: number[] = [], n: number) {
    const isPositiveInteger = Number.isSafeInteger(n) && n > 0;
    if (!isPositiveInteger) {
      throw new RangeError('n must be a positive integer');
    }

    const partitions = [];
    const partitionLength = Math.ceil(list.length / n);

    for (let i = 0; i < list.length; i += partitionLength) {
      const partition = list.slice(i, i + partitionLength);
      partitions.push(partition);
    }
    return partitions;
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  // E.g: chunkArray([1,2,3,4,5,6,7,8], 3);
  // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
  chunkArray(myArray: number[] = [], chunk_size: number) {
    var results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }

  pastDate(dateInput: Date) {
    //var today = new Date();
    //var today = new Date(moment.utc(moment.utc().format()).local().utcOffset(0, true).format());
    let today = moment().toDate();
    if (moment(dateInput).isAfter(today, 'date')) return false;
    else return true;
  }

  expireInMonthDate(dateInput: Date) {
    var nextMonthDate = moment().add(1, 'months').format('YYYY-MM-DD');
    if (moment(dateInput).isAfter(nextMonthDate, 'date')) return false;
    else return true;
  }

  timeConvert24to12(timeString: string) {
    if (timeString == '') timeString = '12:00:00';

    var H = +timeString.split(':')[0];
    var h = H % 12 || 12;
    var ampm = H < 12 || H === 24 || timeString === '12:00:00' ? 'AM' : 'PM';
    var m = '00';
    if (timeString.split(':')[1]) m = timeString.split(':')[1];

    if (m.substring(0, 2) == '0 ') m = '00';
    timeString = h + ':' + m + ' ' + ampm;
    return timeString;
  }

  timeStringToTime(timeString: string): Time {
    let timeHour = '';
    let timeMinute = '';

    var array1 = timeString.split(':');
    var array2 = timeString.split(' ');

    if (timeString != '12:00 AM') {
      if (array2[1] == 'AM' || timeString == '12:00 PM')
        timeHour = parseInt(array1[0]).toString();
      else timeHour = ((parseInt(array1[0]) % 12) + 12).toString();

      if (array1[1])
        timeMinute = parseInt(array1[1].substring(0, 2)).toString();
    }

    if (timeHour == 'NaN' || timeHour == '') timeHour = '0';

    if (timeMinute == 'NaN' || timeMinute == '') timeMinute = '0';

    return JSON.parse(JSON.stringify(timeHour + ':' + timeMinute + ':' + '00'));
  }

  timeToTimeString(time: string): string {
    let timeHour = '';
    let timeMinute = '';
    let amPm = '';

    if (time == '[object Object]') time = '00:00 AM';

    var array1 = time.toString().split(':');

    if (time.toString() != '00:00:00') {
      if (parseInt(array1[0]) < 12) {
        timeHour = parseInt(array1[0]).toString();
        amPm = 'AM';
      } else {
        timeHour = ((parseInt(array1[0]) % 12) + 12).toString();
        amPm = 'PM';
      }

      if (array1[1])
        timeMinute = parseInt(array1[1].substring(0, 2)).toString();

      return timeHour + ':' + timeMinute + ' ' + amPm;
    } else return '12:00 AM';
  }

  getDayAfterBusinessDays(numberOfDays: number) {
    //var moment = require('moment-business-days');
    //var dueDate = moment().businessAdd(numberOfDays);

    // true if the moment is Mon-Fri, false otherwise
    //business.isWeekDay(someMoment);

    // Adds five work days to the Moment
    //business.addWeekDays(someMoment, 5);

    let today = moment();
    var dueDate = business.addWeekDays(today, numberOfDays).toDate();

    return dueDate;
  }

  blankIfNull(inValue: string): string {
    if (inValue == null) return '';
    else return inValue;
  }

  isValidDate(str: string) {
    var m = str.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
    //return (m) ? new Date(Number(m[3]), Number(m[2])-1, Number(m[1])) : null;
    return m ? true : false;
  }

  b64toBlob(b64Data: string) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'application/pdf' });
    return blob;
  }

  getCurrentTimestampFormatted() {
    return this.datePipe
      .transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
      ?.toString();
  }

  openPdf(blobString: string) {
    var blob = this.b64toBlob(blobString);
    var blobURL = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = blobURL;
    link.target = '_blank';
    //link.rel = 'noopener noreferrer';
    link.click();
  }

  isValidABN(value: string): boolean {
    let weights = new Array(10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19);
    let abn = value.replace(/\D/g, ''); // strip non numeric chars
    if (abn.length != 11) {
      return false;
    }
    let abnFirstDigit = parseInt(abn.charAt(0)) - 1; //subtract 1 from first digit
    abn = abnFirstDigit + abn.substring(1); // replace first digit with the substracted value

    let total = 0;
    for (let i = 0; i < 11; i++) total += weights[i] * Number(abn.charAt(i));

    if (total == 0 || total % 89 != 0) {
      return false;
    } else {
      return true;
    }
  }

  isValidACN(acn: string): boolean {
    if (acn.length != 9 || isNaN(parseInt(acn))) return false;

    var weighting = [8, 7, 6, 5, 4, 3, 2, 1];
    var weighted = 0;
    for (var i = 0; i < weighting.length; i++) {
      weighted += parseInt(acn.charAt(i)) * weighting[i];
    }
    let checkDigit = 10 - (weighted % 10);
    checkDigit = checkDigit == 10 ? 0 : checkDigit;
    return checkDigit == Number(acn[8]);
  }

  // Remove line breaks from string
  removeNewlines(event: any, control: FormControl, str: string = '') {
    if (!str) {
      str = event.clipboardData.getData('text/plain');
    }
    str = str.replace(/\s{2,}/g, ' ');
    str = str.replace(/\t/g, ' ');
    str = str
      .toString()
      .trim()
      .replace(/(\r\n|\n|\r)/g, '');
    control.setValue(str);
    event.preventDefault();
  }
}
