<section fxLayout="row wrap" fxLayoutAlign="center center" class="row-height" class="bdrop">
  <mat-card fxFlex="600px" fxFlex.xs="100%">
    <mat-card-title>{{this.companyName}}</mat-card-title>
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="createNote()" fxLayout="column wrap"
      fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-card-content>

        <small>Select Note Type</small>
        <select placeholder="Select note type" formControlName="noteTypeId" class="form-control" style="display: flex;">
          <option *ngFor="let item of noteTypes | async" [ngValue]="item.id">
            {{ item.type }}
          </option>
        </select>
        
        <small>Mk3 RefNo (Optional)</small>
        <input type="text"  digitOnly inputmode="numeric" formControlName="refNo" class="form-control" maxlength="5"/>

        <mat-form-field style="text-align: center;display: inline; min-width:500px; display:block">
          <textarea matInput width="800px" placeholder="Enter New Note"
            formControlName="noteDescription" autofocus></textarea>
          <mat-hint align="end">Characters {{form.controls.noteDescription.value.length || 0}}/255</mat-hint>
          <mat-error *ngIf="hasError('noteDescription', 'required')">Note is required</mat-error>
          <mat-error *ngIf="hasError('noteDescription', 'maxlength')">You have entered more than 255 characters!</mat-error>
        </mat-form-field>


      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button class="btn btn-sm btn-primary" [disabled]="!form.valid">Add Note</button>
        <button type="button" mat-raised-button class="btn mat-button-base" (click)="onCancel()">Cancel</button>
      </mat-card-actions>
    </form>
  </mat-card>
</section>
